import * as React from "react";
import * as _ from "lodash";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import { ProjectRouteParams } from "../ProjectLayout";
import { PermissionCheck } from "components/PermissionCheck";
import { Permission, ProjectResource, TenantResource, RunbookResource, EnvironmentResource, ResourceCollection, RunbooksDashboardItemResource } from "client/resources";
import ActionButton, { NavigationButton, NavigationButtonType, ActionButtonType } from "components/Button";
import RunbooksPaperLayout from "./Layouts/RunbooksPaperLayout";
import { repository } from "clientInstance";
import RunbookOnboarding from "./RunbookOnboarding";
import * as tenantTagsets from "components/tenantTagsets";
import { TagIndex } from "components/tenantTagsets";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import { QueryStringFilters, IQuery } from "components/QueryStringFilters/QueryStringFilters";
import { Feature, FeatureToggle } from "components/FeatureToggle";
import { TenantMultiSelect, TenantTagMultiSelect, EnvironmentMultiSelect } from "components/MultiSelect";
import { FilterSection } from "components/AdvancedFilterLayout";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import { isEqual } from "lodash";
import List from "components/List";
import RunbookTaskStatusDetails from "./RunbookTaskStatusDetails/RunbookTaskStatusDetails";
import RunNowButton from "./RunNowButton";
import { NoResults } from "components/NoResults/NoResults";
import ActionList from "components/ActionList";
import { GetTaskRunDashboardItemsListArgs } from "client/repositories/progressionRepository";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { WithRunbookContextInjectedProps, withRunbookContext } from "./RunbookContext";

interface RunbookRunsListLayoutFilter {
    environmentIds: string[];
    tenantIds: string[];
    tenantTags: string[];
}

interface RunbookRunsListLayoutQuery extends IQuery {
    environmentIds: string[];
    tenantIds: string[];
    tenantTags: string[];
}

class FilterLayout extends AdvancedFilterLayout<RunbookRunsListLayoutFilter> {}

const RunbookRunsListQueryStringFilters = QueryStringFilters.For<RunbookRunsListLayoutFilter, RunbookRunsListLayoutQuery>();

class RunbookRunsList extends List<RunbooksDashboardItemResource> {}

interface RunbookRunsListLayoutState extends DataBaseComponentState {
    project: ProjectResource;
    runbook: RunbookResource;
    runbookRunsDashboardItems: ResourceCollection<RunbooksDashboardItemResource>;
    tenants: TenantResource[];
    tagIndex: TagIndex;
    environments: EnvironmentResource[];
    hasSteps: boolean;
    filter: RunbookRunsListLayoutFilter;
    queryFilter?: RunbookRunsListLayoutFilter;
}

export interface RunbookRunsListLayoutRouteProps {
    runbookId: string;
}

type RunbookRunsListLayoutProps = RouteComponentProps<ProjectRouteParams & RunbookRunsListLayoutRouteProps> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps;

class RunbookRunsListLayoutInternal extends DataBaseComponent<RunbookRunsListLayoutProps, RunbookRunsListLayoutState> {
    constructor(props: RunbookRunsListLayoutProps) {
        super(props);
        this.state = {
            hasSteps: false,
            project: null,
            runbook: null,
            runbookRunsDashboardItems: null,
            tenants: [],
            tagIndex: null,
            filter: createEmptyFilter(),
            environments: [],
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(nextProps: RunbookRunsListLayoutProps) {
        const currentRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const nextRunbook = nextProps.runbookContext.state && nextProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async reload() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        if (!project) {
            return;
        }

        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }

        await this.doBusyTask(async () => {
            const [tenants, tagIndex, environments] = await Promise.all([repository.Tenants.all(), tenantTagsets.getTagIndex(), repository.Environments.all()]);

            const [runbookProcess, runbookRunsDashboardItems] = await Promise.all([
                repository.RunbookProcess.get(runbook.RunbookProcessId),
                repository.Progression.getTaskRunDashboardItemsForRunbook(runbook, {
                    projectIds: [project.Id],
                    runbookIds: [runbook.Id],
                    environmentIds: this.state.filter.environmentIds,
                    tenantIds: this.state.filter.tenantIds,
                }),
            ]);

            this.setState({
                project,
                runbook,
                runbookRunsDashboardItems,
                tenants,
                tagIndex,
                environments,
                hasSteps: runbookProcess.Steps.length > 0,
            });
        });
    }

    async reloadRuns() {
        await this.doBusyTask(async () => {
            const runbookRunsDashboardItems = await repository.Progression.getTaskRunDashboardItemsForRunbook(this.state.runbook, {
                projectIds: [this.state.project.Id],
                runbookIds: [this.state.runbook.Id],
                environmentIds: this.state.filter.environmentIds,
                tenantIds: this.state.filter.tenantIds,
            });
            this.setState({ runbookRunsDashboardItems });
        });
    }

    render() {
        const runbook = this.state.runbook;
        if (!this.state.project || !runbook) {
            return <RunbooksPaperLayout busy={true} errors={this.state.errors} />;
        }

        if (this.state.project && !this.state.hasSteps) {
            const actions: JSX.Element[] = [
                <PermissionCheck permission={Permission.EnvironmentCreate} environment="*">
                    <NavigationButton
                        label="Define your Runbook Process"
                        href={
                            routeLinks
                                .project(this.state.project)
                                .operations.runbook(runbook.Id)
                                .runbookProcess.runbookProcess(runbook.RunbookProcessId).root
                        }
                        type={NavigationButtonType.Primary}
                    />
                </PermissionCheck>,
            ];
            const actionSection = <ActionList actions={actions} />;
            return (
                <RunbooksPaperLayout title={runbook.Name} busy={this.state.busy} errors={this.state.errors} sectionControl={actionSection}>
                    <RunbookOnboarding />
                </RunbooksPaperLayout>
            );
        }

        const filterSections: FilterSection[] = [
            {
                render: (
                    <div>
                        <EnvironmentMultiSelect
                            items={this.state.environments}
                            value={this.state.filter.environmentIds}
                            onChange={x => {
                                this.setFilterState({ environmentIds: x }, async () => {
                                    await this.onFilterChange();
                                });
                            }}
                        />
                        <FeatureToggle feature={Feature.MultiTenancy}>
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <TenantMultiSelect
                                    value={this.state.filter.tenantIds}
                                    items={this.state.tenants}
                                    onChange={x => {
                                        this.setFilterState({ tenantIds: x }, async () => {
                                            await this.onFilterChange();
                                        });
                                    }}
                                />
                                <TenantTagMultiSelect
                                    value={this.state.filter.tenantTags}
                                    doBusyTask={this.doBusyTask}
                                    onChange={x => {
                                        this.setFilterState({ tenantTags: x }, async () => {
                                            await this.onFilterChange();
                                        });
                                    }}
                                />
                            </PermissionCheck>
                        </FeatureToggle>
                    </div>
                ),
            },
        ];

        const sectionControlActions = [<ActionButton type={ActionButtonType.Secondary} label="Refresh" onClick={() => this.reloadRuns()} />, <RunNowButton isDisabled={!this.state.hasSteps} />];
        const sectionControl = <ActionList actions={sectionControlActions} />;
        return (
            <RunbooksPaperLayout
                title={runbook.Name}
                breadcrumbTitle={"Runbooks"}
                breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).operations.runbooks}
                sectionControl={sectionControl}
                busy={this.state.busy}
                errors={this.state.errors}
            >
                <RunbookRunsListQueryStringFilters filter={this.state.filter} getQuery={this.queryFromFilter} getFilter={this.getFilter} onFilterChange={filter => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())} />
                <FilterLayout
                    filterSections={filterSections}
                    filter={this.state.filter}
                    queryFilter={this.state.queryFilter}
                    defaultFilter={createEmptyFilter()}
                    initiallyShowFilter={this.isFiltering()}
                    additionalHeaderFilters={null}
                    onFilterReset={(filter: RunbookRunsListLayoutFilter) => {
                        this.setState({ filter }, async () => {
                            await this.onFilterChange();
                            const location = { ...this.props.history, search: null as any };
                            this.props.history.replace(location);
                        });
                    }}
                    renderContent={() => (
                        <div>
                            {this.state.runbookRunsDashboardItems && (
                                <RunbookRunsList
                                    initialData={this.state.runbookRunsDashboardItems}
                                    additionalRequestParams={this.getAdditionalRequestParams()}
                                    onRow={(item: any) => this.buildRunbookRunRow(item)}
                                    onFilter={this.filter}
                                    filterSearchEnabled={false} // This component has advanced filtering instead.
                                    apiSearchParams={["partialName"]}
                                    match={this.props.match}
                                    filterHintText="Filter by name..."
                                    empty={<NoResults />}
                                />
                            )}
                        </div>
                    )}
                />
            </RunbooksPaperLayout>
        );
    }

    private getAdditionalRequestParams(): Map<keyof GetTaskRunDashboardItemsListArgs, any> {
        const additionalRequestParams = new Map<keyof GetTaskRunDashboardItemsListArgs, any>();
        additionalRequestParams.set("projectIds", [this.state.project.Id]);
        additionalRequestParams.set("runbookIds", [this.state.runbook.Id]);
        additionalRequestParams.set("environmentIds", this.state.filter.environmentIds);
        additionalRequestParams.set("tenantIds", this.state.filter.tenantIds);
        return additionalRequestParams;
    }

    private buildRunbookRunRow(runbookRunItem: RunbooksDashboardItemResource) {
        return (
            <div>
                <RunbookTaskStatusDetails project={this.state.project} item={runbookRunItem} />
            </div>
        );
    }

    private filter(filter: string, resource: RunbooksDashboardItemResource) {
        return !filter || filter.length === 0 || !resource || resource.RunbookSnapshotName.toLowerCase().includes(filter.toLowerCase());
    }

    private setFilterState<K extends keyof RunbookRunsListLayoutFilter>(state: Pick<RunbookRunsListLayoutFilter, K>, callback?: () => void) {
        this.setState(
            prev => ({
                filter: { ...(prev.filter as object), ...(state as object) },
            }),
            callback
        );
    }

    private isFiltering() {
        return !isEqual(this.state.filter, createEmptyFilter());
    }

    private async onFilterChange() {
        await this.reloadRuns();
    }

    private queryFromFilter = (filter: RunbookRunsListLayoutFilter): RunbookRunsListLayoutQuery => {
        const query: RunbookRunsListLayoutQuery = {
            environmentIds: filter.environmentIds,
            tenantIds: filter.tenantIds,
            tenantTags: filter.tenantTags,
        };

        return query;
    };

    private getFilter = (query: RunbookRunsListLayoutQuery): RunbookRunsListLayoutFilter => {
        const filter: RunbookRunsListLayoutFilter = {
            ...createEmptyFilter(),
            environmentIds: arrayValueFromQueryString(query.environmentIds) || [],
            tenantIds: arrayValueFromQueryString(query.tenantIds) || [],
            tenantTags: arrayValueFromQueryString(query.tenantTags) || [], // Expecting canonical tag names
        };

        return filter;
    };
}

function createEmptyFilter(): RunbookRunsListLayoutFilter {
    return {
        environmentIds: [],
        tenantIds: [],
        tenantTags: [],
    };
}

const RunbookRunsListLayout = withRunbookContext(withProjectContext(RunbookRunsListLayoutInternal));
export default RunbookRunsListLayout;
