import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import { ProjectRouteParams } from "areas/projects/components/ProjectLayout";
import { ProjectResource, EnvironmentResource, DeploymentTargetResource, LifecycleResource, TenantResource, Permission, TenantedDeploymentMode } from "client/resources";
import { repository } from "clientInstance";
import { flatMap } from "lodash";
import { VariableSetResource } from "client/resources/variableSetResource";
import { VariableResource } from "client/resources/variableResource";
import { SFC } from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { SimpleList } from "components/List";
import { Select } from "components/form";
import { Item } from "components/form/Select/Select";
import Checkbox from "components/form/Checkbox/Checkbox";
import { convertVariableResourcesToVariablesWithSource } from "areas/variables/convertVariableResourcesToVariablesWithSource";
const styles = require("./style.less");
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import VariablePreviewSummary from "./VariablePreviewSummary";
import FilterSearchBox from "components/FilterSearchBox";
import { VariableDisplayer, VariableWithSource, ValueWithSource, FilteredVariable, mergeAndSortVariables } from "areas/variables/VariableDisplayer";
import SidebarLayout, { SidebarSide } from "components/SidebarLayout/SidebarLayout";

interface VariableListProps {
    variables: VariableResource[];
}

class VariableList extends SimpleList<VariableResource> {}

const Variables: SFC<VariableListProps> = props => {
    const onRow = (variable: VariableResource) => [<b>{variable.Name}</b>, variable.Value];
    return <VariableList items={props.variables} onRow={onRow} empty={<span>No variables have been added</span>} />;
};

Variables.displayName = "Variables";

interface VariablePreviewState extends DataBaseComponentState {
    variableNameFilter: string;
    environmentId?: string;
    targetId?: string;
    channelId?: string;
    tenantId?: string;
    actionId?: string;
    role?: string;
    machineId?: string;
    project?: ProjectResource;
    environments?: Item[];
    targets?: DeploymentTargetResource[];
    channels?: Item[];
    tenants?: Item[];
    actions?: Item[];
    roles?: Item[];
    machines?: Item[];
    variables?: VariableSetResource;
    showOctopus: boolean;
}

export default class VariablePreview extends DataBaseComponent<RouteComponentProps<ProjectRouteParams>, VariablePreviewState> {
    constructor(props: RouteComponentProps<ProjectRouteParams>) {
        super(props);
        this.state = {
            showOctopus: false,
            variableNameFilter: null,
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        if (!this.state.variables) {
            return null;
        }

        const summary = (
            <VariablePreviewSummary
                tenantedDeploymentMode={this.state.project && this.state.project.TenantedDeploymentMode}
                environments={this.state.environments}
                environmentId={this.state.environmentId}
                tenants={this.state.tenants}
                tenantId={this.state.tenantId}
                actions={this.state.actions}
                actionId={this.state.actionId}
                channels={this.state.channels}
                channelId={this.state.channelId}
                roles={this.state.roles}
                role={this.state.role}
                machines={this.state.machines}
                machineId={this.state.machineId}
            />
        );

        const sidebar = this.state.variables && (
            <div className={styles.scopeRow}>
                <h4>Select Deployment Scenario</h4>
                <Select label="Environment" items={this.state.environments} allowClear={false} value={this.state.environmentId} onChange={this.handleEnvironmentChanged} />
                {this.state.tenants && this.state.tenants.length > 0 && (
                    <Select label="Tenant" items={this.state.tenants} allowClear={this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Tenanted} value={this.state.tenantId} onChange={this.handleTenantChanged} hintText="Untenanted" />
                )}
                <Select label="Deployment step" items={this.state.actions} allowClear={false} value={this.state.actionId} onChange={this.handleActionChanged} />
                {this.state.channels && this.state.channels.length > 1 && <Select label="Channel" items={this.state.channels} allowClear={false} value={this.state.channelId} onChange={this.handleChannelChanged} />}
                <Select label="Role" items={this.state.roles} allowClear={true} value={this.state.role} onChange={this.handleRoleChanged} hintText="Select role" />
                <Select label="Deployment target" items={this.state.machines} allowClear={true} value={this.state.machineId} onChange={this.handleMachineChanged} hintText="Select deployment target" />
                <div className={styles.showAll}>
                    <Checkbox label="Show system variables" value={this.state.showOctopus} onChange={this.handleShowOctopusChanged} />
                </div>
            </div>
        );

        const variableSections = mergeAndSortVariables(this.getVariables(), this.state.variables.ScopeValues);
        const filteredVariableSections = variableSections.map(v => {
            return this.mapToFilteredVariable(v);
        });

        return (
            <PaperLayout title={"Deployment Variable Preview"} busy={this.state.busy} fullWidth={true} errors={this.state.errors}>
                <div className={styles.filterTextBox}>
                    <FilterSearchBox hintText="By variable name" value={this.state.variableNameFilter} onChange={this.handleFilterChanged} />
                </div>

                {summary}

                <SidebarLayout sideBar={sidebar} side={SidebarSide.Left} extendContentToEdges={true} extendSidebarToEdges={true} hideTopDivider={true} hideSidebarDivider={false} overflowXHidden={true}>
                    <VariableDisplayer
                        availableScopes={this.state.variables && this.state.variables.ScopeValues}
                        variableSections={[filteredVariableSections]}
                        doBusyTask={this.doBusyTask}
                        hideSource={true}
                        hideScope={false}
                        isDisplayingFullWidth={false}
                    />
                </SidebarLayout>
            </PaperLayout>
        );
    }

    private mapToFilteredVariable(variable: VariableWithSource): FilteredVariable {
        return {
            name: variable.name,
            variableMessages: null,
            values: variable.values.map(x => {
                const value = x as ValueWithSource;
                return {
                    ...value,
                    messages: null,
                };
            }),
        };
    }

    private handleFilterChanged = (variableNameFilter: string) => {
        this.setState({ variableNameFilter });
    };

    private async loadEnvironmentsFromLifecycle(lifecycle: LifecycleResource) {
        const environmentIds = flatMap(lifecycle.Phases, phase => {
            return phase.AutomaticDeploymentTargets.concat(phase.OptionalDeploymentTargets);
        });

        const args = environmentIds.length === 0 ? undefined : { ids: environmentIds };
        const environments = await (isAllowed({ permission: Permission.EnvironmentView, environment: "*" }) ? repository.Environments.all(args) : Promise.resolve<EnvironmentResource[]>([]));

        return environments;
    }

    private async loadData() {
        await this.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.match.params.projectSlug);

            const [deploymentProcess, channels, lifecycle, tenants, roles] = await Promise.all([
                repository.DeploymentProcesses.get(project.DeploymentProcessId),
                repository.Projects.getChannels(project, 0, 1000),
                repository.Lifecycles.get(project.LifecycleId),
                isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all({ projectId: project.Id }) : Promise.resolve<TenantResource[]>([]),
                repository.MachineRoles.all(),
            ]);

            const environments = await this.loadEnvironmentsFromLifecycle(lifecycle);

            const tenantId = tenants && tenants.length > 0 ? tenants[0].Id : null;
            const environmentId = environments && environments.length > 0 ? environments[0].Id : null;
            const actionId = deploymentProcess && deploymentProcess.Steps.length > 0 ? deploymentProcess.Steps[0].Actions[0].Id : null;
            const channelId = channels && channels.Items.length > 0 ? channels.Items[0].Id : null;
            const role: string = null;

            const variablesPromise = repository.Variables.preview(project.Id, actionId, environmentId, role, null, channelId, tenantId);
            const machinesPromise = this.loadMachinesForEnvironment(environmentId, role);
            const [variables, machines] = await Promise.all([variablesPromise, machinesPromise]);
            const actions = variables.ScopeValues.Actions.map(a => ({ text: a.Name, value: a.Id }));

            this.setState({
                project,
                environments: environments.map(e => ({ text: e.Name, value: e.Id })),
                environmentId,
                channels: channels.Items.map(c => ({ text: c.Name, value: c.Id })),
                channelId,
                tenants: tenants.map(t => ({ text: t.Name, value: t.Id })),
                tenantId,
                roles: roles.map(r => ({ text: r, value: r })),
                machines,
                actions,
                actionId,
                variables,
            });
        });
    }

    private loadMachinesForEnvironment = async (environmentId: string, role: string) => {
        if (!environmentId || !isAllowed({ permission: Permission.MachineView, wildcard: true })) {
            return [];
        }
        const machines = await repository.Machines.list({ environmentIds: environmentId, roles: role, take: 999 });
        return machines.Items.map(m => ({ text: m.Name, value: m.Id }));
    };

    private handleEnvironmentChanged = async (environmentId: string) => {
        await this.doBusyTask(async () => {
            const machines = await this.loadMachinesForEnvironment(environmentId, this.state.role);
            const machine = machines.find(m => m.value === this.state.machineId);
            const machineId = machine ? machine.value : null;
            this.setState(
                {
                    environmentId,
                    machines,
                    machineId,
                },
                () => this.loadVariables()
            );
        });
    };

    private handleRoleChanged = async (role: string) => {
        await this.doBusyTask(async () => {
            const machines = await this.loadMachinesForEnvironment(this.state.environmentId, role);
            const machine = machines.find(m => m.value === this.state.machineId);
            const machineId = machine ? machine.value : null;
            this.setState(
                {
                    role,
                    machines,
                    machineId,
                },
                () => this.loadVariables()
            );
        });
    };

    private handleMachineChanged = (machineId: string) => {
        this.setState({ machineId }, () => this.loadVariables());
    };

    private handleChannelChanged = async (channelId: string) => {
        await this.doBusyTask(async () => {
            const channel = await repository.Channels.get(channelId);
            const lifecycle = await repository.Lifecycles.get(channel.LifecycleId);
            const environments = await this.loadEnvironmentsFromLifecycle(lifecycle);
            // clear the existing environment selection if it's no longer available in the drop down
            const environmentId = environments.some(x => x.Id === this.state.environmentId) ? this.state.environmentId : null;
            this.setState(
                {
                    environments: environments.map(e => ({ text: e.Name, value: e.Id })),
                    channelId,
                    environmentId,
                },
                () => this.loadVariables()
            );
        });
    };

    private handleTenantChanged = (tenantId: string) => {
        this.setState({ tenantId }, () => this.loadVariables());
    };

    private handleActionChanged = (actionId: string) => {
        this.setState({ actionId }, () => this.loadVariables());
    };

    private handleShowOctopusChanged = (showOctopus: boolean) => {
        this.setState({ showOctopus });
    };

    private getVariables = () => {
        if (!this.state.variables) {
            return [];
        }
        const source = {
            projectName: this.state.project.Name,
            projectId: this.state.project.Id,
        };
        const filtered = this.state.variables.Variables.filter(v => this.state.showOctopus || !v.Name.startsWith("Octopus.")).filter(
            v => !this.state.variableNameFilter || this.state.variableNameFilter.length === 0 || v.Name.toLowerCase().includes(this.state.variableNameFilter.toLowerCase())
        );
        return convertVariableResourcesToVariablesWithSource(filtered, source);
    };

    private loadVariables = async () => {
        await this.doBusyTask(async () => {
            const variables = await repository.Variables.preview(this.state.project.Id, this.state.actionId, this.state.environmentId, this.state.role, this.state.machineId, this.state.channelId, this.state.tenantId);
            this.setState({
                variables,
            });
        });
    };
}
