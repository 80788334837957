import * as React from "react";
import { Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import SecureRoute from "./components/SecureRoute";
import { StandardLayout } from "./components/StandardLayout";
import SignIn from "./areas/authentication/SignIn";
import SignOut from "./areas/authentication/SignOut";
import Register from "./areas/authentication/Register";
import getUserConfirmation from "./components/ConfirmNavigate";
import AuthenticationLayout from "./areas/authentication/AuthenticationLayout";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import routeLinks from "./routeLinks";
import { TrackJS } from "trackjs";
import { ResourceLoadError, TrackHost } from "./utils/TrackJS";
import { v4 as generateGuid } from "uuid";
import Provenance from "./provenance";
/*
 * We are enabling TrackJS for *.octopushq.com and *.tentaclearmy sites
 * as an expirement to prove the viability of TrackJS for our purposes.
 * We acknowledge the risk of having the token embedded in the client.
 * We shall mitigate this by using a new token when we are ready to go
 * ahead with TrackJS.
 */

if (TrackHost("tentacleArmy") || TrackHost("octopushq")) {
    ResourceLoadError.monitorResourceLoad();
    TrackJS.install({
        token: "c47ae8afe467485f9f9f828957ce610d",
        application: "test",
        network: { error: false },
        onError: payload => {
            const errorId = generateGuid();
            console.log("Unique Error Id: ", errorId);
            payload.metadata.push({
                key: "ErrorId",
                value: errorId,
            });
            return true;
        },
    });
    TrackJS.addMetadata("commitHash", Provenance.getGitCommitHash());
    TrackJS.addMetadata("commitBranch", Provenance.getGitCommitBranch());
    TrackJS.addMetadata("buildDate", Provenance.getBuildDate());
    TrackJS.addMetadata("buildMode", Provenance.getBuildMode());
}

export default class App extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <HashRouter getUserConfirmation={getUserConfirmation as any}>
                <Switch>
                    <ReloadableRoute
                        path={routeLinks.currentUser.signIn}
                        render={props => (
                            <AuthenticationLayout>
                                <SignIn {...props} />
                            </AuthenticationLayout>
                        )}
                    />
                    <ReloadableRoute
                        path={routeLinks.currentUser.signOut}
                        render={(props: any) => (
                            <AuthenticationLayout>
                                <SignOut {...props} />
                            </AuthenticationLayout>
                        )}
                    />
                    <ReloadableRoute
                        path={routeLinks.currentUser.register(":inviteCode")}
                        render={(props: any) => (
                            <AuthenticationLayout>
                                <Register {...props} />
                            </AuthenticationLayout>
                        )}
                    />
                    <SecureRoute path={routeLinks.root} component={StandardLayout} />
                </Switch>
            </HashRouter>
        );
    }
}
