import * as React from "react";
import * as _ from "lodash";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import { ProjectRouteParams } from "../ProjectLayout";
import { PermissionCheck } from "components/PermissionCheck";
import { Permission, ProjectResource, TenantResource, EnvironmentResource, ResourceCollection, RunbooksDashboardItemResource, RunbookResource, TenantedDeploymentMode } from "client/resources";
import { repository } from "clientInstance";
import * as tenantTagsets from "components/tenantTagsets";
import { TagIndex } from "components/tenantTagsets";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import { QueryStringFilters, IQuery } from "components/QueryStringFilters/QueryStringFilters";
import { Feature, FeatureToggle } from "components/FeatureToggle";
import { TenantMultiSelect, TenantTagMultiSelect, EnvironmentMultiSelect } from "components/MultiSelect";
import { FilterSection } from "components/AdvancedFilterLayout";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import { isEqual } from "lodash";
import RunbookTaskStatusDetails from "./RunbookTaskStatusDetails/RunbookTaskStatusDetails";
import PaperLayout from "components/PaperLayout";
import { EarlyAccessFeatureText, RunbooksWelcomeOnboarding } from "./RunbooksOnboarding";
import ActionButton, { NavigationButton, NavigationButtonType, ActionButtonType } from "components/Button";
import Callout, { CalloutType } from "components/Callout";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import InternalLink from "components/Navigation/InternalLink";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import routeLinks from "routeLinks";
import { NoResults } from "components/NoResults/NoResults";
import PagingDataTable from "components/PagingDataTable";
import ListTitle from "components/ListTitle";
import { EnvironmentChip, TenantChip } from "components/Chips";
import ActionList from "components/ActionList";
import InternalNavLink from "components/Navigation/InternalNavLink";
const styles = require("./OperationsOverviewLayout.less");
import { GetTaskRunDashboardItemsListArgs } from "client/repositories/progressionRepository";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";

export const runbooksEarlyAccessCallout = (canAccessFeatures: boolean) => {
    return (
        <Callout type={CalloutType.Warning} title={"Early Access"}>
            {EarlyAccessFeatureText}
            <br />
            If you have any feedback, <ExternalLink href="OperationsFeedbackForm">please let us know</ExternalLink>.{" "}
            {canAccessFeatures && (
                <>
                    You can enable or disable early access features in the <InternalLink to={routeLinks.configuration.features}>features</InternalLink> section.
                </>
            )}
        </Callout>
    );
};

interface OperationsOverviewLayoutFilter {
    environmentIds: string[];
    tenantIds: string[];
    tenantTags: string[];
}

interface OperationsOverviewLayoutQuery extends IQuery {
    environmentIds: string[];
    tenantIds: string[];
    tenantTags: string[];
}

class FilterLayout extends AdvancedFilterLayout<OperationsOverviewLayoutFilter> {}

const OperationsOverviewQueryStringFilters = QueryStringFilters.For<OperationsOverviewLayoutFilter, OperationsOverviewLayoutQuery>();

class OperationsOverviewTable extends PagingDataTable<RunbooksDashboardItemResource> {}

interface OperationsOverviewLayoutState extends DataBaseComponentState {
    project: ProjectResource;
    hasAtLeastOneRunbook: boolean;
    associatedRunbooks: RunbookResource[];
    runbookRunsDashboardItems: ResourceCollection<RunbooksDashboardItemResource>;
    tenants: TenantResource[];
    tagIndex: TagIndex;
    environments: EnvironmentResource[];
    filter: OperationsOverviewLayoutFilter;
    queryFilter?: OperationsOverviewLayoutFilter;
}

type OperationsOverviewLayoutProps = RouteComponentProps<ProjectRouteParams> & WithProjectContextInjectedProps;

class OperationsOverviewLayoutInternal extends DataBaseComponent<OperationsOverviewLayoutProps, OperationsOverviewLayoutState> {
    constructor(props: OperationsOverviewLayoutProps) {
        super(props);
        this.state = {
            project: null,
            hasAtLeastOneRunbook: false,
            associatedRunbooks: [],
            runbookRunsDashboardItems: null,
            tenants: [],
            tagIndex: null,
            filter: createEmptyFilter(),
            environments: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const [tenants, tagIndex, environments] = await Promise.all([repository.Tenants.all(), tenantTagsets.getTagIndex(), repository.Environments.all()]);

            const [runbookRunsDashboardItems, runbooks] = await Promise.all([
                repository.Progression.getTaskRunDashboardItemsForProject(project, {
                    projectIds: [project.Id],
                    environmentIds: this.state.filter.environmentIds,
                    tenantIds: this.state.filter.tenantIds,
                }),
                repository.Projects.getRunbooks(project, { skip: 0, take: 30 }),
            ]);

            const distinctRunbookIds = Array.from(new Set(runbookRunsDashboardItems.Items.map(x => x.RunbookId)));
            const associatedRunbooks = await repository.Runbooks.all({ ids: distinctRunbookIds });

            this.setState({
                project,
                hasAtLeastOneRunbook: runbooks.Items.length > 0,
                associatedRunbooks,
                runbookRunsDashboardItems,
                tenants,
                tagIndex,
                environments,
            });
        });
    }

    async reloadRuns() {
        await this.doBusyTask(async () => {
            const runbookRunsDashboardItems = await repository.Progression.getTaskRunDashboardItemsForProject(this.state.project, {
                projectIds: [this.state.project.Id],
                environmentIds: this.state.filter.environmentIds,
                tenantIds: this.state.filter.tenantIds,
            });

            const distinctRunbookIds = Array.from(new Set(runbookRunsDashboardItems.Items.map(x => x.RunbookId)));
            const associatedRunbooks = await repository.Runbooks.all({ ids: distinctRunbookIds });

            this.setState({ runbookRunsDashboardItems, associatedRunbooks });
        });
    }

    render() {
        if (!this.state.project) {
            return <PaperLayout busy={true} errors={this.state.errors} />;
        }

        if (!this.state.hasAtLeastOneRunbook) {
            const goToRunbooksButton = (
                <PermissionCheck permission={Permission.RunbookEdit} project={this.state.project.Id} tenant="*">
                    <NavigationButton type={NavigationButtonType.Primary} label="Go to Runbooks" href={routeLinks.project(this.state.project.Slug).operations.runbooks} disabled={false} />
                </PermissionCheck>
            );
            return (
                <PaperLayout title={"Overview"} busy={this.state.busy} errors={this.state.errors} sectionControl={goToRunbooksButton}>
                    {runbooksEarlyAccessCallout(isAllowed({ permission: Permission.AdministerSystem }))}
                    <RunbooksWelcomeOnboarding />
                </PaperLayout>
            );
        }

        const filterSections: FilterSection[] = [
            {
                render: (
                    <div>
                        <EnvironmentMultiSelect
                            items={this.state.environments}
                            value={this.state.filter.environmentIds}
                            onChange={x => {
                                this.setFilterState({ environmentIds: x }, async () => {
                                    await this.onFilterChange();
                                });
                            }}
                        />
                        <FeatureToggle feature={Feature.MultiTenancy}>
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <TenantMultiSelect
                                    value={this.state.filter.tenantIds}
                                    items={this.state.tenants}
                                    onChange={x => {
                                        this.setFilterState({ tenantIds: x }, async () => {
                                            await this.onFilterChange();
                                        });
                                    }}
                                />
                                <TenantTagMultiSelect
                                    value={this.state.filter.tenantTags}
                                    doBusyTask={this.doBusyTask}
                                    onChange={x => {
                                        this.setFilterState({ tenantTags: x }, async () => {
                                            await this.onFilterChange();
                                        });
                                    }}
                                />
                            </PermissionCheck>
                        </FeatureToggle>
                    </div>
                ),
            },
        ];

        const sectionControlActions = [<ActionButton type={ActionButtonType.Secondary} label="Refresh" onClick={() => this.reloadRuns()} />];
        const sectionControl = <ActionList actions={sectionControlActions} />;
        return (
            <PaperLayout title={"Overview"} sectionControl={sectionControl} busy={this.state.busy} errors={this.state.errors}>
                <OperationsOverviewQueryStringFilters filter={this.state.filter} getQuery={this.queryFromFilter} getFilter={this.getFilter} onFilterChange={filter => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())} />
                {runbooksEarlyAccessCallout(isAllowed({ permission: Permission.AdministerSystem }))}
                <FilterLayout
                    filterSections={filterSections}
                    filter={this.state.filter}
                    queryFilter={this.state.queryFilter}
                    defaultFilter={createEmptyFilter()}
                    initiallyShowFilter={this.isFiltering()}
                    additionalHeaderFilters={null}
                    onFilterReset={(filter: OperationsOverviewLayoutFilter) => {
                        this.setState({ filter }, async () => {
                            await this.onFilterChange();
                            const location = { ...this.props.history, search: null as any };
                            this.props.history.replace(location);
                        });
                    }}
                    renderContent={() => (
                        <div>
                            {this.state.runbookRunsDashboardItems && (
                                <OperationsOverviewTable
                                    initialData={this.state.runbookRunsDashboardItems}
                                    additionalRequestParams={this.getAdditionalRequestParams()}
                                    onRow={(item: any) => this.buildRunbookRunRow(item)}
                                    onFilter={this.filter}
                                    headerColumns={this.state.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? ["Runbook", "Environment", "Task"] : ["Runbook", "Environment", "Tenant", "Task"]}
                                    headerColumnClassNames={
                                        this.state.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted
                                            ? [styles.headerColumn, styles.headerColumn, styles.headerColumn]
                                            : [styles.headerColumnTenanted, styles.headerColumnTenanted, styles.headerColumnTenanted, styles.headerColumnTenanted]
                                    }
                                    onEmpty={this.handleOnEmpty}
                                    filterSearchEnabled={false} // This component has advanced filtering instead.
                                    apiSearchParams={["partialName"]}
                                    filterHintText="Filter by name..."
                                    empty={<NoResults />}
                                />
                            )}
                        </div>
                    )}
                />
            </PaperLayout>
        );
    }

    private buildRunbookRunRow(runbookRunItem: RunbooksDashboardItemResource) {
        const runbook = this.state.associatedRunbooks.find(x => x.Id === runbookRunItem.RunbookId);
        const environment = this.state.environments.find(x => x.Id === runbookRunItem.EnvironmentId);
        if (this.state.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            return [
                <ListTitle>
                    <InternalLink to={routeLinks.project(this.state.project.Slug).operations.runbook(runbook.Id).root}>{runbook.Name}</InternalLink>
                </ListTitle>,
                <EnvironmentChip environmentName={environment.Name} />,
                <RunbookTaskStatusDetails project={this.state.project} item={runbookRunItem} />,
            ];
        } else {
            const tenant = this.state.tenants.find(x => x.Id === runbookRunItem.TenantId);
            return [
                <ListTitle>
                    <InternalLink to={routeLinks.project(this.state.project.Slug).operations.runbook(runbook.Id).root}>{runbook.Name}</InternalLink>
                </ListTitle>,
                <EnvironmentChip environmentName={environment.Name} />,
                tenant ? <TenantChip tenantName={tenant.Name} /> : <div />,
                <RunbookTaskStatusDetails project={this.state.project} item={runbookRunItem} />,
            ];
        }
    }

    private handleOnEmpty = () => {
        return (
            <div className={styles.emptyCell}>
                <PermissionCheck permission={Permission.RunbookEdit} project={this.state.project.Id} tenant="*">
                    No runs found. <InternalNavLink to={routeLinks.project(this.state.project.Slug).operations.runbooks}> Go to Runbooks</InternalNavLink>
                </PermissionCheck>
            </div>
        );
    };

    private getAdditionalRequestParams(): Map<keyof GetTaskRunDashboardItemsListArgs, any> {
        const additionalRequestParams = new Map<keyof GetTaskRunDashboardItemsListArgs, any>();
        additionalRequestParams.set("projectIds", [this.state.project.Id]);
        additionalRequestParams.set("environmentIds", this.state.filter.environmentIds);
        additionalRequestParams.set("tenantIds", this.state.filter.tenantIds);
        return additionalRequestParams;
    }

    private filter(filter: string, resource: RunbooksDashboardItemResource) {
        return !filter || filter.length === 0 || !resource || resource.RunbookSnapshotName.toLowerCase().includes(filter.toLowerCase());
    }

    private setFilterState<K extends keyof OperationsOverviewLayoutFilter>(state: Pick<OperationsOverviewLayoutFilter, K>, callback?: () => void) {
        this.setState(
            prev => ({
                filter: { ...(prev.filter as object), ...(state as object) },
            }),
            callback
        );
    }

    private isFiltering() {
        return !isEqual(this.state.filter, createEmptyFilter());
    }

    private async onFilterChange() {
        await this.reloadRuns();
    }

    private queryFromFilter = (filter: OperationsOverviewLayoutFilter): OperationsOverviewLayoutQuery => {
        const query: OperationsOverviewLayoutQuery = {
            environmentIds: filter.environmentIds,
            tenantIds: filter.tenantIds,
            tenantTags: filter.tenantTags,
        };

        return query;
    };

    private getFilter = (query: OperationsOverviewLayoutQuery): OperationsOverviewLayoutFilter => {
        const filter: OperationsOverviewLayoutFilter = {
            ...createEmptyFilter(),
            environmentIds: arrayValueFromQueryString(query.environmentIds) || [],
            tenantIds: arrayValueFromQueryString(query.tenantIds) || [],
            tenantTags: arrayValueFromQueryString(query.tenantTags) || [], // Expecting canonical tag names
        };

        return filter;
    };
}

function createEmptyFilter(): OperationsOverviewLayoutFilter {
    return {
        environmentIds: [],
        tenantIds: [],
        tenantTags: [],
    };
}

export default withProjectContext(OperationsOverviewLayoutInternal);
