import * as React from "react";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout";
import Section from "components/Section";
import Markdown from "components/Markdown/index";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import EventListing from "components/EventListing/EventListing";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { Permission } from "client/resources/permission";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { Callout, CalloutType } from "components/Callout/Callout";
import { RunbookSnapshotResource, EnvironmentResource, EventResource, ArtifactResource, RunbookRunResource, ResourceCollection, ProjectResource, TaskResource, PackageReferenceNamesMatch, EventCategoryResource } from "client/resources";
import { ResourcesById } from "client/repositories/basicRepository";
import * as _ from "lodash";
import { FormSectionHeading, Note } from "components/form";
import StringHelper from "utils/StringHelper";
import ActionList from "components/ActionList/ActionList";
import routeLinks from "routeLinks";
import { ProjectRouteParams } from "areas/projects/components/ProjectLayout";
import { RouteComponentProps } from "react-router";
import isBound from "components/form/BoundField/isBound";
import { PackageNote } from "client/repositories/packageRepository";
import ArtifactLink from "areas/tasks/components/Task/Artifacts/ArtifactLink";
const styles = require("./RunbookSnapshotInfo.less");
import { session } from "clientInstance";
import { List } from "components/List/List";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import TimeFromNowLabel from "components/TimeLabels/TimeFromNowLabel";
import DateFormatter from "utils/DateFormatter/DateFormatter";
import InternalRedirect from "components/Navigation/InternalRedirect";
import NavigationButton from "components/Button/NavigationButton";
import { PackageModel } from "../../Releases/packageModel";
import { buildPartialReleaseNotes } from "../../Releases/releaseNoteHelper";
import { DeploymentCreateGoal } from "../../Releases/ReleasesRoutes/releaseRouteLinks";
import PackagesList from "../../Releases/PackagesList/PackagesList";
import { RunbookRouteProps } from "./RunbookSnapshots";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { withRunbookContext, WithRunbookContextInjectedProps } from "../RunbookContext";
import { isEqual } from "lodash";

interface RunbookSnapshotInfoState extends DataBaseComponentState {
    project: ProjectResource;
    runbookSnapshot: RunbookSnapshotResource;
    showUnblockRunbookSnapshotDialog: boolean;
    environmentsById: ResourcesById<EnvironmentResource>;
    packages: PackageModel[];
    events: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
    artifacts: ResourceCollection<ArtifactResource>;
    runbookRuns: RunbookRunResource[];
    runbookRunTasks: Array<TaskResource<{ RunbookRunId: string }>>;
    showFullNotes: boolean;
    isInitialLoad: boolean;
    currentPageIndex?: number;
    currentSkip: number;
    variableSnapshotRefreshKey: string;
    deleted: boolean;
}

class ArtifactsList extends List<ArtifactResource> {}

type RunbookSnapshotInfoProps = RouteComponentProps<ProjectRouteParams & RunbookRouteProps & { runbookSnapshotId: string }> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps;

class RunbookSnapshotInfoInternal extends DataBaseComponent<RunbookSnapshotInfoProps, RunbookSnapshotInfoState> {
    private packageResolveMessage: string = "Package will be resolved during runbook run";

    constructor(props: RunbookSnapshotInfoProps) {
        super(props);
        this.state = {
            project: null,
            runbookSnapshot: null,
            showUnblockRunbookSnapshotDialog: false,
            environmentsById: null,
            packages: [],
            events: null,
            eventCategories: null,
            artifacts: null,
            runbookRuns: [],
            runbookRunTasks: [],
            showFullNotes: false,
            isInitialLoad: true,
            currentPageIndex: 0,
            currentSkip: 0,
            variableSnapshotRefreshKey: DateFormatter.timestamp(),
            deleted: false,
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(nextProps: RunbookSnapshotInfoProps) {
        const currentRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const nextRunbook = nextProps.runbookContext.state && nextProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async reload() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        if (!project) {
            return;
        }

        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }

        await this.doBusyTask(async () => {
            const runbookSnapshot = await repository.RunbookSnapshots.get(this.props.match.params.runbookSnapshotId);
            const environmentsById = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? await repository.Environments.allById() : null;

            await this.init(project, runbookSnapshot);
            await this.startRefreshLoop(() => this.refreshActiveComponents(project, runbookSnapshot, environmentsById), 15000);
        });
    }

    render() {
        const runbookLinks = routeLinks.project(this.props.match.params.projectSlug).operations.runbook(this.props.match.params.runbookId);
        if (this.state.deleted) {
            return <InternalRedirect to={runbookLinks.runbookSnapshots} push={true} />;
        }

        const runbookSnapshotLinks = runbookLinks.runbookSnapshot(this.props.match.params.runbookSnapshotId);
        const overflowActions = [];
        if (this.state.project) {
            overflowActions.push(OverflowMenuItems.navItem("Edit", runbookSnapshotLinks.edit, null, { permission: Permission.ReleaseEdit, project: this.state.project.Id, tenant: "*" }));
        }
        if (this.state.runbookSnapshot) {
            overflowActions.push(
                OverflowMenuItems.deleteItemDefault(
                    "runbook snapshot",
                    this.handleDeleteConfirm,
                    {
                        permission: Permission.ReleaseDelete,
                        project: this.state.project && this.state.project.Id,
                        tenant: "*",
                    },
                    "The runbook snapshot and any of its runs will be permanently deleted and they will disappear from all dashboards."
                )
            );
            overflowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.runbookSnapshot.Id]), null, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const actions = [];
        if (this.state.project) {
            actions.push(
                <PermissionCheck permission={Permission.DeploymentCreate} project={this.state.project.Id} environment="*" tenant="*">
                    <NavigationButton label="Run on..." href={runbookSnapshotLinks.runbookRuns.create(DeploymentCreateGoal.To)} />
                </PermissionCheck>
            );
        }
        if (this.state.runbookSnapshot) {
            actions.push(<OverflowMenu menuItems={overflowActions} />);
        }
        const sectionControl = <ActionList actions={actions} />;
        return (
            <PaperLayout title={this.runbookSnapshotTitle()} breadcrumbTitle={"Snapshots"} breadcrumbPath={runbookLinks.runbookSnapshots} busy={this.state.busy} errors={this.state.errors} sectionControl={sectionControl}>
                <div className={styles.runbookSnapshotDetailsLayout}>
                    {this.state.runbookSnapshot && (
                        <div className={styles.runbookSnapshotDetailsLayoutContent}>
                            {this.state.runbookSnapshot && this.state.runbookSnapshot.Notes && this.getRunbookSnapshotNoteSection()}
                            <FormSectionHeading key="packages" title="Packages" />
                            <Section key="sectionPackages" sectionHeader="">
                                <PermissionCheck
                                    permission={Permission.FeedView}
                                    alternate={
                                        <Callout type={CalloutType.Information} title={"Permission required"}>
                                            The {Permission.FeedView} permission is required to view packages
                                        </Callout>
                                    }
                                >
                                    <PermissionCheck
                                        permission={Permission.DeploymentView}
                                        project={this.state.runbookSnapshot.ProjectId}
                                        wildcard={true}
                                        alternate={
                                            <Callout type={CalloutType.Information} title={"Permission required"}>
                                                The {Permission.DeploymentView} permission is required to view packages
                                            </Callout>
                                        }
                                    >
                                        <div className={styles.runbookSnapshotPackagesLayout}>
                                            <PackagesList packages={this.state.packages} buildInformation={null} />
                                        </div>
                                    </PermissionCheck>
                                </PermissionCheck>
                            </Section>
                            {this.state.artifacts && [
                                <FormSectionHeading key="artifacts" title="Artifacts" />,
                                <div className={styles.runbookSnapshotArtifactsLayout}>
                                    <ArtifactsList
                                        initialData={this.state.artifacts}
                                        onRow={(artifact: ArtifactResource) => [
                                            <ArtifactLink artifact={artifact} key="link" />,
                                            <div key="time" className={styles.time}>
                                                <TimeFromNowLabel time={artifact.Created} />
                                            </div>,
                                        ]}
                                        showPagingInNumberedStyle={true}
                                        currentPageIndex={this.state.currentPageIndex}
                                        onPageSelected={this.handleArtifactsPageSelected}
                                        empty={
                                            <Note>
                                                No artifacts have been added. Learn more about <ExternalLink href="Artifacts">collecting artifacts</ExternalLink>.
                                            </Note>
                                        }
                                    />
                                </div>,
                            ]}
                            <FormSectionHeading key="runbookRunHistory" title="Run history" />
                            <PermissionCheck
                                permission={Permission.DeploymentView}
                                project={this.state.runbookSnapshot.ProjectId}
                                wildcard={true}
                                alternate={
                                    <Callout type={CalloutType.Information} title={"Permission required"}>
                                        The {Permission.DeploymentView} permission is required to view the runbook run history
                                    </Callout>
                                }
                            >
                                <EventListing data={this.state.events} regarding={[this.state.runbookSnapshot.Id]} eventCategories={this.state.eventCategories} />
                            </PermissionCheck>
                        </div>
                    )}
                </div>
            </PaperLayout>
        );
    }

    private async init(project: ProjectResource, runbookSnapshot: RunbookSnapshotResource) {
        const runbookProcess = isAllowed({ permission: Permission.DeploymentView, project: project.Id, wildcard: true }) ? await repository.RunbookProcess.get(runbookSnapshot.FrozenRunbookProcessId) : null;
        const template = runbookProcess && (await repository.RunbookProcess.getRunbookSnapshotTemplate(runbookProcess, runbookSnapshot.Id));

        const allPackages = template
            ? template.Packages.map(packageTemplate => {
                  const selectionForStep = runbookSnapshot.SelectedPackages.find(selected => selected.ActionName === packageTemplate.ActionName && PackageReferenceNamesMatch(selected.PackageReferenceName, packageTemplate.PackageReferenceName));

                  if (selectionForStep) {
                      return {
                          ActionName: packageTemplate.ActionName,
                          PackageId: packageTemplate.PackageId,
                          PackageReferenceName: packageTemplate.PackageReferenceName,
                          ProjectName: packageTemplate.ProjectName,
                          FeedName: packageTemplate.FeedName,
                          FeedId: packageTemplate.FeedId,
                          Version: (selectionForStep as any).Version,
                          Notes: {
                              Notes: null,
                              Succeeded: true,
                              FailureReason: null,
                          },
                      };
                  }
              }).filter(p => p)
            : [];

        this.setState(() => {
            return { packages: allPackages };
        });

        if (isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true })) {
            this.buildPackages(allPackages);
        }
    }

    private async loadTasks(runbookRuns: RunbookRunResource[]) {
        const ids = runbookRuns
            .filter(runbookRun =>
                session.currentPermissions.scopeToSpace(repository.spaceId).isAuthorized({
                    permission: Permission.TaskView,
                    projectId: runbookRun.ProjectId,
                    environmentId: runbookRun.EnvironmentId,
                    tenantId: runbookRun.TenantId,
                })
            )
            .map(runbookRun => runbookRun.TaskId);

        return repository.Tasks.byIds(ids);
    }

    private async refreshActiveComponents(project: ProjectResource, runbookSnapshot: RunbookSnapshotResource, environmentsById: ResourcesById<EnvironmentResource>) {
        //}: Promise<RunbookSnapshotInfoState> {
        const [artifacts, events, eventCategories, runbookRunsCollection] = await Promise.all([
            this.loadArtifactsPromise(runbookSnapshot, this.state.currentSkip),
            isAllowed({ permission: Permission.EventView, project: project.Id, wildcard: true }) ? repository.Events.list({ regarding: [runbookSnapshot.Id] }) : null,
            repository.Events.categories({}),
            isAllowed({ permission: Permission.DeploymentView, project: project.Id, wildcard: true }) ? repository.RunbookSnapshots.getRunbookRuns(runbookSnapshot, { take: 1000 }) : null,
        ]);

        const runbookRuns = runbookRunsCollection && runbookRunsCollection.Items;
        const tasksPromise = runbookRuns && this.loadTasks(runbookRuns);
        const runbookRunsByPhase: { [name: string]: RunbookRunResource[] } = {};

        const resultForState = {
            ...this.state,
            project,
            runbookSnapshot,
            environmentsById,
            artifacts,
            events,
            eventCategories,
            runbookRuns,
            runbookRunsByPhase,
            runbookRunTasks: isAllowed({ permission: Permission.TaskView, project: project.Id, wildcard: true }) ? await tasksPromise : [],
            isInitialLoad: false,
        };

        return resultForState;
    }

    private buildPackages(packages: PackageModel[]) {
        const grouped: { [feedId: string]: PackageModel[] } = _.groupBy(packages, pkg => {
            return pkg.FeedId;
        });
        for (const feedId in grouped) {
            if (isBound(feedId, false)) {
                grouped[feedId].forEach(p => (p.Notes = { Succeeded: true, Notes: this.packageResolveMessage, FailureReason: null }));
            } else {
                this.loadPackages(grouped[feedId]);
            }
        }
    }

    private loadPackages(allPackages: PackageModel[]) {
        // Create an object with two arrays, one containing bound packages, and the
        // other containing unbound packages.
        const boundUnbound = allPackages.reduce(
            (container, pkg) => {
                (isBound(pkg.PackageId, false) ? container.bound : container.unBound).push(pkg);
                return container;
            },
            { bound: [], unBound: [] }
        );

        // Bound packages all get a standard runbookSnapshot notes string
        this.setState(existingState => {
            boundUnbound.bound.forEach(bound => (bound.Notes = this.packageResolveMessage));
            return {
                packages: [..._.differenceWith(existingState.packages, boundUnbound.bound, this.packageNoteEquals), ...boundUnbound.bound],
            };
        });

        // This is how many concurrent packages to query with each request. This
        // is based on a fixed number of concurrent requests, which is limited
        // by the browser. Chrome has a limit of 5, but we need to leave a few requests
        // for other calls made by this page, so we devote 3 to the runbookSnapshot notes.
        // We limit the batch size to 30 though. If there are hundreds of packages
        // we don't want to max out the URL length.
        const requests = Math.min(Math.ceil(boundUnbound.unBound.length / 3), 30);

        // This is the array that will hold chunks of the allPackages array. So an
        // array of PackageModel[30] becomes PackageModel[3][10] (roughly speaking).
        const splitAllPackages: PackageModel[][] = [];

        // Split the original array into a bunch of smaller arrays which will be
        // processed in parallel.
        while (boundUnbound.unBound.length > 0) {
            splitAllPackages.push(boundUnbound.unBound.splice(0, requests));
        }

        // Now process each group of packages with a concurrent request
        splitAllPackages.forEach(pkgs => {
            repository.Packages.getNotes(pkgs)
                .then(pkgsDetails => {
                    this.setState(existingState => {
                        // for every package that was returned, update the existing package
                        // with the returned notes.
                        const updated = existingState.packages.map(existing => _.assign(existing, this.findMatchingNotesPackage(existing, pkgsDetails.Packages)));

                        return {
                            packages: updated,
                        };
                    });
                })
                .catch(err => {
                    this.setState(existingState => {
                        // for every package that was requested, set the state to error.
                        const updated = existingState.packages.map(existing => _.assign(existing, this.findMatchingNotesPackage(existing, pkgs, { Notes: { Success: false, FailureMessage: err.ErrorMessage } })));

                        return {
                            packages: updated,
                        };
                    });
                });
        });
    }

    /**
     * Finding runbookSnapshot notes in a bulk fashion from the server means:
     * 1. Requesting the package details (in a request with a bunch of other packages)
     * 2. Assigning the returned details back to the matching packages from the state
     * 3. Optionally setting the some additional field, typically when a batch request failed and all packages need to show an error
     * This function will attempt to find a matching package from the list of returned packages, and if so assign the values from 3
     * to it, and then return it. Otherwise it will return an empty object. The returned object is expected to be assigned to
     * the package in the state to result in an updated package object that can be displayed to the user.
     * @param {PackageNote} original The original package details to match against the package returned by the server
     * @param {PackageNote[]} packages The list of packages returned by the server
     * @param assign An object that is assigned to the matching package, if one was found. It is like an "overlay" on matching packages.
     * @returns {(PackageNote | undefined) | {}} An empty object if no match was found, and the returned package
     * with the assign object assigned to it.
     */
    private findMatchingNotesPackage(original: PackageNote, packages: PackageNote[], assign: any = null) {
        const packageWithNotes = packages.find(pkgWithNotes => this.packageNoteEquals(pkgWithNotes, original));
        if (packageWithNotes) {
            if (assign) {
                _.assign(packageWithNotes, assign);
            }
        }
        return packageWithNotes || {};
    }

    private packageNoteEquals(a: PackageNote, b: PackageNote) {
        return a.PackageId === b.PackageId && a.Version === b.Version && a.FeedId === b.FeedId;
    }

    private runbookSnapshotTitle() {
        return this.state.runbookSnapshot ? this.state.runbookSnapshot && this.state.runbookSnapshot.Name : StringHelper.ellipsis;
    }

    private buildNotes() {
        if (this.state.showFullNotes) {
            return <Markdown markup={this.state.runbookSnapshot.Notes} />;
        }
        const [runbookSnapshotNotes, isTruncated] = buildPartialReleaseNotes(this.state.runbookSnapshot.Notes, 10);
        return (
            <div>
                <Markdown markup={runbookSnapshotNotes} />
                {isTruncated && <ActionButton type={ActionButtonType.Ternary} onClick={() => this.setState({ showFullNotes: true })} label="show more" />}
            </div>
        );
    }

    private getRunbookSnapshotNoteSection() {
        return [
            <FormSectionHeading key="runbookSnapshotNoteHeading" title="Snapshot notes" />,
            <Section key="runbookSnapshotSection" sectionHeader="">
                <div className={styles.runbookSnapshotNoteLayout}>{this.buildNotes()}</div>
            </Section>,
        ];
    }

    private loadArtifactsPromise = (runbookSnapshot: RunbookSnapshotResource, skip: number) => {
        return isAllowed({ permission: Permission.ArtifactView, wildcard: true }) ? repository.Artifacts.list({ regarding: runbookSnapshot.Id, skip, take: 10, order: "asc" }) : null;
    };

    private handleArtifactsPageSelected = async (skip: number, p: number) => {
        this.setState({ currentPageIndex: p, currentSkip: skip });
        this.setState({ artifacts: await this.loadArtifactsPromise(this.state.runbookSnapshot, skip) });
    };

    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (this.state.runbookSnapshot) {
            await repository.RunbookSnapshots.del(this.state.runbookSnapshot);
            this.setState({ deleted: true });
            return true;
        } else {
            return false;
        }
    };
}

export default withRunbookContext(withProjectContext(RunbookSnapshotInfoInternal));
