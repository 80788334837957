import { flatten } from "lodash";
import MaterialMenuItem from "material-ui/MenuItem";
import ArrowDropRight from "material-ui/svg-icons/navigation-arrow-drop-right";
import * as React from "react";
import { Link } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import { resolvePathWithSpaceId, resolveStringPathWithSpaceId } from "../Navigation/resolvePathWithSpaceId";
import { isUrlActive } from "../Navigation/isUrlActive";

export interface MenuItem {
    url: string;
    text: string;
    title?: string;
    icon?: JSX.Element;
    exact?: boolean;
}

export interface MenuGroup {
    label: string | JSX.Element;
    children: MenuNode[];
}

export interface MenuPopper {
    icon: JSX.Element;
    url: string;
    exact?: boolean;
    text?: string;
    title?: string;
    onClick: (e: HTMLElement) => void;
}

export type MenuNode = MenuGroup | MenuItem | MenuPopper;

interface MenuNodeComponentProps {
    node: MenuNode;
    onClick: () => void;
}

type MenuNodeProps = MenuNodeComponentProps & RouteComponentProps<{ spaceId: string }>;

const MenuNodeComponent: React.SFC<MenuNodeProps> = props => {
    const activeStyle = { color: "#2f93e0" };

    if (isMenuGroup(props.node)) {
        return (
            <MaterialMenuItem
                style={hasActiveDescendants(props.location.pathname, props.match.params.spaceId, props.node) ? activeStyle : {}}
                primaryText={props.node.label}
                rightIcon={<ArrowDropRight />}
                menuItems={props.node.children.map((c, index) => (
                    <RoutedMenuNodeComponent node={c} key={index} onClick={props.onClick} />
                ))}
            />
        );
    }

    if (isMenuPopper(props.node)) {
        return (
            <InternalLink to={props.node.url}>
                <MaterialMenuItem leftIcon={props.node.icon} onClick={() => props.onClick()} />
            </InternalLink>
        );
    }

    return (
        <InternalLink to={props.node.url}>
            <MaterialMenuItem style={isUrlActive(props.location.pathname, props.match.params.spaceId, props.node.url, props.node.exact) ? activeStyle : {}} primaryText={props.node.text} onClick={() => props.onClick()} />
        </InternalLink>
    );
};

const RoutedMenuNodeComponent = withRouter(MenuNodeComponent);

export { RoutedMenuNodeComponent as MenuNodeComponent };

export function isMenuGroup(node: MenuNode): node is MenuGroup {
    return (node as MenuGroup).children !== undefined;
}

export function isMenuPopper(node: MenuNode): node is MenuPopper {
    return (node as MenuPopper).onClick !== undefined;
}

export function hasActiveDescendants(pathname: string, spaceId: string, group: MenuGroup): boolean {
    const allDescendantLinks = (_: MenuGroup): Array<MenuItem | MenuPopper> =>
        flatten(
            _.children.map((c: MenuNode) => {
                if (isMenuGroup(c)) {
                    return allDescendantLinks(c);
                }
                return [c];
            })
        );

    return allDescendantLinks(group).some(l => isUrlActive(pathname, spaceId, l.url, l.exact));
}
