import * as React from "react";
import * as _ from "lodash";
import { RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import { ProjectRouteParams } from "../ProjectLayout";
import { Permission, RunbookResource, ProjectResource, RunbookProcessResource } from "client/resources";
import { withRunbookContext, WithRunbookContextInjectedProps } from "./RunbookContext";
import { ExpandableFormSection, MarkdownEditor, Summary, Text, required } from "components/form";
import { repository } from "clientInstance";
import { FormBaseComponent, FormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { cloneDeep, isEqual } from "lodash";
import RunbooksFormPaperLayout from "./Layouts/RunbooksFormPaperLayout";
import Markdown from "components/Markdown";
import DeleteRunbook from "./DeleteRunbook";
import InternalRedirect from "components/Navigation/InternalRedirect";
import { OverflowMenuItems } from "components/Menu";
import { PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import AddRunbook from "./AddRunbook";
import RunNowButton from "./RunNowButton";
import { withProjectContext, WithProjectContextInjectedProps } from "areas/projects/context/withProjectContext";

// tslint:disable-next-line:no-empty-interface
interface RunbookSettingsLayoutState extends FormBaseComponentState<RunbookResource> {
    project: ProjectResource;
    redirectTo: string;
    canDelete: boolean;
    steps: RunbookProcessResource;
}

export interface RunbookSettingsLayoutRouteProps {
    runbookId: string;
}

type RunbookSettingsLayoutProps = RouteComponentProps<ProjectRouteParams & RunbookSettingsLayoutRouteProps> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps;

class RunbookSettingsLayout extends FormBaseComponent<RunbookSettingsLayoutProps, RunbookSettingsLayoutState, RunbookResource> {
    constructor(props: RunbookSettingsLayoutProps) {
        super(props);
        this.state = {
            model: null,
            cleanModel: null,
            project: null,
            redirectTo: null,
            canDelete: false,
            steps: null,
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(nextProps: RunbookSettingsLayoutProps) {
        const currentRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const nextRunbook = nextProps.runbookContext.state && nextProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async reload() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        if (!project) {
            return;
        }

        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }

        await this.doBusyTask(async () => {
            const steps = await repository.RunbookProcess.get(runbook.RunbookProcessId);
            this.setState({
                model: runbook,
                steps,
                cleanModel: cloneDeep(runbook),
                project,
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const overFlowActions = [];
        if (this.state.project) {
            const CloneDialog = () => <AddRunbook projectId={this.state.project.Id} onProcessCreated={id => this.setState({ redirectTo: routeLinks.project(this.state.project.Slug).operations.runbook(id).root })} cloneId={this.state.model.Id} />;
            overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog />, this.clonePermission()));
            overFlowActions.push(
                OverflowMenuItems.deleteItem(
                    "Delete",
                    "Are you sure you want to delete this Runbook?",
                    this.handleDeleteConfirm,
                    dialogDoBusyTask => <DeleteRunbook doBusyTask={dialogDoBusyTask} runbookName={this.state.model.Name} runbookId={this.state.model.Id} onChange={this.onDeleteChanged} />,
                    this.deletePermission(),
                    !this.state.canDelete
                )
            );
            overFlowActions.push(
                OverflowMenuItems.navItem("View Snapshots", routeLinks.project(this.state.project.Slug).operations.runbook(this.state.model.Id).runbookSnapshots, null, {
                    permission: Permission.ReleaseView,
                    project: this.state.project.Id,
                    projectGroup: this.state.project.ProjectGroupId,
                    tenant: "*",
                })
            );
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.runbookEventsForProject(this.state.project.Id, this.state.model.Id), null, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        return (
            <RunbooksFormPaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title={this.state.model && this.state.model.Name}
                breadcrumbTitle={"Runbooks"}
                breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).operations.runbooks}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                onSaveClick={this.handleSaveClick}
                savePermission={this.editPermission()}
                saveText=""
                secondaryAction={<RunNowButton isDisabled={!this.state.steps || (this.state.steps && this.state.steps.Steps.length === 0)} />}
                overFlowActions={overFlowActions}
                hideExpandAll={true}
                {...this.props}
            >
                {this.state.model && (
                    <>
                        <ExpandableFormSection
                            errorKey="Name"
                            title="Name"
                            focusOnExpandAll
                            summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for the Runbook")}
                            help="Enter a name for the Runbook"
                        >
                            <Text value={this.state.model.Name} onChange={Name => this.setModelState({ Name })} label="Name" validate={required("Please enter a name")} error={this.getFieldError("Name")} autoFocus={true} />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="Description"
                            title="Description"
                            summary={this.state.model.Description ? Summary.summary(<Markdown markup={this.state.model.Description} />) : Summary.placeholder("No description provided")}
                            help="Enter a description for the Runbook"
                        >
                            <MarkdownEditor value={this.state.model.Description} label="Description" onChange={Description => this.setModelState({ Description })} />
                        </ExpandableFormSection>
                    </>
                )}
            </RunbooksFormPaperLayout>
        );
    }

    private onDeleteChanged = (canDelete: boolean) => {
        this.setState({ canDelete });
    };

    private clonePermission(): PermissionCheckProps {
        return {
            permission: Permission.RunbookEdit,
            projectGroup: this.state.project && this.state.project.ProjectGroupId,
        };
    }

    private deletePermission(): PermissionCheckProps {
        return {
            permission: Permission.RunbookEdit,
            project: this.state.project && this.state.project.Id,
            tenant: "*",
        };
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.RunbookEdit,
            project: this.state.project && this.state.project.Id,
            tenant: "*",
        };
    }

    private handleDeleteConfirm = async () => {
        await repository.Runbooks.del(this.state.model);
        this.setState({ redirectTo: routeLinks.project(this.props.match.params.projectSlug).operations.runbooks });
        return true;
    };

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const result = await repository.Runbooks.modify(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
            });

            this.props.runbookContext.actions.onRunbookUpdated(result);
        });
    };
}

const EnhancedRunbookSettingsLayout = withRunbookContext(withProjectContext(RunbookSettingsLayout));
export default EnhancedRunbookSettingsLayout;
