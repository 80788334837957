import * as React from "react";
import { OverflowMenuButton } from "components/IconMenu";
import MaterialMenuItem from "material-ui/MenuItem";
import Divider from "components/Divider/Divider";
import OpenDeleteDialogMenuItem from "../Menu/OpenDeleteDialogMenuItem";
import { isAllowed, PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import { flatten } from "lodash";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import OpenDialogMenuItem from "components/Menu/OpenDialogMenuItem";
const styles = require("./style.less");
import OverflowMenuLink from "./OverflowMenuLink";
import { DoBusyTask } from "../DataBaseComponent/DataBaseComponent";
import OpenConfirmUpgradeDialogMenuItem from "./OpenConfirmUpgradeDialogMenuItem";
import { noOp } from "utils/noOp";
import { actions, selectors } from "./store";
import { useSequenceId } from "components/Dialog/withDialogIdentifier";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ToolTip, { ToolTipPosition } from "components/ToolTip";

enum OverflowMenuItemKind {
    Delete,
    Dialog,
    Generic,
    Disabled,
    Navigation,
    Download,
    Remove,
    ConfirmUpgrade,
}

interface ItemTooltip {
    text: string;
    position?: ToolTipPosition;
}
interface Item {
    kind: OverflowMenuItemKind;
    tooltip?: ItemTooltip;
}

export interface OverflowMenuDialogItem extends Item {
    text: string;
    child: JSX.Element;
    permission?: PermissionCheckProps;
}

export interface OverflowMenuDeleteItem extends Item {
    text: string;
    title: string;
    onClick: () => Promise<boolean>;
    content: React.ReactNode;
    permission?: PermissionCheckProps;
    deleteButtonDisabled: boolean;
}

export interface OverflowMenuRemoveItem extends Item {
    text: string;
    title: string;
    onClick: () => Promise<boolean>;
    content: React.ReactNode;
    permission?: PermissionCheckProps;
    removeButtonDisabled: boolean;
}

export interface OverflowMenuConfirmUpgradeItem extends Item {
    text: string;
    title: string;
    content: React.ReactNode;
    permission?: PermissionCheckProps;
    confirmButtonDisabled: boolean;
    onClick: () => Promise<boolean>;
}

export interface OverflowMenuNavLink extends Item {
    text: string;
    path: string;
    queryString?: string;
    permission?: PermissionCheckProps;
}

export interface OverflowMenuDownloadItem extends Item {
    text: string;
    link: string;
    filename: string;
    permission?: PermissionCheckProps;
}

export interface OverflowMenuDisabledItem extends Item {
    text: string;
    reason: string;
}

export interface OverflowMenuGenericItem extends Item {
    text: string;
    permission?: PermissionCheckProps;
    onClick: () => void;
}

function isGroup(item: MenuItem | MenuItem[]): item is MenuItem[] {
    return Array.isArray(item as MenuItem[]);
}

function isOverflowMenuDialogItem(item: MenuItem): item is OverflowMenuDialogItem {
    return (item as OverflowMenuDialogItem).kind === OverflowMenuItemKind.Dialog;
}

function isOverflowMenuDeleteItem(item: MenuItem): item is OverflowMenuDeleteItem {
    return (item as OverflowMenuDeleteItem).kind === OverflowMenuItemKind.Delete;
}

function isOverflowMenuRemoveItem(item: MenuItem): item is OverflowMenuRemoveItem {
    return (item as OverflowMenuRemoveItem).kind === OverflowMenuItemKind.Remove;
}

function isOverflowMenuConfirmUpgradeItem(item: MenuItem): item is OverflowMenuConfirmUpgradeItem {
    return (item as OverflowMenuConfirmUpgradeItem).kind === OverflowMenuItemKind.ConfirmUpgrade;
}

function isOverflowMenuDownloadItem(item: MenuItem): item is OverflowMenuDownloadItem {
    return (item as OverflowMenuDownloadItem).kind === OverflowMenuItemKind.Download;
}

function isOverflowMenuNavLink(item: MenuItem): item is OverflowMenuNavLink {
    return (item as OverflowMenuNavLink).kind === OverflowMenuItemKind.Navigation;
}

function isOverflowMenuDisabledItem(item: MenuItem): item is OverflowMenuDisabledItem {
    return (item as OverflowMenuDisabledItem).kind === OverflowMenuItemKind.Disabled;
}

function isOverflowMenuGenericItem(item: MenuItem): item is OverflowMenuGenericItem {
    return (item as OverflowMenuGenericItem).kind === OverflowMenuItemKind.Generic;
}

export type MenuItem = OverflowMenuDialogItem | OverflowMenuDeleteItem | OverflowMenuNavLink | OverflowMenuDownloadItem | OverflowMenuGenericItem | OverflowMenuDisabledItem | OverflowMenuRemoveItem | OverflowMenuConfirmUpgradeItem;

interface ConversionResult {
    menuItem: React.ReactNode;
    dialog?: React.ReactNode;
}

interface OverflowMenuProps {
    menuItems: Array<MenuItem | MenuItem[]>;
    tabIndex?: number;
    menuKey?: string;
    colorOverride?: string;
    className?: string;
}

interface GlobalConnectedProps {
    open: boolean;
}

interface GlobalDispatchProps {
    onOpen: () => void;
    onClose: () => void;
}

const ClickTrap: React.FC = ({ children }) => {
    return (
        <div
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {children}
        </div>
    );
};

export class OverflowMenuItems {
    static defaultConfirmUpgradeText = "Please note: This is a blocking task and will prevent deployments during the upgrade.";

    static dialogItem(text: string, child: JSX.Element, permission?: PermissionCheckProps): OverflowMenuDialogItem {
        return { text, child, kind: OverflowMenuItemKind.Dialog, permission };
    }

    static removeItem(text: string, title: string, onClick: () => Promise<boolean>, content: React.ReactNode, permission?: PermissionCheckProps, removeButtonDisabled: boolean = false): OverflowMenuRemoveItem {
        return { text, title, onClick, content, permission, kind: OverflowMenuItemKind.Remove, removeButtonDisabled };
    }

    static deleteItem(
        text: string,
        title: string,
        onClick: () => Promise<boolean>,
        content: ((doBusyTask: DoBusyTask) => React.ReactNode) | React.ReactNode,
        permission?: PermissionCheckProps,
        deleteButtonDisabled: boolean = false
    ): OverflowMenuDeleteItem {
        return { text, title, onClick, content, permission, kind: OverflowMenuItemKind.Delete, deleteButtonDisabled };
    }

    static deleteItemDefault(name: string, onClick: () => Promise<boolean>, permission?: PermissionCheckProps, customMessage?: string, customContent?: JSX.Element, deleteButtonDisabled: boolean = false): OverflowMenuDeleteItem {
        return {
            text: "Delete",
            title: `Are you sure you want to delete this ${name}?`,
            onClick,
            permission,
            kind: OverflowMenuItemKind.Delete,
            deleteButtonDisabled,
            content: (
                <div>
                    {customMessage && <p>{customMessage}</p>}
                    {customContent}
                    <p>Do you wish to continue?</p>
                </div>
            ),
        };
    }

    static confirmUpgrade(title: string, onClick: () => Promise<boolean>, permission: PermissionCheckProps, customMessage: React.ReactNode = OverflowMenuItems.defaultConfirmUpgradeText): OverflowMenuConfirmUpgradeItem {
        return {
            text: title,
            title,
            onClick,
            permission,
            kind: OverflowMenuItemKind.ConfirmUpgrade,
            confirmButtonDisabled: false,
            content: (
                <div>
                    {customMessage && <p>{customMessage}</p>}
                    <p>Are you sure?</p>
                </div>
            ),
        };
    }

    static navItem(text: string, path: string, queryString?: string, permission?: PermissionCheckProps): OverflowMenuNavLink {
        return { text, path, queryString, permission, kind: OverflowMenuItemKind.Navigation };
    }

    static disabledItem(text: string, reason: string): OverflowMenuDisabledItem {
        return { text, reason, kind: OverflowMenuItemKind.Disabled };
    }

    static item(text: string, onClick: () => void, permission?: PermissionCheckProps, tooltip?: ItemTooltip): OverflowMenuGenericItem {
        return { text, onClick, permission, kind: OverflowMenuItemKind.Generic, tooltip };
    }

    static downloadItem(text: string, filename: string, link: string) {
        return { text, link, filename, kind: OverflowMenuItemKind.Download };
    }
}
class OverflowMenu extends BaseComponent<OverflowMenuProps & GlobalConnectedProps & GlobalDispatchProps, {}> {
    onIconButtonClick = (event: React.MouseEvent<Element>) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onOpen();
    };

    onClickAway = (event: React.MouseEvent<Document>) => {
        this.props.onClose();
    };

    render() {
        // Permissions may cause null entries in our menuItems, so we do null checking before map.
        const result =
            this.props.menuItems &&
            this.props.menuItems
                .filter(item => !!item)
                .map((item, index) => {
                    if (isGroup(item)) {
                        const results = item.filter(subItem => !!subItem).map(groupItem => this.convert(groupItem));
                        if (results.length === 0) {
                            return null;
                        }
                        // This should be smart enough to know if it needs to add a divider at the start or end of a grouping/array.
                        if (index > 0 && !isGroup(this.props.menuItems[index - 1])) {
                            // Show the divider at the start of this grouping.
                            // I.e. The last thing wasn't a group, so we're good to create one here to indicate the start of a grouping.
                            results.splice(0, 0, { menuItem: <Divider key={index} /> });
                        } else if (index < this.props.menuItems.length - 1) {
                            // Show the divider at the end of this grouping.
                            results.push({ menuItem: <Divider key={index} /> });
                        }
                        return results;
                    }
                    return [this.convert(item)];
                });

        const dialogs: any[] = [];
        const menuItems = flatten(result)
            .filter(r => !!r)
            .map(r => {
                if (r.dialog) {
                    dialogs.push(r.dialog);
                }
                return r.menuItem;
            });

        return (
            <ClickAwayListener onClickAway={this.onClickAway}>
                <div onMouseDown={this.onMouseDownOverride}>
                    <ClickTrap>{dialogs}</ClickTrap>
                    {menuItems && menuItems.length > 0 && (
                        <OverflowMenuButton open={this.props.open} onClick={this.onIconButtonClick} onClose={this.props.onClose}>
                            {menuItems}
                        </OverflowMenuButton>
                    )}
                </div>
            </ClickAwayListener>
        );
    }

    private onMouseDownOverride(e: any) {
        e.stopPropagation(); //prevent this click bubbling up and making any of the parent show a touch ripple (eg when overflow is used on a List)
    }

    private convert(item: MenuItem): ConversionResult {
        if (isOverflowMenuDialogItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return this.alternate(item.permission.alternate);
            }
            return this.convertDialogMenuItem(item);
        }

        if (isOverflowMenuDeleteItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return this.alternate(item.permission.alternate);
            }
            return this.convertDeleteMenuItem(item);
        }

        if (isOverflowMenuRemoveItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return this.alternate(item.permission.alternate);
            }
            return this.convertRemoveMenuItem(item);
        }

        if (isOverflowMenuConfirmUpgradeItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return this.alternate(item.permission.alternate);
            }
            return this.convertConfirmUpgradeMenuItem(item);
        }

        if (isOverflowMenuDownloadItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return this.alternate(item.permission.alternate);
            }
            return {
                menuItem: <MaterialMenuItem primaryText={item.text} key={`${this.props.menuKey}-${item.text}`} containerElement={<OverflowMenuLink resolve={false} downloadFileName={item.filename} to={item.link} />} />,
            };
        }

        if (isOverflowMenuNavLink(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return this.alternate(item.permission.alternate);
            }
            return {
                menuItem: <MaterialMenuItem onClick={e => e.stopPropagation()} primaryText={item.text} key={`${this.props.menuKey}-${item.text}`} containerElement={<OverflowMenuLink to={{ pathname: item.path, search: item.queryString }} />} />,
            };
        }

        if (isOverflowMenuDisabledItem(item)) {
            return {
                menuItem: (
                    <MaterialMenuItem key={`${this.props.menuKey}-${item.text}`} disabled={true}>
                        <a href="#" onClick={e => e.preventDefault()} className={styles.disabledItem} title={item.reason}>
                            {item.text}
                        </a>
                    </MaterialMenuItem>
                ),
            };
        }

        if (isOverflowMenuGenericItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return this.alternate(item.permission.alternate);
            }
        }

        const menuItem = (
            <MaterialMenuItem
                key={`${this.props.menuKey}-${item.text}`}
                primaryText={item.text}
                onClick={e => {
                    //Generic menu items could possibly keep focus so we explicitly close these.
                    this.props.onClose();
                    if (item.onClick) {
                        //If explicitlyhandling click, we really should prevent default behaviors such as links triggering
                        e.preventDefault();
                        item.onClick();
                    }
                }}
            />
        );

        return {
            menuItem: item.tooltip ? (
                <ToolTip key={`${this.props.menuKey}-${item.text}`} content={item.tooltip.text} position={item.tooltip.position}>
                    {menuItem}
                </ToolTip>
            ) : (
                menuItem
            ),
        };
    }

    private convertDeleteMenuItem(item: OverflowMenuDeleteItem) {
        let openDialog: () => void;
        const dialogMenuItem = (
            <OpenDeleteDialogMenuItem
                acceptOnClick={click => (openDialog = click)}
                label={item.text}
                disabled={false}
                deleteButtonDisabled={item.deleteButtonDisabled}
                dialogTitle={item.title}
                key={`${this.props.menuKey}-${item.text}`}
                onDeleteClick={item.onClick}
                renderContent={(doBusyTask: DoBusyTask) => {
                    if (typeof item.content === "function") {
                        return item.content(doBusyTask);
                    }
                    return item.content;
                }}
            />
        );

        return {
            menuItem: (
                <ClickTrap>
                    <MaterialMenuItem
                        primaryText={item.text}
                        key={`${this.props.menuKey}-${item.text}`}
                        onClick={() => {
                            openDialog ? openDialog() : noOp();
                        }}
                    />
                </ClickTrap>
            ),
            dialog: dialogMenuItem,
        };
    }

    private convertRemoveMenuItem(item: OverflowMenuRemoveItem) {
        let openDialog: () => void;
        const dialogMenuItem = (
            <OpenDeleteDialogMenuItem
                acceptOnClick={click => (openDialog = click)}
                label={item.text}
                disabled={false}
                deleteButtonDisabled={item.removeButtonDisabled}
                dialogTitle={item.title}
                deleteButtonLabel="Remove"
                deleteButtonBusyLabel="Removing"
                key={`${this.props.menuKey}-${item.text}`}
                onDeleteClick={item.onClick}
                renderContent={() => item.content}
            />
        );

        return {
            menuItem: (
                <ClickTrap>
                    <MaterialMenuItem primaryText={item.text} key={`${this.props.menuKey}-${item.text}`} onClick={() => (openDialog ? openDialog() : noOp())} />
                </ClickTrap>
            ),
            dialog: dialogMenuItem,
        };
    }

    private convertConfirmUpgradeMenuItem(item: OverflowMenuConfirmUpgradeItem) {
        let openDialog: () => void;
        const dialogMenuItem = (
            <OpenConfirmUpgradeDialogMenuItem
                acceptOnClick={click => (openDialog = click)}
                label={item.text}
                disabled={false}
                dialogTitle={item.title}
                confirmButtonDisabled={item.confirmButtonDisabled}
                confirmButtonLabel="Continue"
                confirmButtonBusyLabel="Continuing"
                key={`${this.props.menuKey}-${item.text}`}
                onConfirmUpgradeClick={() => item.onClick()}
                renderContent={() => item.content}
            />
        );

        return {
            menuItem: (
                <ClickTrap>
                    <MaterialMenuItem primaryText={item.text} key={`${this.props.menuKey}-${item.text}`} onClick={() => (openDialog ? openDialog() : noOp())} />
                </ClickTrap>
            ),
            dialog: dialogMenuItem,
        };
    }

    private convertDialogMenuItem(item: OverflowMenuDialogItem) {
        let openDialog: () => void;
        //TODO: Find an alternative to this callback to get the click handler which doesn't result in overflow menus remaining open etc.
        const dialogMenuItem = (
            <OpenDialogMenuItem
                acceptOnClick={click => {
                    openDialog = click;
                }}
                label={item.text}
                key={`${this.props.menuKey}-${item.text}`}
            >
                {item.child}
            </OpenDialogMenuItem>
        );

        return {
            menuItem: (
                <ClickTrap>
                    <MaterialMenuItem primaryText={item.text} key={`${this.props.menuKey}-${item.text}`} onClick={() => (openDialog ? openDialog() : noOp())} />
                </ClickTrap>
            ),
            dialog: dialogMenuItem,
        };
    }

    private alternate(alternate: string) {
        return alternate && { menuItem: <MaterialMenuItem key={alternate} primaryText={alternate} /> };
    }
}

const mapGlobalStateToProps = (state: GlobalState, { sequence }: OverflowMenuProps & { sequence: string }) => {
    return {
        open: selectors.createMenuOpenSelector(sequence)(state),
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>, { sequence }: OverflowMenuProps & { sequence: string }) => {
    return {
        onOpen: () => dispatch(actions.openOnly(sequence)),
        onClose: () => dispatch(actions.close(sequence)),
    };
};

const ConnectedOverflow = connect(
    mapGlobalStateToProps,
    mapGlobalActionDispatchersToProps
)(OverflowMenu);

const SequencedOverflow: React.FC<OverflowMenuProps & { sequence?: string }> = ({ sequence, ...rest }) => {
    const result = useSequenceId(sequence);
    return <ConnectedOverflow sequence={result} {...rest} />;
};

export default SequencedOverflow;
