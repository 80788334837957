import * as React from "react";
import { Switch } from "react-router-dom";
import { Projects } from "../Projects";
import { ProjectLayout } from "../ProjectLayout";
import { TriggersRoute } from "../Triggers";
import VariablesRoute from "../Variables/VariablesRoute";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { RouteComponentProps } from "react-router";
import * as H from "history";
import AreaTitle from "components/AreaTitle/index";
import { repository } from "clientInstance";
import { ProjectRouteParams } from "../ProjectLayout/ProjectLayout";
import SlugSafeRedirect from "components/SlugSafeRedirect/SlugSafeRedirect";
import routeLinks from "routeLinks";
import PaperLayout from "components/PaperLayout";
import { RedirectAs404 } from "components/NotFound/NotFound";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import { ProjectSettings } from "../ProjectSettings";
import DeploymentsRoute from "../DeploymentsRoute";
import { generatePath } from "react-router";
import OperationsRoute from "../OperationsRoute";

const ProjectSettingsPage = withPage({ page: pageIds.project().settings })(ProjectSettings);
const ProjectsPage = withPage({ page: pageIds.projects.root })(Projects);

export class ProjectRoutes extends React.Component<RouteComponentProps<any>, any> {
    async loadSlugFromProjectId(projectId: string, location: H.Location) {
        if (location.state && location.state.project) {
            return location.state.project.Slug;
        }
        return (await repository.Projects.get(projectId)).Slug;
    }

    projectSlugLoading() {
        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.projects.root} title="Projects" />
                <PaperLayout busy={true} fullWidth={true} />
            </main>
        );
    }

    render() {
        const projectSlugParameter = "projectSlug";
        const specificProjectLink = routeLinks.project(`:${projectSlugParameter}`);

        return (
            <Switch>
                <ReloadableRoute path={routeLinks.v3projectRoutes.newProject}>
                    <InternalRedirect to={routeLinks.projects.root} />
                </ReloadableRoute>
                <ReloadableRoute path={specificProjectLink.root}>
                    <SlugSafeRedirect parameter={projectSlugParameter} regexp={/^Projects-[0-9]+$/} getRealParam={this.loadSlugFromProjectId} loadingComponent={this.projectSlugLoading}>
                        <ProjectLayout>
                            <Switch>
                                <ReloadableRoute path={specificProjectLink.settings} component={ProjectSettingsPage} />
                                <ReloadableRoute path={specificProjectLink.triggers} component={TriggersRoute} />
                                <ReloadableRoute path={specificProjectLink.deployments.root} component={DeploymentsRoute} />
                                <OperationsRoute path={specificProjectLink.operations.root} />
                                <ReloadableRoute path={specificProjectLink.variables.root} component={VariablesRoute} />
                                <ReloadableRoute
                                    path={specificProjectLink.steptemplates}
                                    render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).process.stepTemplates} />}
                                />
                                <ReloadableRoute
                                    exact={true}
                                    path={specificProjectLink.overview}
                                    render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.root} />}
                                />
                                <ReloadableRoute
                                    exact={true}
                                    path={specificProjectLink.root}
                                    render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.root} />}
                                />
                                <ReloadableRoute
                                    path={specificProjectLink.childStepTemplates(":parentStepId").root}
                                    render={(props: RouteComponentProps<ProjectRouteParams & { parentStepId: string }>) => (
                                        <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).process.childStepTemplates(props.match.params.parentStepId).root} />
                                    )}
                                />
                                <ReloadableRoute
                                    path={`${specificProjectLink.channels}*`}
                                    render={(props: RouteComponentProps<any>) => {
                                        //Rewrite any old release based routes from project/releases to project/deployments/releases
                                        const rewrite = props.match.path.replace(specificProjectLink.channels, specificProjectLink.deployments.channels);
                                        const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
                                        return <InternalRedirect to={destination} />;
                                    }}
                                />
                                <ReloadableRoute
                                    path={`${specificProjectLink.releases}*`}
                                    render={(props: RouteComponentProps<any>) => {
                                        //Rewrite any old release based routes from project/releases to project/deployments/releases
                                        const rewrite = props.match.path.replace(specificProjectLink.releases, specificProjectLink.deployments.releases);
                                        const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
                                        return <InternalRedirect to={destination} />;
                                    }}
                                />
                                <ReloadableRoute
                                    path={`${specificProjectLink.process.root}*`}
                                    render={(props: RouteComponentProps<any>) => {
                                        //Rewrite any old process links to the new route
                                        const rewrite = props.match.path.replace(specificProjectLink.process.root, specificProjectLink.deployments.process.root);
                                        const destination = `${generatePath(rewrite, props.match.params)}${props.location.search}`;
                                        return <InternalRedirect to={destination} />;
                                    }}
                                />
                                <RedirectAs404 />
                            </Switch>
                        </ProjectLayout>
                    </SlugSafeRedirect>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.projects.root} component={ProjectsPage} />
                <RedirectAs404 />
            </Switch>
        );
    }
}

export default ProjectRoutes;
