import * as React from "react";
import * as _ from "lodash";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import { ProjectRouteParams } from "../ProjectLayout";
import { PermissionCheck } from "components/PermissionCheck";
import { Permission, ProjectResource, TenantResource, RunbookResource, EnvironmentResource, RunbookProgressionResource, RunbooksDashboardItemResource, TenantedDeploymentMode } from "client/resources";
import ActionButton, { NavigationButton, NavigationButtonType, ActionButtonType } from "components/Button";
import RunbooksPaperLayout from "./Layouts/RunbooksPaperLayout";
import { repository } from "clientInstance";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { ResourcesById } from "client/repositories/basicRepository";
import RunbookOnboarding from "./RunbookOnboarding";
import ActionList from "components/ActionList";
import Callout, { CalloutType } from "components/Callout";
import classnames = require("classnames");
import InternalLink from "components/Navigation/InternalLink";
import Logo from "components/Logo";
import { NoResults } from "components/NoResults/NoResults";
import MarkdownDescription from "components/MarkdownDescription";
import { OverflowMenu, OverflowMenuItems } from "components/Menu";
import RunbookTaskStatusDetails from "./RunbookTaskStatusDetails/RunbookTaskStatusDetails";
import RunNowButton from "./RunNowButton";
import Section from "components/Section";
import Card from "components/Card/Card";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { WithRunbookContextInjectedProps, withRunbookContext } from "./RunbookContext";
import { isEqual } from "lodash";

const untenantedDeploymentLogo = require("./un-tenanted-deployment-logo.svg");
const styles = require("./RunbookOverviewLayout.less");

interface RunbookOverviewLayoutState extends DataBaseComponentState {
    project: ProjectResource;
    runbook: RunbookResource;
    progression: RunbookProgressionResource;
    tenants: TenantResource[];
    environmentsById: ResourcesById<EnvironmentResource>;
    hasSteps: boolean;
    failedChecks: Array<{ permission: Permission; isNotAllowed: boolean }>;
}

export interface RunbookOverviewLayoutRouteProps {
    runbookId: string;
}

type RunbookOverviewLayoutProps = RouteComponentProps<ProjectRouteParams & RunbookOverviewLayoutRouteProps> & WithProjectContextInjectedProps & WithRunbookContextInjectedProps;

class RunbookOverviewLayoutInternal extends DataBaseComponent<RunbookOverviewLayoutProps, RunbookOverviewLayoutState> {
    constructor(props: RunbookOverviewLayoutProps) {
        super(props);
        this.state = {
            hasSteps: false,
            project: null,
            runbook: null,
            progression: null,
            tenants: [],
            environmentsById: null,
            failedChecks: [],
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(nextProps: RunbookOverviewLayoutProps) {
        const currentRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const nextRunbook = nextProps.runbookContext.state && nextProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async reload() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        if (!project) {
            return;
        }

        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }

        await this.doBusyTask(async () => {
            const [tenants, environmentsById] = await Promise.all([repository.Tenants.all(), repository.Environments.allById()]);

            const requiredPermissions = [{ permission: Permission.ProjectView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId }, { permission: Permission.EnvironmentView, wildcard: true }];

            const failedChecks = requiredPermissions
                .map(check => ({
                    permission: check.permission,
                    isNotAllowed: !isAllowed(check),
                }))
                .filter(check => check.isNotAllowed);

            if (failedChecks.length > 0) {
                this.setState({
                    project,
                    runbook,
                    failedChecks,
                });
                return;
            }

            const [runbookProcess, progression] = await Promise.all([repository.RunbookProcess.get(runbook.RunbookProcessId), repository.Progression.getRunbookProgression(runbook)]);

            this.setState({
                project,
                runbook,
                progression,
                tenants,
                environmentsById,
                hasSteps: runbookProcess.Steps.length > 0,
            });
        });
    }

    renderDashboard() {
        if (!this.state.progression || Object.keys(this.state.progression.RunbookRuns).length === 0) {
            const runbook = this.state.runbook;
            return (
                <>
                    <div className={styles.emptyCell}>
                        There are no run for this Runbook yet.&nbsp;<InternalLink to={routeLinks.project(this.props.match.params.projectSlug).operations.runbook(runbook && runbook.Id).runNow.root}>Run Now</InternalLink>
                    </div>
                    <NoResults />
                </>
            );
        }

        const progression = this.state.progression;
        const environments = progression.Environments;
        const environmentKeys = Object.keys(progression.RunbookRuns);
        const rowsByTenant: Record<string, RunbooksDashboardItemResource[]> = {};
        environmentKeys.map(i => {
            const runbookRuns = progression.RunbookRuns[i];
            runbookRuns.forEach(r => {
                const untenantedRowKey = "";
                const rowKey = r.TenantId ? this.state.tenants.find(t => t.Id === r.TenantId).Name : untenantedRowKey;
                if (!rowsByTenant[rowKey]) {
                    rowsByTenant[rowKey] = [];
                }
                rowsByTenant[rowKey].push(r);
            });
        });
        const rowsBySortedTenantKeys = Object.keys(rowsByTenant).sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        const isTenanted = this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted;
        //const filterHintText = isTenanted ? "Filter by tenant or environment..." : "Filter by environment...";
        return (
            <div>
                {/* TODO: markse - look at filtering as part of phase 2. <Section>
                    <FilterSearchBox onChange={this.handleEnvironmentFilterUpdated} hintText={filterHintText} />
                </Section> */}
                <div className={styles.dashboardCards}>
                    {rowsBySortedTenantKeys.map((tenantName, tenantRowIndex) => {
                        const tenant = this.state.tenants.find(t => t.Name === tenantName);
                        const tenantId = tenant ? tenant.Id : null;
                        return (
                            <div className={styles.bodyRow} key={tenantRowIndex}>
                                {isTenanted && (
                                    <Section className={classnames(styles.tenantName, styles.groupHeader)} key={tenantRowIndex}>
                                        {tenant && this.tile(tenant.Links.Logo, tenant.Name, routeLinks.tenant(tenantId).root)}
                                        {!tenant && this.tile(untenantedDeploymentLogo, "Untenanted")}
                                    </Section>
                                )}
                                {environmentKeys.map((x, environmentRowIndex) => {
                                    const runbookRuns = progression.RunbookRuns[x];
                                    const environment = environments.find(e => e.Id === x);
                                    const thisRunbookRun = runbookRuns.find(o => o.TenantId === tenantId);

                                    if (!thisRunbookRun) {
                                        return null;
                                    } else {
                                        return (
                                            <Card
                                                key={environmentRowIndex}
                                                className={styles.cardContainer}
                                                leftAlign={true}
                                                logo={null}
                                                header={<div className={styles.runTaskEnvironment}>{environment.Name}</div>}
                                                content={
                                                    <div>
                                                        {" "}
                                                        <div className={styles.runTaskDetails}>
                                                            <RunbookTaskStatusDetails project={this.state.project} item={thisRunbookRun} />
                                                            <div className={styles.runActions}>
                                                                <OverflowMenu
                                                                    menuItems={[
                                                                        OverflowMenuItems.navItem(
                                                                            "Run this snapshot",
                                                                            routeLinks
                                                                                .project(this.props.match.params.projectSlug)
                                                                                .operations.runbook(this.props.match.params.runbookId)
                                                                                .runNow.runbookSnapshot(thisRunbookRun.RunbookSnapshotId),
                                                                            null,
                                                                            {
                                                                                permission: Permission.ReleaseCreate,
                                                                                wildcard: true,
                                                                            }
                                                                        ),
                                                                        OverflowMenuItems.navItem(
                                                                            "View this snapshot",
                                                                            routeLinks
                                                                                .project(this.props.match.params.projectSlug)
                                                                                .operations.runbook(this.props.match.params.runbookId)
                                                                                .runbookSnapshot(thisRunbookRun.RunbookSnapshotId).root,
                                                                            null,
                                                                            {
                                                                                permission: Permission.ReleaseView,
                                                                                wildcard: true,
                                                                            }
                                                                        ),
                                                                    ]}
                                                                    tabIndex={-1}
                                                                />
                                                            </div>
                                                        </div>
                                                        {thisRunbookRun.RunbookSnapshotNotes ? <div className={styles.runTaskNotes}>{thisRunbookRun.RunbookSnapshotNotes}</div> : null}
                                                    </div>
                                                }
                                            />
                                        );
                                    }
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    tile(logoUrl: string, name: string, toUrl?: string) {
        if (toUrl) {
            return (
                <InternalLink to={toUrl} className={styles.rowHeader}>
                    <span>
                        <Logo url={logoUrl} size="2.25rem" />
                    </span>
                    <span className={styles.tileName}>{name}</span>
                </InternalLink>
            );
        } else {
            return (
                <div className={styles.rowHeader}>
                    <span>
                        <Logo url={logoUrl} size="2.25rem" />
                    </span>
                    <span className={styles.tileName}>{name}</span>
                </div>
            );
        }
    }

    render() {
        const runbook = this.state.runbook;
        const breadcrumbTitle = "Runbooks";
        const breadcrumbPath = routeLinks.project(this.props.match.params.projectSlug).operations.runbooks;
        if (!this.state.project || !runbook) {
            return <RunbooksPaperLayout busy={true} errors={this.state.errors} />;
        }

        if (this.state.failedChecks.length > 0) {
            return (
                <RunbooksPaperLayout title={runbook.Name} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} busy={this.state.busy} errors={this.state.errors}>
                    <Callout type={CalloutType.Information} title={"Permission required"}>
                        The {this.state.failedChecks[0].permission} permission is required to view project overview details
                    </Callout>
                </RunbooksPaperLayout>
            );
        }
        const runbookDescription = this.state.runbook.Description && <MarkdownDescription markup={this.state.runbook.Description} />;
        if (this.state.project && !this.state.hasSteps) {
            const actions: JSX.Element[] = [
                <PermissionCheck permission={Permission.EnvironmentCreate} environment="*">
                    <NavigationButton
                        label="Define your Runbook Process"
                        href={
                            routeLinks
                                .project(this.state.project)
                                .operations.runbook(runbook.Id)
                                .runbookProcess.runbookProcess(runbook.RunbookProcessId).root
                        }
                        type={NavigationButtonType.Primary}
                    />
                </PermissionCheck>,
            ];
            const actionSection = <ActionList actions={actions} />;
            return (
                <RunbooksPaperLayout title={runbook.Name} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} busy={this.state.busy} errors={this.state.errors} sectionControl={actionSection} sidebar={runbookDescription}>
                    <RunbookOnboarding />
                </RunbooksPaperLayout>
            );
        }

        const sectionControlActions = [<ActionButton type={ActionButtonType.Secondary} label="Refresh" onClick={() => this.reload()} />, <RunNowButton isDisabled={!this.state.hasSteps} />];
        const sectionControl = <ActionList actions={sectionControlActions} />;
        return (
            <RunbooksPaperLayout title={runbook.Name} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} sectionControl={sectionControl} sidebar={runbookDescription} busy={this.state.busy} errors={this.state.errors}>
                {this.renderDashboard()}
            </RunbooksPaperLayout>
        );
    }
}

const RunbookOverviewLayoutWithContext = withRunbookContext(withProjectContext(RunbookOverviewLayoutInternal));
export default RunbookOverviewLayoutWithContext;
