import LinksCollection from "./linksCollection";

export interface IId {
    Id: string;
}

export interface IIdName {
    Id: string;
    Name: string;
}

export type ValueResource = {};

export class Resource implements IId {
    Id: string;
    Links: LinksCollection;
}

export default Resource;
