import BasicRepository from "./basicRepository";
import Client from "../client";
import { RunbookResource, DeploymentTemplateResource, DeploymentPromotionTarget, RunbookSnapshotTemplateResource, RunbookRunPreviewResource, ResourceCollection, RunbookSnapshotResource } from "client/resources";

type RunbookRepositoryListArgs = {
    skip?: number;
    take?: number;
    orderBy?: string;
};

class RunbookRepository extends BasicRepository<RunbookResource, RunbookResource, RunbookRepositoryListArgs> {
    constructor(client: Client) {
        super("Runbooks", client);
    }
    getRunbookSnapshotTemplate(runbook: RunbookResource): Promise<RunbookSnapshotTemplateResource> {
        return this.client.get<RunbookSnapshotTemplateResource>(runbook.Links["RunbookSnapshotTemplate"]);
    }
    getRunbookRunTemplate(runbook: RunbookResource): Promise<DeploymentTemplateResource> {
        return this.client.get<DeploymentTemplateResource>(runbook.Links["RunbookRunTemplate"]);
    }
    getRunbookRunPreview(promotionTarget: DeploymentPromotionTarget) {
        return this.client.get<RunbookRunPreviewResource>(promotionTarget.Links["Preview"], { includeDisabledSteps: true });
    }
    getRunbookSnapshots(runbook: RunbookResource, args?: { skip?: number; take?: number }): Promise<ResourceCollection<RunbookSnapshotResource>> {
        return this.client.get<ResourceCollection<RunbookSnapshotResource>>(runbook.Links["RunbookSnapshots"], args);
    }
}

export default RunbookRepository;
