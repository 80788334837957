import { DeploymentProcessResource, IProcessResource, PackageRequirement, RunCondition, StartTrigger, DeploymentStepResource, DeploymentActionResource } from "client/resources";
import { v4 } from "uuid";
import { ScriptingLanguage } from "components/scriptingLanguage";

type Resolver<T> = () => T | ResolverMap<T>;

type ResolverMap<T extends unknown> = {
    [P in keyof T]: Resolver<T[P]> | T[P] | ResolverMap<T[P]>;
};

type ResolverOrMap<T> = Resolver<T> | ResolverMap<T>;
export const maybeResolve = <T extends unknown>(resolver: ResolverOrMap<T>): T | ResolverMap<T> => {
    if (typeof resolver === "function") {
        return resolver();
    }

    return resolver;
};

export const resolveMap = <T extends unknown>(options: ResolverMap<T>): T => {
    const keys = Object.keys(options) as Array<keyof typeof options>;
    return keys.reduce((prev, current) => {
        const member = options[current];
        let resolved = maybeResolve(member);

        if (resolved && Array.isArray(resolved)) {
            resolved = resolved.map(resolveMap);
        } else if (resolved && typeof resolved === "object") {
            resolved = resolveMap(resolved) as any;
        }
        const res = { ...prev, [current]: resolved };
        return res;
    }, {}) as any;
};

export const incrementingId = (prefix: string) => {
    let id = 1;
    return () => `${prefix}${id++}`;
};

export const generateGuid = v4;
export const generateNullId = (): string => null;
export const generateDeploymentProcessId = (projectId: string) => () => `deployment-process-${projectId}`;
export const createResolver = <T extends unknown>() => (options: ResolverMap<T>) => resolveMap(options);
export const generateProcess = createResolver<IProcessResource>();
export const generateStep = createResolver<DeploymentStepResource>();
export const generateAction = createResolver<DeploymentActionResource>();
export const withIdGeneration = <T extends { Id: string }>(generator: () => string) => {
    return (options: ResolverMap<T>) => ({ ...options, Id: generator() });
};

export const getInlineScriptProperties = (type: ScriptingLanguage, body: string) => {
    return {
        "Octopus.Action.Script.ScriptSource": "Inline",
        "Octopus.Action.Script.ScriptBody": body,
        "Octopus.Action.Script.Syntax": type,
    };
};

export const generateBasicProcess = (id: string, generateProjectId: () => string, generateSteps: () => DeploymentStepResource[]) => {
    const projectId = generateProjectId();

    return generateProcess({
        Id: id,
        Links: {},
        ProjectId: projectId,
        Steps: generateSteps(),
    });
};

type BasicStepOptions = Pick<DeploymentStepResource, "Condition" | "PackageRequirement" | "StartTrigger" | "Properties">;
const getBasicStepOptions = (): BasicStepOptions => {
    return {
        Condition: RunCondition.Success,
        StartTrigger: StartTrigger.StartAfterPrevious,
        PackageRequirement: PackageRequirement.LetOctopusDecide,
        Properties: {
            "Octopus.Action.RunOnServer": "true",
        },
    };
};

type BasicActionOptions = Pick<
    DeploymentActionResource,
    "CanBeUsedForProjectVersioning" | "IsDisabled" | "IsRequired" | "Properties" | "ActionType" | "ExcludedEnvironments" | "Channels" | "Environments" | "Packages" | "Links" | "TenantTags" | "WorkerPoolId"
>;

export const getBasicActionOptions = (): BasicActionOptions => {
    return {
        CanBeUsedForProjectVersioning: true,
        IsDisabled: false,
        IsRequired: true,
        Properties: {},
        ActionType: "Octopus.Script",
        ExcludedEnvironments: [],
        Channels: [],
        Environments: [],
        Packages: [],
        Links: {},
        WorkerPoolId: generateGuid(),
        TenantTags: [],
    };
};

type GenerateStepOptions = Parameters<typeof generateStep>[0];
export const generateBasicStep = (name: string, configure: (options: GenerateStepOptions) => GenerateStepOptions = t => t) => {
    return generateStep(
        configure({
            ...getBasicStepOptions(),
            Id: null,
            Actions: [],
            Name: name,
        })
    );
};

type GenerateActionOptions = Parameters<typeof generateAction>[0];
export const generateBasicAction = (name: string, configure: (options: GenerateActionOptions) => GenerateActionOptions = t => t) => {
    return generateAction(
        configure({
            ...getBasicActionOptions(),
            Id: null,
            Name: name,
        })
    );
};

export const generateBasicParentStep = (id: string, name: string, children: string[], configureChild?: (options: GenerateActionOptions) => GenerateActionOptions) => {
    return generateBasicStep(name, options => ({ ...options, Id: id, Actions: children.map(x => generateBasicAction(x, configureChild)) }));
};

export const generateBasicScriptStep = (name: string, type: ScriptingLanguage, body: string) => {
    return generateBasicStep(name, options => ({
        ...options,
        Actions: [
            generateBasicAction(name, action => ({
                ...action,
                Properties: { ...action.Properties, ...getInlineScriptProperties(type, body) },
            })),
        ],
    }));
};
