import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonProjectRunbookOverview, CommonProjectRunbookTopics } from "../../CommonComponents/CommonProjectRunbooksViews";

export const ProjectRunbooksRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.settings,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookTopics />;
    },
};
