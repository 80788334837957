import ParseHelper from "utils/ParseHelper";
import * as React from "react";
import { Switch, RouteComponentProps, withRouter } from "react-router-dom";
import StepDetailsLoader from "../DeploymentProcess/StepDetailsLoader";
import ActionTemplateSelector from "areas/projects/components/ActionTemplateSelector/ActionTemplateSelector";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import { WithActionScopeInjectedProps } from "components/Actions/withActionScope";
import { ActionScope } from "components/Actions/pluginRegistry";
import RunbookProcessLayout from "./RunbookProcessLayout";
import { useRunbookContext } from "./RunbookContext";
import { RedirectAs404 } from "components/NotFound/NotFound";

export type WithProjectDeploymentProcessInjectedProps = {
    processId: string;
};

const withRunbookProcess = <T extends unknown>(Component: React.ComponentType<T>) => {
    type ExternalProps = Omit<T, keyof WithProjectDeploymentProcessInjectedProps>;
    const WithRunbookProcess: React.FC<ExternalProps> = props => {
        const context = useRunbookContext();
        return <Component runbookId={context.state.runbook && context.state.runbook.Id} processId={context.state.runbook && context.state.runbook.RunbookProcessId} {...(props as T)} />;
    };

    return WithRunbookProcess;
};

const specificProjectPage = pageIds.project();
export const CreateStepDetailsPage = withPage({ page: specificProjectPage.runbook.process })(withRunbookProcess(StepDetailsLoader));
export const StepDetailsPage = withPage({ page: specificProjectPage.runbook.process })(withRunbookProcess(StepDetailsLoader));
export const ActionTemplateSelectionPage = withPage({ page: specificProjectPage.runbook.process })(withRunbookProcess(ActionTemplateSelector));
export const RunbookProcessOverviewPage = withPage({ page: specificProjectPage.runbook.process })(RunbookProcessLayout);

type StepsRouteProps = {
    path: string;
    scope: ActionScope;
    processId: string;
} & WithActionScopeInjectedProps;

type Props = StepsRouteProps & RouteComponentProps<{ runbookId: string }>;

class RunbookProcessRoute extends React.Component<Props, any> {
    render() {
        return (
            <Switch>
                <ReloadableRoute path={`${this.props.path}/step/new/:actionType/:template?`} render={props => <CreateStepDetailsPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${this.props.path}/step/:stepId/new/:actionType/:template?`} render={props => <CreateStepDetailsPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${this.props.path}/step/:stepId/:reloadKey?`} render={props => <StepDetailsPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${this.props.path}/steptemplates`} render={props => <ActionTemplateSelectionPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${this.props.path}/childsteptemplates/:parentStepId`} render={props => <ActionTemplateSelectionPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${this.props.path}`} exact={true} render={props => <RunbookProcessOverviewPage {...props} />} />
                <RedirectAs404 />
            </Switch>
        );
    }
}

const EnhancedRunbookProcessRoute = withRouter(RunbookProcessRoute);

export default EnhancedRunbookProcessRoute;
