import * as React from "react";
import { IconButton } from "@material-ui/core";
import ExpandMoreIcon from "material-ui/svg-icons/navigation/expand-more";
const styles = require("./style.less");
import * as cn from "classnames";

interface CardTitleProps {
    title: JSX.Element;
    onClick?: (e: any) => void;
    showExpandableButton?: boolean;
    isExpanded?: boolean;
    forceMobileBehaviour?: boolean;
    useCardTitleContainerStyle?: boolean;
}

class CardTitle extends React.Component<CardTitleProps, any> {
    render() {
        return (
            <div
                onClick={this.props.onClick}
                className={cn(this.props.showExpandableButton ? styles.expandableFormTitleContainer : this.props.useCardTitleContainerStyle ? styles.cardTitleContainer : null, this.props.forceMobileBehaviour && styles.cardTitleContainerForceMobile)}
            >
                {this.props.title}
                {this.props.showExpandableButton && (
                    <IconButton className={cn(styles.expandIcon, this.props.isExpanded ? styles.expandOpen : styles.expandClosed)} onClick={this.props.onClick} aria-expanded={this.props.isExpanded} aria-label="expand">
                        <ExpandMoreIcon />
                    </IconButton>
                )}
            </div>
        );
    }
}

export default CardTitle;
