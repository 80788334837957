import * as React from "react";
import RaisedButton from "material-ui/RaisedButton";
import { white, success } from "theme/colors";
import { Permission, EnvironmentResource } from "client/resources";
import routeLinks from "../../../../routeLinks";
import { DeploymentCreateGoal } from "./ReleasesRoutes/releaseRouteLinks";
import { ResourcesById } from "client/repositories/basicRepository";
import { repository, session } from "../../../../clientInstance";
import TenantedDeploymentMode from "client/resources/tenantedDeploymentMode";
import FilterSearchBox from "components/FilterSearchBox";
const styles = require("./style.less");
import Divider from "components/Divider/Divider";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import { RaisedButtonProps } from "material-ui";
import DropdownButton, { DropdownButtonMenu, DropdownButtonMenuItem } from "components/Button/DropdownButton";
import { ActionButtonType } from "components/Button";

const deployButtonStyle: Partial<RaisedButtonProps> = {
    labelColor: white,
    backgroundColor: success,
    labelStyle: {
        fontSize: "0.8125rem",
        whiteSpace: "nowrap",
    },
};

interface DeployButtonProps {
    projectSlug: string;
    projectId: string;
    releaseVersion: string;
    nextDeployments: string[];
    environmentsById: ResourcesById<EnvironmentResource>;
    tenantedDeploymentMode: TenantedDeploymentMode;
}

interface DeployButtonState {
    open: boolean;
    filterText?: string;
}

export class DeployButton extends React.Component<DeployButtonProps, DeployButtonState> {
    constructor(props: DeployButtonProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    handleTouchTap: React.MouseEventHandler = event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            open: true,
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const deploymentLinks = routeLinks.project(this.props.projectSlug).release(this.props.releaseVersion).deployments;
        const createToLink = (environments?: string) => deploymentLinks.create(environments ? DeploymentCreateGoal.To : null, environments);
        const canDeployToMultipleEnvironments = this.props.tenantedDeploymentMode !== TenantedDeploymentMode.Tenanted;

        if (this.props.nextDeployments) {
            const nextDeploymentEnvironments = this.props.nextDeployments
                .filter(e => this.props.environmentsById.hasOwnProperty(e))
                .filter(e =>
                    session.currentPermissions.scopeToSpace(repository.spaceId).isAuthorized({
                        permission: Permission.DeploymentCreate,
                        environmentId: e,
                        projectId: this.props.projectId,
                        tenantId: "*",
                    })
                );
            if (nextDeploymentEnvironments.length > 1) {
                const filteredEnvironments = this.state.filterText
                    ? nextDeploymentEnvironments.filter(envId => {
                          return this.props.environmentsById[envId].Name.toLowerCase().indexOf(this.state.filterText) !== -1;
                      })
                    : nextDeploymentEnvironments;

                const button = (
                    <DropdownButton label={"Deploy to..."} open={this.state.open} type={ActionButtonType.Primary} onClose={this.handleRequestClose} onClick={this.handleTouchTap}>
                        {renderMenuProps => (
                            //We have to disable auto focusing behavior in order to get the filter search box to focus
                            <DropdownButtonMenu {...renderMenuProps.getMenuProps({ disableAutoFocus: true, disableAutoFocusItem: true, disableRestoreFocus: true, disableEnforceFocus: true })}>
                                {canDeployToMultipleEnvironments && nextDeploymentEnvironments.length === 2 && (
                                    <DropdownButtonMenuItem key="deploy-to-both" {...renderMenuProps.getMenuItemProps({ onClick: () => renderMenuProps.navigation.navigate(createToLink(nextDeploymentEnvironments.join(","))) })}>
                                        Deploy to both environments...
                                    </DropdownButtonMenuItem>
                                )}
                                {canDeployToMultipleEnvironments && nextDeploymentEnvironments.length > 2 && (
                                    <DropdownButtonMenuItem key="deploy-to-all" {...renderMenuProps.getMenuItemProps({ onClick: () => renderMenuProps.navigation.navigate(createToLink(nextDeploymentEnvironments.join(","))) })}>
                                        {`Deploy to all ${nextDeploymentEnvironments.length} environments...`}
                                    </DropdownButtonMenuItem>
                                )}
                                {canDeployToMultipleEnvironments && (
                                    <div key="filter">
                                        <Divider />
                                        <div className={styles.deployButtonFilterContainer}>
                                            <FilterSearchBox
                                                autoFocus={true}
                                                value={this.state.filterText}
                                                hintText="Filter..."
                                                onKeyDown={e => {
                                                    //There is special handling for keydown events in menu items so we have to stop this from propagating.
                                                    e.stopPropagation();
                                                }}
                                                onChange={(value: string) => {
                                                    this.setState({ filterText: value.toLowerCase() });
                                                }}
                                                fullWidth={true}
                                                containerClassName={styles.filterFieldContainer}
                                                iconColor={white}
                                                iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {filteredEnvironments.map((e: string) => {
                                    const environment = this.props.environmentsById[e];
                                    return (
                                        <DropdownButtonMenuItem key={environment.Name} {...renderMenuProps.getMenuItemProps({ onClick: () => renderMenuProps.navigation.navigate(createToLink(e)) })}>
                                            {environment.Name}
                                        </DropdownButtonMenuItem>
                                    );
                                })}
                                )}
                            </DropdownButtonMenu>
                        )}
                    </DropdownButton>
                );

                return <div>{button}</div>;
            }

            if (nextDeploymentEnvironments.length === 0) {
                return null;
            }

            return (
                <InternalLink to={createToLink(nextDeploymentEnvironments[0])}>
                    <RaisedButton {...deployButtonStyle} label={`Deploy to ${this.props.environmentsById[nextDeploymentEnvironments[0]] ? this.props.environmentsById[nextDeploymentEnvironments[0]].Name : ""}...`} />
                </InternalLink>
            );
        }

        return null;
    }
}
