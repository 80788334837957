import Resource from "./resource";
import DeploymentStepResource from "./deploymentStepResource";
import { typeSafeHasOwnProperty, RunbookProcessResource } from ".";

export interface IProcessResource extends Resource {
    Id: string;
    ProjectId: string;
    Steps: DeploymentStepResource[];
    LastSnapshotId?: string;
}

export interface DeploymentProcessResource extends IProcessResource {
    Version: number;
}

export function isDeploymentProcessResource(resource: NonNullable<IProcessResource>): resource is DeploymentProcessResource {
    if (resource === null || resource === undefined) {
        return false;
    }

    const converted = resource as DeploymentProcessResource;
    return !isRunbookProcessResource(resource) && converted.Version !== undefined && typeSafeHasOwnProperty(converted, "Version");
}

export function isRunbookProcessResource(resource: NonNullable<IProcessResource>): resource is RunbookProcessResource {
    if (resource === null || resource === undefined) {
        return false;
    }

    const converted = resource as RunbookProcessResource;
    return converted.RunbookId !== undefined && typeSafeHasOwnProperty(converted, "RunbookId");
}

export default DeploymentProcessResource;
