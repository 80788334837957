import * as React from "react";
import { RouteComponentProps } from "react-router";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { repository } from "clientInstance";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { Permission, TaskResource, RunbookRunResource, ProjectResource } from "client/resources";
import { NavigationButton, NavigationButtonType } from "components/Button/NavigationButton";
import { EnvironmentResource } from "client/resources/environmentResource";
import { ResourcesById } from "client/repositories/basicRepository";
import routeLinks from "routeLinks";
import PaperLayout from "components/PaperLayout/PaperLayout";
import Task from "areas/tasks/components/Task/Task";
import { ProjectRouteParams } from "../ProjectLayout";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { WithRunbookContextInjectedProps, withRunbookContext } from "./RunbookContext";
import { isEqual } from "lodash";

interface RunbookRunDetailsState extends DataBaseComponentState {
    project?: ProjectResource;
    runbookRun?: RunbookRunResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
}

type RunbookRunDetailsProps = RouteComponentProps<ProjectRouteParams & { runbookRunId: string }> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps;

class RunbookRunDetailsInternal extends DataBaseComponent<RunbookRunDetailsProps, RunbookRunDetailsState> {
    constructor(props: RunbookRunDetailsProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(nextProps: RunbookRunDetailsProps) {
        const currentRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const nextRunbook = nextProps.runbookContext.state && nextProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async reload() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        if (!project) {
            return;
        }

        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }

        await this.doBusyTask(async () => {
            const runbookRun = await repository.RunbookRuns.get(this.props.match.params.runbookRunId);
            this.setState({ runbookRun, project });
        });
    }

    renderAdditionalActions = (task: TaskResource<any>) => {
        const actions = [];

        if (task.IsCompleted && !task.FinishedSuccessfully) {
            const tryAgainLink = routeLinks
                .project(this.state.project)
                .operations.runbook(this.state.runbookRun.RunbookId)
                .runbookSnapshot(this.state.runbookRun.RunbookSnapshotId)
                .runbookRuns.retry(this.state.runbookRun.Id);
            actions.push(
                <PermissionCheck permission={Permission.DeploymentCreate} project={this.state.runbookRun.ProjectId} environment={this.state.runbookRun.EnvironmentId} tenant="*">
                    <NavigationButton label="Try again..." href={tryAgainLink} type={NavigationButtonType.Primary} />
                </PermissionCheck>
            );
        }

        return actions;
    };

    render() {
        const runbookRun = this.state.runbookRun;
        if (runbookRun) {
            return <Task taskId={runbookRun.TaskId} delayRender={() => false} projectId={this.state.project.Id} environmentId={runbookRun.EnvironmentId} tenantId={runbookRun.TenantId} additionalActions={this.renderAdditionalActions} />;
        }
        return <PaperLayout busy={this.state.busy} title="Task" errors={this.state.errors} />;
    }
}

export default withRunbookContext(withProjectContext(RunbookRunDetailsInternal));
