import Resource from "./resource";
import ActionProperties from "client/resources/actionProperties";
import ParseHelper from "../../utils/ParseHelper/ParseHelper";
import * as _ from "lodash";
import { PackageAcquisitionLocation } from "./packageAcquisitionLocation";
import { PackageReference } from "./packageReference";
import FeedResource, { FeedType } from "./feedResource";

export class DeploymentActionResource extends Resource {
    Id: string;
    Name: string;
    ActionType: string;
    WorkerPoolId: string;
    IsDisabled: boolean;
    CanBeUsedForProjectVersioning: boolean;
    Environments: string[];
    ExcludedEnvironments: string[];
    Channels: string[];
    TenantTags: string[];
    Packages: PackageReference[];
    Properties: ActionProperties;
    IsRequired: boolean;
}

export default DeploymentActionResource;

export function IsDeployReleaseAction(action: DeploymentActionResource) {
    return !!action.Properties["Octopus.Action.DeployRelease.ProjectId"];
}

export function HasManualInterventionResponsibleTeams(action: DeploymentActionResource) {
    return _.some(ParseHelper.parseCSV(action.Properties["Octopus.Action.Manual.ResponsibleTeamIds"] as string));
}

export function GetPrimaryPackageReference(packages: Array<PackageReference<any>>): PackageReference<any> {
    return packages.find(pkg => IsPrimaryPackageReference(pkg));
}

export function IsPrimaryPackageReference(pkg: PackageReference<any>): boolean {
    return !pkg.Name;
}

export function IsNamedPackageReference(pkg: PackageReference): boolean {
    return !!pkg.Name;
}

export function GetNamedPackageReferences(packages: Array<PackageReference<any>>): Array<PackageReference<any>> {
    return RemovePrimaryPackageReference(packages);
}

export function SetNamedPackageReference(name: string, updated: Partial<PackageReference<any>>, packages: Array<PackageReference<any>>): Array<PackageReference<any>> {
    return _.map(packages, pkg => {
        if (!PackageReferenceNamesMatch(name, pkg.Name)) {
            return pkg;
        }
        return { ...pkg, ...updated };
    });
}

export function SetPrimaryPackageReference(updated: Partial<PackageReference<any>>, packages: Array<PackageReference<any>>): Array<PackageReference<any>> {
    return _.map(packages, pkg => {
        if (!IsPrimaryPackageReference(pkg)) {
            return pkg;
        }
        return { ...pkg, ...updated };
    });
}

export function InitialisePrimaryPackageReference(packages: Array<PackageReference<any>>, feeds: FeedResource[]): Array<PackageReference<any>> {
    const primaryPackage = GetPrimaryPackageReference(packages);

    if (primaryPackage) {
        return [...packages];
    }

    const packagesWithoutDefault = RemovePrimaryPackageReference(packages);
    const builtInFeed: FeedResource | null = feeds.find(f => f.FeedType === FeedType.BuiltIn);
    const builtInFeedId: string | null = builtInFeed && builtInFeed.Id;

    return [
        {
            Id: null,
            PackageId: null,
            FeedId: builtInFeedId,
            AcquisitionLocation: PackageAcquisitionLocation.Server,
            Properties: {},
        },
        ...packagesWithoutDefault,
    ];
}

export function RemovePrimaryPackageReference(packages: Array<PackageReference<any>>): Array<PackageReference<any>> {
    return _.filter(packages, pkg => !IsPrimaryPackageReference(pkg));
}

// Returns true if the names match, where null and empty string are equivalent
export function PackageReferenceNamesMatch(nameA: string, nameB: string): boolean {
    if (!nameA) {
        return !nameB;
    }

    return nameA === nameB;
}
