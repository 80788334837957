import NamedResource from "./namedResource";
import { ExternalIdentityResource, ClaimsBasedIdentity } from "./identityResource";
import { RecentlyViewedProjectIds } from "./projectResource";

export class UserResource extends NamedResource {
    DisplayName: string;
    Username: string;
    IsActive: boolean;
    IsService: boolean;
    EmailAddress?: string;
    CanPasswordBeEdited?: boolean;
    IsRequestor?: boolean;
    Identities: ClaimsBasedIdentity[];
    RecentlyViewedProjectIds?: RecentlyViewedProjectIds;

    Password?: string;

    constructor() {
        super();
        this.Name = this.DisplayName;
    }
}

export default UserResource;
