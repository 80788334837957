import { Resource } from "./resource";
import { ReferenceDataItem } from "./referenceDataItem";
import { Form } from "./form";
import { ReleaseChanges } from "./deploymentResource";
import { MachineModelHealthStatus } from "./machineResource";
import { typeSafeHasOwnProperty } from ".";

export class MachineDeploymentPreview extends Resource {
    HasLatestCalamari: boolean;
    Name: string;
    HealthStatus?: MachineModelHealthStatus;
}

export class DeploymentTemplateStep {
    ActionId: string;
    ActionName: string;
    ActionNumber: string;
    Roles: string[];
    Machines: MachineDeploymentPreview[];
    CanBeSkipped: boolean;
    IsDisabled: boolean;
    HasNoApplicableMachines: boolean;
    UnavailableMachines: ReferenceDataItem[];
    ExcludedMachines: ReferenceDataItem[];
}

export class DeploymentPreviewBaseResource extends Resource {
    StepsToExecute: DeploymentTemplateStep[];
    Form: Form;
    UseGuidedFailureModeByDefault: boolean;
}

export class DeploymentPreviewResource extends DeploymentPreviewBaseResource {
    Changes: ReleaseChanges[];
    ChangesMarkdown: string;
}

export function isDeploymentPreviewResource(resource: NonNullable<DeploymentPreviewBaseResource>): resource is DeploymentPreviewResource {
    const converted = resource as DeploymentPreviewResource;
    return converted.Changes !== undefined && typeSafeHasOwnProperty(converted, "Changes");
}

export class RunbookRunPreviewResource extends DeploymentPreviewResource {}
