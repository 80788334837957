import * as React from "react";
import Select from "components/form/Select/Select";
import { DataBaseComponent, DataBaseComponentState, DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { RadioButton, StringRadioButtonGroup } from "components/form";
import { repository } from "clientInstance";
import { ProjectResource, RunbookResource, NamedResource, IId } from "client/resources";
import FormComponent from "components/FormComponent/FormComponent";
import { DialogLayout, DialogLayoutCommonProps, DialogLayoutDispatchProps } from "components/DialogLayout/DialogLayout";
import ActionButton, { ActionButtonType } from "components/Button";
import { DialogLayoutConnect } from "components/Dialog/DialogLayoutConnect";
import InfoDialogLayout from "components/DialogLayout/InfoDialogLayout";
import Callout, { CalloutType } from "components/Callout";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";
import { useDoBusyTaskEffect, Errors } from "components/DataBaseComponent";
import { keyBy, values, Dictionary, merge, compact, head } from "lodash";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import { connect } from "react-redux";
import configurationSelectors from "areas/configuration/reducers/selectors";

interface CloneStepProps {
    currentProject: ProjectResource;
    currentRunbook?: RunbookResource;
    actionName: string;
    stepId: string;
    actionId?: string;
    onCloneTargetSelected: (definition: CloneSourceDefinition) => Promise<void>;
}

interface CloneStepState extends DataBaseComponentState {
    selectedProject: ProjectResource;
    selectedRunbook?: RunbookResource;
    projects: { [id: string]: ProjectResource };
    selectedTarget: CloneStepTargetType;
    status: CloneStatus;
}

export enum CloneStepTargetType {
    CurrentContext = "CurrentContext",
    ThisProjectDeploymentProcess = "ThisProjectDeploymentProcess",
    AnotherProjectDeploymentProcess = "AnotherProjectDeploymentProcess",
    AnotherProjectRunbook = "AnotherProjectRunbook",
    ThisProjectRunbook = "ThisProjectRunbook",
}

enum StepsSourceType {
    Deployment = "Deployment",
    Runbook = "Runbook",
}

export interface CloneStepsSource {
    type: StepsSourceType;
    project: ProjectResource;
}

export interface CloneRunbookProcessSource extends CloneStepsSource {
    type: StepsSourceType.Runbook;
    runbook: RunbookResource;
}

export interface CloneDeploymentProcessStepsSource extends CloneStepsSource {
    type: StepsSourceType.Deployment;
}

enum CloneStatus {
    AskingForTarget = "AskingForTarget",
    Success = "Success",
}

export interface CloneSourceDefinition {
    source: CloneStepsSource;
    target: CloneStepsSource;
    targetType: CloneStepTargetType;
}

export function isRunbookProcessCloneSource(source: CloneStepsSource): source is CloneRunbookProcessSource {
    return source !== null && source !== undefined && source.type === StepsSourceType.Runbook;
}

export function isDeploymentsStepsCloneSource(source: CloneStepsSource): source is CloneDeploymentProcessStepsSource {
    return source !== null && source !== undefined && source.type === StepsSourceType.Deployment;
}

interface CloneStepDialogLayoutProps extends DialogLayoutCommonProps {
    onOkClick(): Promise<boolean>;
}

class CloneStepDialogLayoutInternal extends React.Component<CloneStepDialogLayoutProps & DialogLayoutDispatchProps> {
    okClick = async () => {
        const result = await this.props.onOkClick();
        if (result) {
            this.props.close();
        }
    };

    render() {
        const { children, ...other } = this.props;

        const ok = <ActionButton key="Ok" label="Ok" onClick={this.okClick} type={ActionButtonType.Primary} />;
        const cancel = <ActionButton key="Cancel" label="Cancel" onClick={() => this.props.close()} />;
        const actions = [cancel, ok];

        return (
            <DialogLayout actions={actions} closeDialog={this.props.close} {...other}>
                <FormComponent onFormSubmit={this.okClick}>{children}</FormComponent>
            </DialogLayout>
        );
    }
}

const toResourceLookup = <T extends IId>(items: T[]) => keyBy(items, x => x.Id);
const toSelectItem = <T extends NamedResource>(item: T) => ({ value: item.Id, text: item.Name });
const lookupToSelectItems = <T extends Dictionary<TItem>, TItem extends NamedResource>(lookup: T, filter?: (item: TItem) => boolean) => {
    return (filter ? values(lookup).filter(filter) : values(lookup)).map(toSelectItem);
};

function exhaustiveCheck(param: never) {
    return;
}

const CloneStepDialogLayout = DialogLayoutConnect.to<CloneStepDialogLayoutProps>(CloneStepDialogLayoutInternal);
CloneStepDialogLayout.displayName = "CloneDialogLayout";

const useFetchRunbooks = (project: ProjectResource, doBusyTask: DoBusyTask) => {
    const [runbooks, setRunbooks] = React.useState<Dictionary<RunbookResource>>({});
    const refreshRunbooks = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            if (project) {
                const result = await repository.Projects.getRunbooks(project, { take: repository.takeAll });
                setRunbooks(toResourceLookup(result.Items));
            } else {
                setRunbooks({});
            }
        },
        [project && project.Id]
    );

    return { runbooks, refreshRunbooks };
};

interface RunbookSelectorProps {
    project: ProjectResource;
    runbook?: RunbookResource;
    onChange: (runbook: RunbookResource) => void;
    error?: string;
    doBusyTask: DoBusyTask;
    filterItems?: (resource: RunbookResource) => boolean;
}

const RunbookSelector: React.FC<RunbookSelectorProps> = ({ error, onChange, project, runbook, doBusyTask, filterItems }) => {
    const { runbooks, refreshRunbooks } = useFetchRunbooks(project, doBusyTask);
    const handleChange = (id: string) => {
        onChange(runbooks[id]);
    };

    const items = lookupToSelectItems(runbooks, filterItems);
    if (!project) {
        return null;
    }
    return <Select label="Select Runbook" value={runbook && runbook.Id} error={error} items={items} onChange={handleChange} />;
};

interface GlobalConnectedProps {
    isRunbooksEnabled?: boolean;
}

type Props = CloneStepProps & GlobalConnectedProps;

class CloneStepInternal extends DataBaseComponent<Props, CloneStepState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedRunbook: this.props.currentRunbook,
            selectedProject: this.props.currentProject,
            projects: {},
            selectedTarget: CloneStepTargetType.CurrentContext,
            status: CloneStatus.AskingForTarget,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const projects = await repository.Projects.all();
            this.setState({ projects: toResourceLookup(projects) });
        });
    }

    renderTargetSelectionView() {
        const radioButtonOptions = this.props.isRunbooksEnabled
            ? [
                  <RadioButton value={CloneStepTargetType.CurrentContext} label={this.props.currentRunbook ? `This runbook` : `This project's deployment process`} isDefault={true} />,
                  this.props.currentRunbook && <RadioButton value={CloneStepTargetType.ThisProjectDeploymentProcess} label={"This project's deployment process"} />,
                  <RadioButton value={CloneStepTargetType.AnotherProjectDeploymentProcess} label="Another project's deployment process" />,
                  <RadioButton value={CloneStepTargetType.ThisProjectRunbook} label="Runbook in this project" />,
                  <RadioButton value={CloneStepTargetType.AnotherProjectRunbook} label="Runbook in another project" />,
              ]
            : [
                  <RadioButton value={CloneStepTargetType.CurrentContext} label={this.props.currentRunbook ? `This runbook` : `This project's deployment process`} isDefault={true} />,
                  this.props.currentRunbook && <RadioButton value={CloneStepTargetType.ThisProjectDeploymentProcess} label={"This project's deployment process"} />,
                  <RadioButton value={CloneStepTargetType.AnotherProjectDeploymentProcess} label="Another project's deployment process" />,
              ];
        return (
            <CloneStepDialogLayout title="Clone Step" busy={this.state.busy} errors={this.state.errors} onOkClick={this.onOk}>
                <p>
                    Clone the step <strong>{this.props.actionName}</strong> to:
                </p>

                <StringRadioButtonGroup value={this.state.selectedTarget} onChange={selection => this.changeSelectedTarget(selection as CloneStepTargetType)}>
                    {radioButtonOptions}
                </StringRadioButtonGroup>

                <p>
                    {(this.state.selectedTarget === CloneStepTargetType.AnotherProjectDeploymentProcess || this.state.selectedTarget === CloneStepTargetType.AnotherProjectRunbook) && (
                        <Select
                            label="Select project"
                            value={this.state.selectedProject && this.state.selectedProject.Id}
                            error={this.state.errors && this.state.errors.fieldErrors.selectedProjectId}
                            items={lookupToSelectItems(this.state.projects).filter(x => x.value !== (this.props.currentProject && this.props.currentProject.Id))}
                            onChange={this.onSelectProject}
                        />
                    )}
                    {(this.state.selectedTarget === CloneStepTargetType.AnotherProjectRunbook || this.state.selectedTarget === CloneStepTargetType.ThisProjectRunbook) && (
                        <RunbookSelector
                            doBusyTask={this.doBusyTask}
                            error={this.state.errors && this.state.errors.fieldErrors.selectedRunbookId}
                            project={this.state.selectedProject}
                            runbook={this.state.selectedRunbook}
                            onChange={this.onSelectRunbook}
                            filterItems={t => t.Id !== (this.props.currentRunbook && this.props.currentRunbook.Id)}
                        />
                    )}
                </p>
            </CloneStepDialogLayout>
        );
    }

    changeSelectedTarget = (selectedTarget: CloneStepTargetType) => {
        if (selectedTarget === CloneStepTargetType.CurrentContext) {
            this.setState({ selectedRunbook: this.props.currentRunbook, selectedProject: this.props.currentProject, selectedTarget });
        } else if (selectedTarget === CloneStepTargetType.ThisProjectRunbook) {
            this.setState({ selectedRunbook: null, selectedProject: this.props.currentProject, selectedTarget });
        } else {
            this.setState({ selectedRunbook: null, selectedProject: null, selectedTarget });
        }
    };

    onSelectProject = (id: string) => {
        this.setState({
            selectedProject: this.state.projects[id],
            errors: null,
        });
    };

    onSelectRunbook = (runbook: RunbookResource) => {
        this.setState({
            selectedRunbook: runbook,
            errors: null,
        });
    };

    getCloneDefinition = (): CloneSourceDefinition => {
        const baseDetails = { source: this.getCurrentContextSource(), targetType: this.state.selectedTarget };

        if (this.state.selectedTarget === CloneStepTargetType.CurrentContext) {
            return { ...baseDetails, target: baseDetails.source };
        } else if (this.state.selectedTarget === CloneStepTargetType.ThisProjectDeploymentProcess) {
            return { ...baseDetails, target: this.getDeploymentProcessSource(this.props.currentProject) };
        } else if (this.state.selectedTarget === CloneStepTargetType.ThisProjectRunbook) {
            return { ...baseDetails, target: this.getRunbookSource(this.props.currentProject, this.state.selectedRunbook) };
        } else if (this.state.selectedTarget === CloneStepTargetType.AnotherProjectRunbook) {
            return { ...baseDetails, target: this.getRunbookSource(this.state.selectedProject, this.state.selectedRunbook) };
        } else if (this.state.selectedTarget === CloneStepTargetType.AnotherProjectDeploymentProcess) {
            return { ...baseDetails, target: this.getDeploymentProcessSource(this.state.selectedProject) };
        }

        exhaustiveCheck(this.state.selectedTarget);
    };

    getDeploymentProcessSource = (project: ProjectResource): CloneDeploymentProcessStepsSource => {
        return { project, type: StepsSourceType.Deployment };
    };

    getRunbookSource = (project: ProjectResource, runbook: RunbookResource): CloneRunbookProcessSource => {
        return { project, runbook, type: StepsSourceType.Runbook };
    };

    getCurrentContextSource = (): CloneStepsSource => {
        if (this.props.currentRunbook) {
            return this.getRunbookSource(this.props.currentProject, this.props.currentRunbook);
        } else {
            return this.getDeploymentProcessSource(this.props.currentProject);
        }
    };

    renderSuccessView() {
        const source = this.getCloneDefinition().target;
        const runbook = isRunbookProcessCloneSource(source) ? source.runbook : null;

        return (
            <InfoDialogLayout title="Clone Successful" busy={this.state.busy} errors={this.state.errors}>
                {!runbook && <ClonedToDeploymentProcessSucces project={source.project} actionName={this.props.actionName} />}
                {runbook && <ClonedToRunbookSuccessMessage project={source.project} runbook={runbook} actionName={this.props.actionName} />}

                <Callout type={CalloutType.Warning} title="Variables">
                    No variables were copied - consider reviewing the cloned step and manually copy any required variables.
                </Callout>
            </InfoDialogLayout>
        );
    }

    render() {
        switch (this.state.status) {
            case CloneStatus.AskingForTarget:
                return this.renderTargetSelectionView();
            case CloneStatus.Success:
                return this.renderSuccessView();
        }
    }

    validateSelectedProject = (): Errors => {
        if (!this.state.selectedProject) {
            return {
                message: "Please select a project",
                fieldErrors: { selectedProjectId: "Select a project" },
                details: null,
            };
        }
    };

    validateSelectedRunbook = (): Errors => {
        if (!this.state.selectedRunbook) {
            return {
                message: "Please select a runbook",
                fieldErrors: { selectedRunbookId: "Select a runbook" },
                details: null,
            };
        }
    };

    mergeErrors = (errors: Errors[]): Errors => {
        const compacted = compact(errors);
        if (compacted.length === 0) {
            return null;
        }

        return compacted.reduce((prev, current) => {
            return { ...prev, fieldErrors: merge(prev.fieldErrors, current.fieldErrors) };
        }, head(compacted));
    };

    validate = () => {
        const errors: Errors[] = [];

        if (this.state.selectedTarget === CloneStepTargetType.AnotherProjectDeploymentProcess) {
            errors.push(this.validateSelectedProject());
        } else if (this.state.selectedTarget === CloneStepTargetType.ThisProjectRunbook) {
            errors.push(this.validateSelectedRunbook());
        } else if (this.state.selectedTarget === CloneStepTargetType.AnotherProjectRunbook) {
            errors.push(this.validateSelectedProject());
            errors.push(this.validateSelectedRunbook());
        }

        return this.mergeErrors(errors);
    };

    private onOk = async () => {
        const errors = this.validate();
        if (errors) {
            this.setState({ errors });
            return false;
        }

        const definition = this.getCloneDefinition();
        await this.props.onCloneTargetSelected(definition);
        if (this.state.selectedTarget === CloneStepTargetType.CurrentContext) {
            return true;
        } else if (this.state.selectedTarget) {
            this.setState({ status: CloneStatus.Success });
        }
    };
}

const ClonedToRunbookSuccessMessage: React.FC<{ project: ProjectResource; runbook: RunbookResource; actionName: string }> = ({ project, actionName, runbook }) => {
    return (
        <p>
            Step <strong>{actionName}</strong> has been successfully cloned to project {project.Name} runbook &nbsp;
            <InternalLink
                to={
                    routeLinks
                        .project(project.Slug)
                        .operations.runbook(runbook.Id)
                        .runbookProcess.runbookProcess(runbook.RunbookProcessId).steps.root
                }
            >
                {runbook.Name}
            </InternalLink>
        </p>
    );
};

const ClonedToDeploymentProcessSucces: React.FC<{ project: ProjectResource; actionName: string }> = ({ project, actionName }) => {
    return (
        <p>
            Step <strong>{actionName}</strong> has been successfully cloned to <InternalLink to={routeLinks.project(project.Slug).process.root}>{project.Name}</InternalLink>.
        </p>
    );
};

export { CloneStepTargetType as CloneStepTarget };

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isRunbooksEnabled: configurationSelectors.createFeatureEnabledSelector(t => t.isRunbooksEnabled)(state),
    };
};

export default connect(mapGlobalStateToProps)(CloneStepInternal);
