import * as React from "react";
import OnboardingPage from "components/GettingStarted/OnboardingPage";
import ExternalLink from "components/Navigation/ExternalLink";

export const EarlyAccessFeatureText = <span>Early access features are still in development and we encourage you to experiment with this.</span>;

export const RunbooksIntroText = "Runbooks can be used to automate routine or emergency operations-centric processes, for instance, disaster recovery and database backups.";

export const RunbooksWelcomeOnboarding = () => {
    return <OnboardingPage title="Welcome to Runbooks!" intro={RunbooksIntroText} learnMore={<ExternalLink href="OnboardingRunbooksLearnMore">Learn more</ExternalLink>} />;
};

const RunbooksOnboarding = () => {
    return <OnboardingPage title="Add your first Runbook to get started!" intro={RunbooksIntroText} learnMore={<ExternalLink href="OnboardingRunbooksLearnMore">Learn more</ExternalLink>} />;
};

export default RunbooksOnboarding;
