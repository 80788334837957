import Note from "../form/Note/Note";
import Chip from "../Chips/Chip";
import Tag from "../Tag";
import * as React from "react";
import { ActionButton, NavigationButton, CloseButton, ActionButtonType, NavigationButtonType } from "components/Button";
import { DeployButton } from "areas/projects/components/Releases/DeployButton";
import Text from "../form/Text/Text";
import { DatePicker, TimePicker, Select } from "components/form";
import NetworkFailure from "./NetworkFailure";
import Logo from "components/Logo";
import StyleGuideEntry from "./StyleGuideEntry";
import InternalLink from "components/Navigation/InternalLink";
import ExternalLink from "../Navigation/ExternalLink/ExternalLink";
import List, { SimpleList } from "components/List";
import Callout, { CalloutType } from "components/Callout/Callout";
import ToolTip from "../ToolTip/index";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import EmailLink from "../EmailLink/EmailLink";
import { ResourceCollection, ProjectResource, TenantedDeploymentMode, TenantResource, GuidedFailureMode } from "client/resources";
import { cloneDeep } from "lodash";
import ProjectCard from "areas/projects/components/ProjectCard/ProjectCard";
import TenantCard from "areas/tenants/TenantCard/TenantCard";
import AirlineIcon from "material-ui/svg-icons/notification/airline-seat-recline-extra";
import SortableList from "../SortableList/SortableList";
import { range } from "lodash";
import ActionList from "../ActionList/ActionList";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import { Section } from "components/Section/Section";
import PagingDataTable from "components/PagingDataTable";
import PagingDataGrid from "components/PagingDataGrid";
import { DataTable } from "components/DataTable/DataTable";
import { DataTableBody } from "components/DataTable/DataTableBody";
import { DataTableRow } from "components/DataTable/DataTableRow";
import { DataTableRowColumn } from "components/DataTable/DataTableRowColumn";
import { DataTableHeader } from "components/DataTable/DataTableHeader";
import { DataTableHeaderColumn } from "components/DataTable/DataTableHeaderColumn";
import { DataTableRowHeaderColumn } from "components/DataTable/DataTableRowHeaderColumn";
import DateFormatter from "utils/DateFormatter";
import LifecycleMap from "areas/library/components/Lifecycle/LifecycleMap/LifecycleMap";
import { RemoveItemsList } from "components/RemoveItemsList/RemoveItemsList";
import { ProjectChip, EnvironmentChip, TenantChip, RoleChip, ChannelChip, TeamChip, MachineChip, FilterTextChip, WorkerPoolChip } from "components/Chips";
import { LifecycleResource } from "client/resources/lifecycleResource";
import { repository, client } from "clientInstance";
import { ResourcesById } from "client/repositories/basicRepository";
import { EnvironmentResource } from "client/resources/environmentResource";
import { white, primaryText, secondaryText, primary, primaryDark, primaryLight, hover, divider, info, danger, success, alert, shadow40, shadow60 } from "theme/colors";
import TagsList from "components/TagsList/TagsList";
import { TagIndex } from "components/tenantTagsets";
import * as tenantTagsets from "components/tenantTagsets";
import DatePickerDialog, { DateRange } from "components/Dialog/DatePickerDialog";
import DateRangeIcon from "material-ui/svg-icons/action/date-range";
import * as moment from "moment";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import CodeEditor from "../CodeEditor/CodeEditor";
import Checkbox from "../form/Checkbox/Checkbox";
import { Card } from "@material-ui/core";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import MarkdownEditor from "components/form/MarkdownEditor/MarkdownEditor";
import ExampleDialogContent from "components/StyleGuide/ExampleDialogContent";
import Markdown from "components/Markdown/index";
import ExampleComponentThatShowsDialogs from "components/StyleGuide/ExampleComponentThatShowsDialogs";
import { default as GenericCard } from "components/Card/Card";
import { Errors } from "components/DataBaseComponent/Errors";
import ScrollTable from "components/ScrollTable/ScrollTable";
import FocusActionsLayout from "components/FocusActionsLayout/FocusActionsLayout";
import getTabEntries from "./TabsEntry";
const waitingForOctopus = require("resources/images/waiting-for-octopus.svg");
const styles = require("./style.less");
import ReadonlyText from "components/ReadonlyText/ReadonlyText";
import LateStateChanges from "components/StyleGuide/LateStateChanges";
import SidebarLayout, { SidebarSide } from "components/SidebarLayout/SidebarLayout";
import TaskDetails from "components/TaskDetails/TaskDetails";
import { TaskName, TaskResource } from "client/resources";
import { TaskState } from "client/resources/taskState";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import AdvancedFilterLayout, { AdvancedFilterCheckbox, AdvancedFilterTextInput } from "components/AdvancedFilterLayout";
import ListTitle from "components/ListTitle/ListTitle";
import SampleFormPage from "components/StyleGuide/SampleFormComponent";
import PageDivider from "components/PageDivider/PageDivider";
import { ScriptingLanguage } from "components/scriptingLanguage";
import ScriptingLanguageSelector from "components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import NavigationSidebarLayout, { Navigation } from "components/NavigationSidebarLayout/index";
import PageTitleHelper from "utils/PageTitleHelper";
import routeLinks from "../../routeLinks";
import IconButton, { Icon } from "components/IconButton/IconButton";
import IconButtonList from "components/IconButtonList/IconButtonList";
import OpenDialogIconButton from "components/Dialog/OpenDialogIconButton";
import InputWithActions from "components/InputWithActions/InputWithActions";
import AutoComplete from "components/AutoComplete/AutoComplete";
import DisplayProperties from "components/DisplayProperties";
import { IQueryStringFilter, defaultQueryStringFilter, renderQueryStringFiltersEntry } from "components/StyleGuide/QueryStringFiltersEntry";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import { normal, light, semibold, bold } from "../../fontWeights";
import InternalNavLink from "../Navigation/InternalNavLink/InternalNavLink";
import { DemoWizard } from "./DemoWizard";
import AreaTitle from "components/AreaTitle";
import PaperLayout from "components/PaperLayout";
import ScrollToTop from "components/ScrollToTop";
import ListItem from "components/ListItem";
import ActionTemplateCategory from "../ActionTemplates/ActionTemplateCategory";
import { RetentionUnit } from "client/resources";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";
import UrlQueryState, { QueryStateMode } from "components/UrlQueryState";
import getDialogsEntries from "./DialogEntries";
import { WorkerPoolType } from "../../client/resources/workerPoolsSupportedTypesResouce";
import { getMockEnvironmentsById } from "./Data/environments";

const textStyles = require("resources/styles/text.less");
const octoClippy = require("resources/images/octoclippy.svg");
const TestingMultiSelect = MultiSelect<SelectItem>();

interface Thing {
    Id: string;
    Name: string;
}

class ThingList extends List<Thing> {}

class SimpleThingList extends SimpleList<Thing> {}

class ThingTable extends SimpleDataTable<Thing> {}

class ThingDataTable extends PagingDataTable<Thing> {}

class ThingDataGrid extends PagingDataGrid<Thing> {}

class ThingRemoveItemsList extends RemoveItemsList<Thing> {}

interface StyleGuideState {
    busyIndicator?: Promise<any>;
    listOfThingsCollection: ResourceCollection<Thing>;
    otherListOfThingsCollection: ResourceCollection<Thing>;
    tableOfThingsCollection: ResourceCollection<Thing>;
    gridOfThingsCollection: ResourceCollection<Thing>;
    itemsPerPage: number;
    itemsToSort: Thing[];
    itemsToSelect: Thing[];
    formError?: string;
    errors?: Errors;
    certificateId?: string;
    accountId?: string;
    openDialogButtonLabel: string;
    lifecyclePreview?: LifecycleResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
    isDataLoaded: boolean;
    removableItems: Thing[];
    isFiltering: boolean;
    tagIndex?: TagIndex;
    tagNames?: string[];
    dateRange: DateRange;
    codeEditorAllowFullScreen: boolean;
    filter?: string;
    codeEditorLanguage: ScriptingLanguage;
    markdown: string;
    showConfirmationDialog?: boolean;
    showNoActionDialog?: boolean;
    focusActionsLayoutIsFocused: boolean;
    focusActionsLayoutText: string;
    readonlyTextValue: string;
    sampleSuccessfulTask: TaskResource<any>;
    sampleSuccessfulTaskWithWarnings: TaskResource<any>;
    sampleFailedTask: TaskResource<any>;
    sampleTimedOutTask: TaskResource<any>;
    sampleCancellingTask: TaskResource<any>;
    sampleCanceledTask: TaskResource<any>;
    sampleExecutingTask: TaskResource<any>;
    sampleWaitingForManualInterventionTask: TaskResource<any>;
    sampleQueuedTask: TaskResource<any>;
    personFilter: PersonFilter;
    emptyProgression: string[];
    singleEnvironmentProgression: string[];
    multipleEnvironmentProgression: string[];
    datepicker: Date;
    inputWithActions: string;
    autoCompleteValue: string;
    freeformAutoCompleteValue: string;
    autocompleteWithError: string;
    showNetworkFailure: boolean;
    multiselect_empty_values: string[];
    multiselect_existing_values: string[];
    multiselect_error_values: string[];
    multiselect_add_values: string[];
    multiselect_focus_values: string[];
    multiselect_description_values: string[];
    multiselect_itemrender_values: string[];
    relativeColumnWidths: ReadonlyArray<number>;
    queryStringFilter: IQueryStringFilter;
    filterKeyword: string;
    openDatePickerDialog: boolean;
    showDialogLongContent: boolean;
    customDialogFrameSize: CustomDialogFrameSize;
    selectedThingId: string;
}

enum CustomDialogFrameSize {
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
    FullScreen = "FullScreen",
}

enum WeekendActivity {
    Hiking = "Hiking",
    Dining = "Dining",
    Lounging = "Lounging",
    Camping = "Camping",
}

interface PersonFilter {
    name: string;
    age: string;
    favoriteTvShow: string;
    isRetired: boolean;
    likesOutdoors: boolean;
    favoriteWeekendActivites: WeekendActivity[];
}

class AdvancedPersonFilterLayout extends AdvancedFilterLayout<PersonFilter> {}

export default class StyleGuide extends BaseComponent<any, StyleGuideState> {
    constructor(props: any) {
        super(props);
        PageTitleHelper.setRootPageTitle();

        // Our API returns a ResourceCollection of "Things", so our List component expects a ResourceCollection.
        const things: Thing[] = [];
        const itemsPerPage = 5;
        for (let i = 1; i <= itemsPerPage; i++) {
            const thing: Thing = {
                Id: "Things-" + i,
                Name: "Name of thing #" + i,
            };
            things.push(thing);
        }
        const totalResults = 100; // Fake this for the purposes of this example.
        const numberOfPages = Math.max(1, Math.ceil(totalResults / itemsPerPage));
        const lastPageNumber = numberOfPages - 1;
        const thingsCollection: ResourceCollection<Thing> = {
            ItemType: "Thing",
            TotalResults: totalResults,
            ItemsPerPage: itemsPerPage,
            NumberOfPages: numberOfPages,
            LastPageNumber: lastPageNumber,
            Items: things,
            // The real-API will return a Links collection, which our List component will then use to calculate paging links.
            Links: {
                Self: "/api/Things",
                Template: "/api/Things{?skip,take}",
            },
        };
        const sampleSuccessfulTask: TaskResource<any> = {
            Id: "ServerTask-Sample1",
            Name: TaskName.AdHocScript,
            Description: "This task was successful",
            Arguments: null,
            State: TaskState.Success,
            Links: null,
            IsCompleted: true,
            HasPendingInterruptions: false,
            HasWarningsOrErrors: false,
            SpaceId: client.spaceId,
        };
        const sampleSuccessfulTaskWithWarnings: TaskResource<any> = {
            Id: "ServerTask-Sample1",
            Name: TaskName.AdHocScript,
            Description: "This task was successful, but had warnings",
            Arguments: null,
            State: TaskState.Success,
            Links: null,
            IsCompleted: true,
            HasPendingInterruptions: false,
            HasWarningsOrErrors: true,
            StartTime: "2018-10-19T03:29:30.614+00:00",
        };
        const sampleFailedTask: TaskResource<any> = {
            Id: "ServerTask-Sample2",
            Name: TaskName.AdHocScript,
            Description: "This task failed",
            Arguments: null,
            State: TaskState.Failed,
            Links: null,
            IsCompleted: true,
            HasPendingInterruptions: false,
            HasWarningsOrErrors: false,
            SpaceId: client.spaceId,
        };
        const sampleTimedOutTask: TaskResource<any> = {
            Id: "ServerTask-Sample3",
            Name: TaskName.AdHocScript,
            Description: "This task timed out",
            Arguments: null,
            State: TaskState.TimedOut,
            Links: null,
            IsCompleted: true,
            HasPendingInterruptions: false,
            HasWarningsOrErrors: false,
            StartTime: "2018-10-19T03:29:30.614+00:00",
        };
        const sampleCancellingTask: TaskResource<any> = {
            Id: "ServerTask-Sample3",
            Name: TaskName.AdHocScript,
            Description: "This task is cancelling (user hit cancel)",
            Arguments: null,
            State: TaskState.Cancelling,
            Links: null,
            IsCompleted: true,
            HasPendingInterruptions: false,
            HasWarningsOrErrors: false,
            StartTime: "2018-10-19T03:29:30.614+00:00",
        };
        const sampleCanceledTask: TaskResource<any> = {
            Id: "ServerTask-Sample3",
            Name: TaskName.AdHocScript,
            Description: "This task was canceled",
            ErrorMessage: "A misalignment in the sub-zenon quarks caused this task to be canceled.",
            Arguments: null,
            State: TaskState.Canceled,
            Links: null,
            IsCompleted: true,
            HasPendingInterruptions: false,
            HasWarningsOrErrors: false,
            StartTime: "2018-10-19T03:29:30.614+00:00",
        };
        const sampleExecutingTask: TaskResource<any> = {
            Id: "ServerTask-Sample3",
            Name: TaskName.AdHocScript,
            Description: "This task is executing",
            Arguments: null,
            State: TaskState.Executing,
            Links: null,
            IsCompleted: true,
            HasPendingInterruptions: false,
            HasWarningsOrErrors: false,
            StartTime: "2018-10-19T03:29:30.614+00:00",
        };
        const sampleQueuedTask: TaskResource<any> = {
            Id: "ServerTask-Sample3",
            Name: TaskName.AdHocScript,
            Description: "This task is queued",
            Arguments: null,
            State: TaskState.Queued,
            Links: null,
            IsCompleted: true,
            HasPendingInterruptions: false,
            HasWarningsOrErrors: false,
            StartTime: "2018-10-19T03:29:30.614+00:00",
        };
        const sampleWaitingForManualInterventionTask: TaskResource<any> = {
            Id: "ServerTask-Sample3",
            Name: TaskName.AdHocScript,
            Description: "This task has pending manual interventions",
            Arguments: null,
            State: TaskState.Queued,
            Links: null,
            IsCompleted: false,
            HasPendingInterruptions: true,
            HasWarningsOrErrors: false,
            StartTime: "2018-10-19T03:29:30.614+00:00",
        };
        const lifecyclePreview: LifecycleResource = {
            Id: "Lifecycles-1",
            Phases: [
                {
                    Id: "autophase-dev",
                    Name: "dev",
                    AutomaticDeploymentTargets: [],
                    OptionalDeploymentTargets: ["Environments-61"],
                    MinimumEnvironmentsBeforePromotion: 0,
                    IsOptionalPhase: false,
                    ReleaseRetentionPolicy: null,
                    TentacleRetentionPolicy: null,
                },
                {
                    Id: "autophase-prod",
                    Name: "prod",
                    AutomaticDeploymentTargets: [],
                    OptionalDeploymentTargets: ["Environments-101"],
                    MinimumEnvironmentsBeforePromotion: 0,
                    IsOptionalPhase: false,
                    ReleaseRetentionPolicy: null,
                    TentacleRetentionPolicy: null,
                },
            ],
            Name: "Dev and Test lifecycle",
            ReleaseRetentionPolicy: {
                Unit: RetentionUnit.Days,
                QuantityToKeep: 0,
                ShouldKeepForever: true,
            },
            TentacleRetentionPolicy: {
                Unit: RetentionUnit.Days,
                QuantityToKeep: 0,
                ShouldKeepForever: true,
            },
            Description: "",
            Links: {
                Self: "/api/Spaces-1/lifecycles/Lifecycles-1",
                Preview: "/api/Spaces-1/lifecycles/Lifecycles-1/preview",
                Projects: "/api/Spaces-1/lifecycles/Lifecycles-1/projects",
            },
        };
        this.state = {
            busyIndicator: null,
            listOfThingsCollection: thingsCollection,
            otherListOfThingsCollection: cloneDeep(thingsCollection),
            tableOfThingsCollection: cloneDeep(thingsCollection),
            gridOfThingsCollection: cloneDeep(thingsCollection),
            itemsPerPage: 5,
            itemsToSort: range(3).map(i => ({ Id: i.toString(), Name: `Name${i}` })),
            itemsToSelect: range(30).map(i => ({ Id: i.toString(), Name: `Name${i}` })),
            openDialogButtonLabel: "Open Example Save Form",
            isDataLoaded: false,
            removableItems: range(3).map(i => ({ Id: i.toString(), Name: `Name${i}` })),
            isFiltering: false,
            dateRange: { startDate: moment(new Date()), endDate: moment(new Date()) },
            codeEditorAllowFullScreen: false,
            codeEditorLanguage: ScriptingLanguage.PowerShell,
            markdown: "**Bold**\n" + "\n" + "_Italic_\n" + "\n" + "- Bullet list item 1\n" + "- Bullet list item 2\n" + "\n" + "1. Numbered list item 1\n" + "1. Numbered list item 2\n" + "\n" + "> Quote\n" + "\n" + "```Console.WriteLine```",
            focusActionsLayoutIsFocused: false,
            focusActionsLayoutText: "Some value",
            readonlyTextValue: "Some value",
            sampleSuccessfulTask,
            sampleSuccessfulTaskWithWarnings,
            sampleFailedTask,
            sampleCancellingTask,
            sampleCanceledTask,
            sampleExecutingTask,
            sampleWaitingForManualInterventionTask,
            sampleQueuedTask,
            sampleTimedOutTask,
            personFilter: this.defaultPersonFilter(),
            emptyProgression: [],
            singleEnvironmentProgression: ["Environments-1"],
            multipleEnvironmentProgression: ["Environments-1", "Environments-2"],
            datepicker: new Date(),
            inputWithActions: null,
            autoCompleteValue: "",
            freeformAutoCompleteValue: "",
            autocompleteWithError: "",
            showNetworkFailure: false,
            multiselect_empty_values: [],
            multiselect_existing_values: ["5a0d32aefcc4b90008b93548", "5a0d32ae1f2e66c9864b77b4"],
            multiselect_error_values: [],
            multiselect_add_values: [],
            multiselect_focus_values: [],
            multiselect_description_values: [],
            multiselect_itemrender_values: [],
            relativeColumnWidths: [1, 2, 1],
            queryStringFilter: defaultQueryStringFilter(),
            lifecyclePreview,
            filterKeyword: null,
            openDatePickerDialog: false,
            showDialogLongContent: true,
            customDialogFrameSize: CustomDialogFrameSize.Medium,
            selectedThingId: null,
        };
    }

    async componentDidMount() {
        const environmentsById = getMockEnvironmentsById();

        const tagIndex = await tenantTagsets.getTagIndex();
        const tagNames = Object.values(tagIndex).map(tag => tag.CanonicalTagName);

        this.setState({ environmentsById, tagIndex, tagNames, isDataLoaded: true });
    }

    render() {
        const entries = this.getEntries()
            .filter(x => (this.state.filterKeyword ? x.props.heading.toLowerCase().includes(this.state.filterKeyword.toLowerCase()) : x))
            .sort((entry1, entry2) => {
                if (entry1.props.heading < entry2.props.heading) {
                    return -1;
                }
                if (entry1.props.heading > entry2.props.heading) {
                    return 1;
                }
                return 0;
            })
            .map(entry => React.cloneElement(entry, { ...entry.props, key: entry.props.heading }));

        return (
            <div>
                <AreaTitle link={routeLinks.styleGuide} title="Style Guide" />
                <DrawerWrapperLayout>
                    <div className={styles.container}>
                        <ul className={styles.componentMenu}>
                            <UrlQueryState
                                mode={QueryStateMode.ReplaceHistory}
                                defaultValue={null}
                                onChange={val => this.setState({ filterKeyword: val })}
                                name="filterKeyword"
                                render={({ value, onChange }) => <FilterSearchBox hintText="Filter by name" value={value} onChange={onChange} autoFocus={true} fullWidth={true} />}
                            />
                            <br />
                            <br />
                            {entries.map((entry, i) => (
                                <li key={i}>
                                    <a href="#" onClick={e => this.navigateToEntry(e, entry.props.heading)}>
                                        {entry.props.heading}
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <PaperLayout title="Component Demos" fullWidth={true}>
                            <ul className={styles.components}>{entries}</ul>
                        </PaperLayout>
                        <ScrollToTop />
                    </div>
                </DrawerWrapperLayout>
            </div>
        );
    }

    private getEntries() {
        const someDateTimeOffset = "2017-05-21T13:37:00.000+00:00";
        const dateRangeTitle = DateFormatter.dateToCustomFormat(this.state.dateRange.startDate, "D MMMM YYYY") + " - " + DateFormatter.dateToCustomFormat(this.state.dateRange.endDate, "D MMMM YYYY");
        const selectItems =
            this.state.itemsToSelect &&
            this.state.itemsToSelect.map(e => {
                return { text: e.Name, value: e.Id };
            });
        const selectOnChange = (thing: string) => {
            this.setState({ selectedThingId: thing });
        };
        return [
            <StyleGuideEntry heading="Select" context="">
                <div>
                    <h4>Scenario - No labels or errors</h4>
                    <Select allowClear={true} items={selectItems} value={this.state.selectedThingId} onChange={selectOnChange} />
                    <h4>Scenario - Select with filter</h4>
                    <Select allowClear={true} allowFilter={true} items={selectItems} value={this.state.selectedThingId} onChange={selectOnChange} />
                    <h4>Scenario - No clear</h4>
                    <Select allowClear={false} items={selectItems} value={this.state.selectedThingId} onChange={selectOnChange} />
                    <h4>Scenario - Select with label</h4>
                    <Select allowClear={true} items={selectItems} value={this.state.selectedThingId} onChange={selectOnChange} label={"Select with label"} />
                    <h4>Scenario - Select with hint</h4>
                    <Select allowClear={true} items={selectItems} value={this.state.selectedThingId} onChange={selectOnChange} hintText={"Hint text here!!"} />
                    <h4>Scenario - Select with label and hint</h4>
                    <Select allowClear={true} items={selectItems} value={this.state.selectedThingId} onChange={selectOnChange} label={"Select with label"} hintText={"Hint text here!!"} />
                    <h4>Scenario - Select with error</h4>
                    <Select allowClear={true} items={selectItems} value={this.state.selectedThingId} onChange={selectOnChange} error="This has an error!" />
                </div>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Text" context="Font sizes are in rems">
                <form onSubmit={this.startBusyIndicator}>
                    <h1>H1</h1>
                    <h2>H2</h2>
                    <h3>H3</h3>
                    <h4>H4</h4>
                    <h5>H5</h5>
                    <h6>H6</h6>
                    <p>p</p>
                    <span className={textStyles.secondary}>text.secondary</span>
                    <br />
                    <Note>note</Note>
                    <br />
                    <a href="#">A text link</a>
                </form>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Font weights">
                <table>
                    <thead>
                        <tr>
                            <td>
                                React <br />
                                <small>import fontWeights.ts</small>
                            </td>
                            <td>
                                Less <br />
                                <small>import variables.less</small>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p style={{ fontWeight: light }}>light</p>
                            </td>
                            <td>
                                <p style={{ fontWeight: light }}>@light</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p style={{ fontWeight: normal }}>normal</p>
                            </td>
                            <td>
                                <p style={{ fontWeight: normal }}>@normal</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p style={{ fontWeight: semibold }}>semibold</p>
                            </td>
                            <td>
                                <p style={{ fontWeight: semibold }}>@semibold</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p style={{ fontWeight: bold }}>bold</p>
                            </td>
                            <td>
                                <p style={{ fontWeight: bold }}>@bold</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Colors" context="Use only colors specified here.">
                <table>
                    <thead>
                        <tr>
                            <td />
                            <td>less</td>
                            <td>js</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: white }} />
                            </td>
                            <td>@white</td>
                            <td>white</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: primaryText }} />
                            </td>
                            <td>@primaryText</td>
                            <td>primaryText</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: secondaryText }} />
                            </td>
                            <td>@secondaryText</td>
                            <td>secondaryText</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: primary }} />
                            </td>
                            <td>@primary</td>
                            <td>primary</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: primaryLight }} />
                            </td>
                            <td>@primaryLight</td>
                            <td>primaryLight</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: primaryDark }} />
                            </td>
                            <td>@primaryDark</td>
                            <td>primaryDark</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: hover }} />
                            </td>
                            <td>@hover</td>
                            <td>hover</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: divider }} />
                            </td>
                            <td>@divider</td>
                            <td>divider</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: info }} />
                            </td>
                            <td>@info</td>
                            <td>info</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: success }} />
                            </td>
                            <td>@success</td>
                            <td>success</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: danger }} />
                            </td>
                            <td>@danger</td>
                            <td>danger</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: alert }} />
                            </td>
                            <td>@alert</td>
                            <td>alert</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: shadow40 }} />
                            </td>
                            <td>@shadow40</td>
                            <td>shadow40</td>
                        </tr>
                        <tr>
                            <td>
                                <div className={styles.swatch} style={{ background: shadow60 }} />
                            </td>
                            <td>@shadow60</td>
                            <td>shadow60</td>
                        </tr>
                    </tbody>
                </table>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Dates" context="Use 'DateFormatter' class">
                <div>
                    <p>
                        In short format: <strong>{DateFormatter.dateToShortFormat(someDateTimeOffset)}</strong>
                    </p>
                    <p>
                        In long format: <strong>{DateFormatter.dateToLongFormat(someDateTimeOffset)}</strong>
                    </p>
                    <p>
                        In custom format:
                        <strong>{DateFormatter.dateToCustomFormat(someDateTimeOffset, "MMMM Do YYYY")}</strong>
                    </p>
                    <p>
                        Moments ago: <strong>{DateFormatter.momentAgo(someDateTimeOffset)}</strong>
                    </p>
                </div>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Date/Time Picker">
                <div>
                    <label>Date picker:</label>
                    <DatePicker value={this.state.datepicker} onChange={value => this.setState({ datepicker: value })} />
                </div>
                <div>
                    <label>Time picker:</label>
                    <TimePicker value={this.state.datepicker} onChange={value => this.setState({ datepicker: value })} />
                    {this.state.datepicker.toLocaleTimeString()}
                </div>
                <div>
                    <label>Date range picker:</label>
                    <OpenDialogButton
                        label={dateRangeTitle}
                        icon={<DateRangeIcon />}
                        wideDialog={true}
                        renderDialog={({ closeDialog, open }) => {
                            return (
                                <DatePickerDialog
                                    key={open.toString()}
                                    dialogOpen={open}
                                    startDate={this.state.dateRange.startDate}
                                    endDate={this.state.dateRange.endDate}
                                    onSaveClick={async (dateRange: any) => {
                                        this.setState({ dateRange });
                                        return Promise.resolve(null);
                                    }}
                                    onCancelClick={closeDialog}
                                />
                            );
                        }}
                    />
                </div>
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="Buttons - Action Buttons"
                context={
                    <div>
                        Action and Navigation buttons look the same but have different purposes. Navigation buttons will navigate a user to a configuration page whereas Action buttons will trigger an action eg open a dialog or Save.
                        <ul>
                            <li>
                                <b>Primary action button</b> is used for the main action a user will do on that page. There should only be one primary action per page. eg. Adding something via a dialog, save and sign in/out
                                <Note>If an ADD button navigates a user to a configuration page, rather than a dialog, the button should be a Navigation Button</Note>
                            </li>
                            <li>
                                <b>Secondary action button</b> represents actions a user may need to do sometimes they visit a page. There should only be 1-2 secondary actions per page. eg. Changing settings or cancel{" "}
                            </li>
                            <li>
                                <b>Ternary action button</b> is used for inline actions that don't need to visually stand out as there may end up being a number of them on a page. eg. Show/Hide,{" "}
                            </li>
                        </ul>
                    </div>
                }
            >
                <form onSubmit={this.startBusyIndicator}>
                    <table className={styles.buttons}>
                        <thead>
                            <tr>
                                <td>Active State</td>
                                <td>Disabled State</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {" "}
                                    <ActionButton type={ActionButtonType.Primary} label="Add Primary Action" icon={<AirlineIcon key="primary" />} />
                                </td>
                                <td>
                                    {" "}
                                    <ActionButton type={ActionButtonType.Primary} label="Add Primary Action" icon={<AirlineIcon key="primary" />} disabled={true} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ActionButton label="Add Secondary Action" icon={<AirlineIcon key="secondary" />} />
                                </td>
                                <td>
                                    <ActionButton label="Add Secondary Action" icon={<AirlineIcon key="secondary" />} disabled={true} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {" "}
                                    <ActionButton type={ActionButtonType.Ternary} label="Add Ternary Action" />
                                </td>
                                <td>
                                    {" "}
                                    <ActionButton type={ActionButtonType.Ternary} label="Add Ternary Action" disabled={true} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ActionButton type={ActionButtonType.Save} label="Save" busyLabel={"Saving..."} disabled={this.state.busyIndicator} />
                                </td>
                                <td>
                                    <ActionButton type={ActionButtonType.Save} label="Save" disabled={true} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ActionButton type={ActionButtonType.Delete} label="Delete" busyLabel={"Deleting..."} disabled={this.state.busyIndicator} />
                                </td>
                                <td>
                                    <ActionButton type={ActionButtonType.Delete} label="Delete" disabled={true} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {" "}
                                    Close <CloseButton />
                                </td>
                                <td />
                            </tr>
                            <tr>
                                <td>
                                    <OpenDialogButton label={this.state.openDialogButtonLabel}>
                                        <ExampleDialogContent saveDone={openDialogButtonLabel => this.setState({ openDialogButtonLabel })} />
                                    </OpenDialogButton>
                                </td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </form>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Buttons - Action List" context="Use it when you need display multiple links and/or buttons as a group. The primary button should always be on the right.">
                <ActionList
                    actions={[<ActionButton label="Secondary" busyLabel="Doing..." disabled={this.state.busyIndicator} />, <ActionButton type={ActionButtonType.Primary} label="Primary" busyLabel="Doing..." disabled={this.state.busyIndicator} />]}
                />
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="Buttons - Navigation Buttons"
                context={
                    <div>
                        Navigation and Action buttons look the same but have different purposes. Navigation buttons will navigate a user to a configuration page whereas Action buttons will trigger an action eg open a dialog or Save.
                        <ul>
                            <li>
                                <b>Primary navigation button</b> is used for the main task a user will do on that page. There should only be one primary task per page. eg. Adding something via a configuration page
                                <Note>If an ADD button opens a dialog, rather than taking the user to a configuration page, the button should be an Action Button</Note>
                            </li>
                            <li>
                                <b>Secondary navigation button</b> represents tasks a user may need to do sometimes they visit a page. There should only be 1-2 secondary actions per page. eg. Adding deployment target when on the environments
                                page&nbsp;
                            </li>
                            <li>
                                <b>Ternary navigation button</b> is used for inline tasks that don't need to visually stand out as there may end up being a number of them on a page. eg. Run a step,{" "}
                            </li>
                        </ul>
                    </div>
                }
            >
                <table className={styles.buttons}>
                    <thead>
                        <tr>
                            <td>Active state</td>
                            <td>Disabled state</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <NavigationButton type={NavigationButtonType.Primary} label="Add primary navigation" href={routeLinks.styleGuide} />
                            </td>
                            <td>
                                <NavigationButton type={NavigationButtonType.Primary} label="Add primary navigation" href="#" disabled={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <NavigationButton label="Add secondary navigation" href={routeLinks.styleGuide} />
                            </td>
                            <td>
                                <NavigationButton label="Add secondary navigation" href="" disabled={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <NavigationButton type={NavigationButtonType.Ternary} label="Add ternary navigation" href={routeLinks.styleGuide} />
                            </td>
                            <td>
                                <NavigationButton type={NavigationButtonType.Ternary} label="Add ternary navigation" href="" disabled={true} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Buttons - Deploy Buttons">
                {this.state.isDataLoaded && (
                    <table>
                        <tbody>
                            <tr>
                                <td>Empty Progression</td>
                                <td>
                                    <DeployButton
                                        releaseVersion="1.0.0"
                                        projectSlug="Sluggy"
                                        projectId={"*"}
                                        nextDeployments={this.state.emptyProgression}
                                        environmentsById={this.state.environmentsById}
                                        tenantedDeploymentMode={TenantedDeploymentMode.Untenanted}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Single Environment</td>
                                <td>
                                    <DeployButton
                                        releaseVersion="1.0.0"
                                        projectSlug="Sluggy"
                                        projectId={"*"}
                                        nextDeployments={this.state.singleEnvironmentProgression}
                                        environmentsById={this.state.environmentsById}
                                        tenantedDeploymentMode={TenantedDeploymentMode.Untenanted}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Multiple Environments</td>
                                <td>
                                    <DeployButton
                                        releaseVersion="1.0.0"
                                        projectSlug="Sluggy"
                                        projectId={"*"}
                                        nextDeployments={this.state.multipleEnvironmentProgression}
                                        environmentsById={this.state.environmentsById}
                                        tenantedDeploymentMode={TenantedDeploymentMode.Untenanted}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="Buttons - Icon Button and Icon Button List"
                context={
                    <div>
                        <ul>
                            <li>
                                <b>Icon buttons</b> represents a contextual action that is placed next to the control it operates on.
                            </li>
                            <li>
                                <b>Icon button lists</b> are used to display a list of icon buttons that are nicely laid out.
                            </li>
                        </ul>
                    </div>
                }
            >
                <IconButtonList
                    buttons={[
                        <IconButton icon={Icon.UseDefaultImage} toolTipContent="Material UI icon" onClick={() => this.showAlert("Clicked Material UI icon")} />,
                        <IconButton icon={octoClippy} toolTipContent="Custom SVG icon" onClick={() => this.showAlert("Clicked Custom SVG icon")} />,
                        <IconButton icon={Icon.EnterFullScreen} toolTipContent="Font awesome icon" onClick={() => this.showAlert("Clicked Font awesome icon")} />,
                    ]}
                />
            </StyleGuideEntry>,

            <StyleGuideEntry heading="InputWithActions" context={"Represents a simple input and a set of actions that operate on it. " + "Input and actions should be displayed inline on all screens except mobile phones."}>
                <InputWithActions
                    input={<Text hintText={"Type something"} value={this.state.inputWithActions} onChange={inputWithActions => this.setState({ inputWithActions })} />}
                    actions={
                        <IconButtonList
                            buttons={[
                                <IconButton toolTipContent="Refresh" icon={Icon.Refresh} onClick={() => this.setState({ inputWithActions: "Refresh" })} />,
                                <IconButton toolTipContent="Add" icon={Icon.Add} onClick={() => this.setState({ inputWithActions: "Add" })} />,
                            ]}
                        />
                    }
                />
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Links" context={"Use InternalLink for all links unless you want the link to have custom styling " + "when it matches the current url, then use InternalNavLink."}>
                <table>
                    <tbody>
                        <tr>
                            <td>Internal Link</td>
                            <td>
                                <InternalLink to={routeLinks.projects.root}>Project list</InternalLink>
                            </td>
                        </tr>
                        <tr>
                            <td>Internal Nav Link</td>
                            <td>
                                <InternalNavLink to={routeLinks.styleGuide}>Style Guide</InternalNavLink>
                            </td>
                        </tr>
                        <tr>
                            <td>External</td>
                            <td>
                                <ExternalLink href="LifecyclesNoProgression">Lifecycles</ExternalLink>
                            </td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>
                                <EmailLink emailAddress="test@example.com" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Action Template Categories" context={"some context"}>
                <div className={styles.row}>
                    <ul>
                        <ActionTemplateCategory category={null} name={"Selected category tile"} active={true} templates={null} onCategorySelected={null} />
                        <ActionTemplateCategory category={null} name={"Category name"} active={false} templates={null} onCategorySelected={null} />
                    </ul>
                </div>
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="Cards"
                context={
                    <span>
                        Cards are used for when a user is browsing to select an item to add eg. step templates and projects. Use generic **Card** component to build a specialized card for your use case. Cards do not appear on the paper element as it
                        is a smaller version of the paper element. It is an entry point for more detail and contains various elements - content, icon, status, count, and links.
                    </span>
                }
            >
                <div className={styles.row}>
                    <ProjectCard
                        project={
                            ({
                                Id: "Projects-41",
                                Name: "Fake Project",
                                Description: "**Simple** _description_",
                                Links: {
                                    Logo: "/api/projects/Projects-1/logo",
                                },
                            } as any) as ProjectResource
                        }
                    />
                    <TenantCard
                        tenant={
                            ({
                                Id: "Tenant-1",
                                Name: "Micro Corp",
                                Description: "**Simple** _description_",
                                Links: {
                                    Logo: "/api/projects/Projects-1/logo",
                                },
                            } as any) as TenantResource
                        }
                        hasMissingVariables={false}
                        tagIndex={null}
                    />
                    <GenericCard
                        logo={<Logo url="/api/projects/Projects-1/logo" />}
                        header={"Generic Card"}
                        content={<p>Anything can go to content section. Hover over me to see actions.</p>}
                        footerBackgroundColor={divider}
                        footerIcon={<em className="fontoctopus-octopus" />}
                        footerText="Footer text"
                        onHoverView={<ActionButton type={ActionButtonType.Primary} label="Primary Action" />}
                    />
                </div>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Data Table" context="A table with a fixed number of rows and columns">
                <Section sectionHeader="with Column Headers">
                    <DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Name</DataTableHeaderColumn>
                                <DataTableHeaderColumn>Value</DataTableHeaderColumn>
                                <DataTableHeaderColumn>Icon</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            <DataTableRow>
                                <DataTableRowColumn>Foo</DataTableRowColumn>
                                <DataTableRowColumn>
                                    <b>126</b>
                                </DataTableRowColumn>
                                <DataTableRowColumn>
                                    <AirlineIcon />
                                </DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowColumn>Bar</DataTableRowColumn>
                                <DataTableRowColumn>
                                    <b>234546</b>
                                </DataTableRowColumn>
                                <DataTableRowColumn>
                                    <AirlineIcon />
                                </DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowColumn>Buzz</DataTableRowColumn>
                                <DataTableRowColumn>
                                    <b>126</b>
                                </DataTableRowColumn>
                                <DataTableRowColumn>
                                    <AirlineIcon />
                                </DataTableRowColumn>
                            </DataTableRow>
                        </DataTableBody>
                    </DataTable>
                </Section>
                <Section sectionHeader="with Row Headers">
                    <DataTable>
                        <DataTableBody>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Name</DataTableRowHeaderColumn>
                                <DataTableRowColumn>The Doctor</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Age</DataTableRowHeaderColumn>
                                <DataTableRowColumn>2000 ish</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Location</DataTableRowHeaderColumn>
                                <DataTableRowColumn>Earth</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Birth Place</DataTableRowHeaderColumn>
                                <DataTableRowColumn>Gallifrey</DataTableRowColumn>
                            </DataTableRow>
                        </DataTableBody>
                    </DataTable>
                </Section>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Simple table" context="A table where you have all the data and no paging is required">
                <ThingTable data={this.state.listOfThingsCollection.Items} headerColumns={["Id", "Name"]} onRow={item => [item.Id, <b>{item.Name}</b>]} />
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Simple list" context={`No loading`}>
                <SimpleThingList items={this.state.listOfThingsCollection.Items} onRow={thing => <ListTitle>{thing.Name}</ListTitle>} onFilter={this.filterThings} filterHintText="Filter by name..." />
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="List with 'Load More' style paging (default)"
                context={`Represents items that can be displayed all at once and then filtered inline.
                    It can easily display hundreds of items. "Load more" is a safety net and is ONLY shown when
                    the number of items is larger than expected. The overflow menu on lists should be used sparingly -
                    it is not intended to duplicate all the menu items from the "edit" page. A good rule of thumb is to
                    only have Enable/Disable.`}
            >
                <ThingList
                    initialData={this.state.listOfThingsCollection}
                    onRow={(thing: any) => (
                        <ListItem overflowMenuItems={[OverflowMenuItems.item("Enable", () => null)]}>
                            <ListTitle>{thing.Name}</ListTitle>
                        </ListItem>
                    )}
                    onFilter={this.filterThings}
                    filterHintText="Filter by name..."
                    onLoadMore={async () => {
                        /*
                         In this example, we are passing a custom 'onPageSelected' callback because we're not hitting a real
                         Octopus API, but you typically don't need this, as our List component will calculate this for you.
                         */
                        await this.sleep1s();
                        const newCollection = this.state.listOfThingsCollection;
                        const itemsLength = this.state.listOfThingsCollection.Items.length + 1;
                        for (let i = itemsLength; i < itemsLength + this.state.itemsPerPage; i++) {
                            const thing: Thing = {
                                Id: "Things-" + i,
                                Name: "Name of thing #" + i,
                            };
                            newCollection.Items.push(thing);
                        }
                        this.setState({
                            listOfThingsCollection: newCollection,
                        });
                    }}
                />
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="List with 'Numbered' style paging"
                context={`This should be used sparingly, but sometimes it is necessary to show paging numbers
                    instead of the default "Load More" style of paging. E.g. See a machine policy screen and the
                    list of machines that are using that policy.`}
            >
                <ThingList
                    initialData={this.state.otherListOfThingsCollection}
                    onRow={(thing: any) => <ListTitle>{thing.Name}</ListTitle>}
                    onFilter={this.filterThings}
                    filterHintText="Filter by name..."
                    showPagingInNumberedStyle={true}
                    onPageSelected={async (skip: any, p: any) => {
                        /*
                        In this example, we are passing a custom 'onPageSelected' callback because we're not hitting a real
                        Octopus API, but you typically don't need this, as our List component will calculate this for you.
                        */
                        await this.sleep1s();
                        const items: Thing[] = [];
                        for (let i = skip; i < skip + this.state.itemsPerPage; i++) {
                            const thing: Thing = {
                                Id: "Things-" + i,
                                Name: "Name of thing #" + i,
                            };
                            items.push(thing);
                        }
                        const newCollection = cloneDeep(this.state.otherListOfThingsCollection);
                        newCollection.Items = items;
                        this.setState({
                            otherListOfThingsCollection: cloneDeep(newCollection),
                        });
                        //TODO: mark.siedle - figure out why this custom onPageSelected callback isn't updated the list's data.
                    }}
                />
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="Data Table with 'Load More' or numbered style paging"
                context={`Represents items that can be displayed all at once and then filtered inline.
                    It can easily display hundreds of items. "Load more" is a safety net and is ONLY shown when
                    the number of items is larger than expected. Also has a flag for numbered paging, just like List.`}
            >
                <ThingDataTable
                    initialData={this.state.tableOfThingsCollection}
                    onRow={(thing: any) => [thing.Id, thing.Name]}
                    headerColumns={["Id", "Name"]}
                    onFilter={this.filterThings}
                    filterHintText="Filter by name..."
                    onLoadMore={async () => {
                        /*
                        In this example, we are passing a custom 'onPageSelected' callback because we're not hitting a real
                        Octopus API, but you typically don't need this, as our List component will calculate this for you.
                        */
                        await this.sleep1s();
                        const newCollection = this.state.tableOfThingsCollection;
                        const itemsLength = this.state.tableOfThingsCollection.Items.length + 1;
                        for (let i = itemsLength; i < itemsLength + this.state.itemsPerPage; i++) {
                            const thing: Thing = {
                                Id: "Things-" + i,
                                Name: "Name of thing #" + i,
                            };
                            newCollection.Items.push(thing);
                        }
                        this.setState({
                            tableOfThingsCollection: newCollection,
                        });
                    }}
                />
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="Data Grid with 'Load More' or numbered style paging"
                context={`Represents items that can be displayed all at once and then filtered inline.
                    It can easily display hundreds of items. "Load more" is a safety net and is ONLY shown when
                    the number of items is larger than expected. Also has a flag for numbered paging, just like List.`}
            >
                <ThingDataGrid
                    initialData={this.state.gridOfThingsCollection}
                    onRow={(thing: any) => [
                        <Card key={thing.Id} className={styles.card}>
                            <div className={styles.cardTitle}>{thing.Id}</div>
                            <Section>{thing.Name}</Section>
                        </Card>,
                    ]}
                    onFilter={this.filterThings}
                    filterHintText="Filter by name..."
                    onLoadMore={async () => {
                        /*
                        In this example, we are passing a custom 'onPageSelected' callback because we're not hitting a real
                        Octopus API, but you typically don't need this, as our List component will calculate this for you.
                        */
                        await this.sleep1s();
                        const newCollection = this.state.gridOfThingsCollection;
                        const itemsLength = this.state.gridOfThingsCollection.Items.length + 1;
                        for (let i = itemsLength; i < itemsLength + this.state.itemsPerPage; i++) {
                            const thing: Thing = {
                                Id: "Things-" + i,
                                Name: "Name of thing #" + i,
                            };
                            newCollection.Items.push(thing);
                        }
                        this.setState({
                            gridOfThingsCollection: newCollection,
                        });
                    }}
                />
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="FilterSearch box"
                context={`Can be used to filter in situations where you have all data, or search in situations where
                        you need to do an async operation. During search, we recommend adding a circular busy indicator next to the search box to indicate activity.`}
            >
                <FilterSearchBox hintText={"Filter..."} onChange={filter => this.setState({ filter })} />
                <p>You just typed: {this.state.filter}</p>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Network Failure Does Not Break The App">
                <ActionButton type={ActionButtonType.Primary} label="Cause a network error" onClick={() => this.setState({ showNetworkFailure: true })} />
                {this.state.showNetworkFailure && <NetworkFailure />}
            </StyleGuideEntry>,

            <StyleGuideEntry heading="State update to unmounted component does not trigger a warning">
                <LateStateChanges />
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Ordering via Drag & Drop">
                <SortableList items={this.state.itemsToSort} onOrderChanged={(orderedItems: any) => this.setState({ itemsToSort: orderedItems })} />
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="Dialogs - Standard"
                context={
                    <div>
                        <p>
                            Standard dialogs are typically launched from a <code>OpenDialogButton</code> directly and are implemented in a separate component that maintains the state separate from the parent. The component is typically named for what
                            is does (eg <code>ModifyMachines</code>) and returns one of the <code>DialogLayout</code> components as its root element.
                        </p>
                        <p>Standard dialogs are characterised by one or more of the following:</p>
                        <ul className={styles.bulletList}>
                            <li>Hold some internal state</li>
                            <li>Load data on open</li>
                            <li>Load data during usage</li>
                            <li>Persist data to the parent or API</li>
                        </ul>
                        <p>These are typically used for:</p>
                        <ul className={styles.bulletList}>
                            <li>Editing a record</li>
                            <li>Loading and showing more information</li>
                            <li>Picking or reordering</li>
                        </ul>
                        <p>
                            Commonly used <code>DialogLayout</code> are:
                        </p>
                        <ul className={styles.bulletList}>
                            <li>
                                <strong>SaveDialogLayout</strong> - Save and cancel button, used when editing and saving to the API
                            </li>
                            <li>
                                <strong>InfoDialogLayout</strong> - Close button only, used to load and show extra info
                            </li>
                            <li>
                                <strong>OkDialogLayout</strong> - Ok and cancel button, used when editing and updating the parent
                            </li>
                        </ul>
                    </div>
                }
            >
                <div>
                    <OpenDialogButton label={this.state.openDialogButtonLabel}>
                        <ExampleDialogContent saveDone={openDialogButtonLabel => this.setState({ openDialogButtonLabel })} />
                    </OpenDialogButton>
                    <Note>Open dialog Button:</Note>
                </div>
                <div className={styles.styleGuideExample}>
                    <OpenDialogIconButton toolTipContent="Open dialog using an icon button" icon={Icon.Add}>
                        <ExampleDialogContent saveDone={openDialogButtonLabel => this.setState({ openDialogButtonLabel })} />
                    </OpenDialogIconButton>
                    <Note>Open dialog icon button</Note>
                </div>
                <div>
                    <OpenDialogButton label={this.state.openDialogButtonLabel + " (load and save error)"}>
                        <ExampleDialogContent saveDone={openDialogButtonLabel => this.setState({ openDialogButtonLabel })} throwErrors={true} />
                    </OpenDialogButton>
                    <Note>Open Dialog button with load and save error handing</Note>
                </div>
                <div>
                    <ExampleComponentThatShowsDialogs />
                    <Note>
                        Example SendEmail on the Smtp page, This type of dialog may need to be launched indirectly this as part of another action. The <code>ExampleComponentThatShowsDialogs</code> component below represents the parent component and
                        should contain all the functionality of your page and not be a component that just pops dialogs.
                    </Note>
                </div>
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="Overflow Menu"
                context='Pages present primary and secondary actions as buttons. All other actions are provided via this menu. These are shown on the top right
                                                            of an "edit" page. Please see "List with &apos;Load More&apos; style paging (default)" above for overflow menus on lists.'
            >
                <OverflowMenu
                    menuItems={[
                        [OverflowMenuItems.navItem("Navigation 1 in a group", routeLinks.projects.root), OverflowMenuItems.navItem("Navigation 2 in a group", routeLinks.projects.root)],
                        OverflowMenuItems.deleteItemDefault("thing", () => Promise.resolve(true)),
                        OverflowMenuItems.dialogItem("Open Dialog not in a group", <ExampleDialogContent saveDone={s => console.log(s)} />),
                        OverflowMenuItems.item("Generic item", () => console.log("Generic clicked")),
                        OverflowMenuItems.disabledItem("Disabled item", "Can't be clicked because reasons"),
                    ]}
                />
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Lifecycle">
                {this.state.isDataLoaded && (
                    <div>
                        <span>{this.state.lifecyclePreview.Name}</span>
                        <LifecycleMap lifecyclePreview={this.state.lifecyclePreview} environmentsById={this.state.environmentsById} />
                    </div>
                )}
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Form">
                <SampleFormPage />
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="Formatting"
                context={
                    <span>
                        When text is used as a title for an area use <b>Title Case </b>
                        and when it's descriptive use <b>sentence case</b>.
                    </span>
                }
            >
                <h4>Title Case Example</h4>
                <p>Sentence case example</p>
                <DataTable>
                    <DataTableHeader>
                        <DataTableRow>
                            <DataTableHeaderColumn>Component</DataTableHeaderColumn>
                            <DataTableHeaderColumn>Title Case</DataTableHeaderColumn>
                            <DataTableHeaderColumn>sentence case</DataTableHeaderColumn>
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>PaperLayout</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>Title, BreadcrumbTitle</DataTableRowColumn>
                            <DataTableRowColumn>-</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>ExpandableFormSection</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>Title</DataTableRowColumn>
                            <DataTableRowColumn>Summary, Help</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>All Dialogs, All Section Headings, PageDivider</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>Title</DataTableRowColumn>
                            <DataTableRowColumn>-</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>NavigationSidebarLayout</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>navLinks</DataTableRowColumn>
                            <DataTableRowColumn>-</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>All form components</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>-</DataTableRowColumn>
                            <DataTableRowColumn>Label</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>OverflowMenu</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>MenuItems</DataTableRowColumn>
                            <DataTableRowColumn>-</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>Card, GenericCard</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>header</DataTableRowColumn>
                            <DataTableRowColumn>content, footerText</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>CardHeader</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>Title</DataTableRowColumn>
                            <DataTableRowColumn>-</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>CardText</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>-</DataTableRowColumn>
                            <DataTableRowColumn>Any text</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>DataTableRowHeaderColumn</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>Any text</DataTableRowColumn>
                            <DataTableRowColumn>-</DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <b>DataTableHeaderColumn</b>
                            </DataTableRowColumn>
                            <DataTableRowColumn>Any text</DataTableRowColumn>
                            <DataTableRowColumn>-</DataTableRowColumn>
                        </DataTableRow>
                    </DataTableBody>
                </DataTable>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Tool Tip">
                <ToolTip content="Additional information that appears only oh hover">Hover over me</ToolTip>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Logo" context="Use to display logos managed by Octopus.">
                <Logo url={waitingForOctopus} />
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Section" context="Provides a distinct area on the page via standardized paddings and margins.">
                <Section sectionHeader="This is the Section Header">This is the section body</Section>
                <Section>This is the section body</Section>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Page Divider" context="Divides page into 2 sections.">
                <PageDivider>Some Text That Divides the Whole Page</PageDivider>
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="Callouts"
                context={
                    <div>
                        Callouts provide contextual information related to nearby content. There is no need to put styles or paragraph tags in callouts as they are already styled appropriately.
                        <p>A callout should have a title that is either instructional or informative. Examples:</p>
                        <ul className={styles.bulletList}>
                            <li>Permission required</li>
                            <li>This project is currently disabled</li>
                            <li>You appear to be removing a variable set</li>
                        </ul>
                        <p>
                            Use callouts as a last resort to bring information to the attention of the user. Consider whether the content can be Note text or if the content is telling the user how to use the UI then the UI isn't intuitive enough and
                            needs reworking.{" "}
                        </p>
                    </div>
                }
            >
                <ul className={styles.components}>
                    <li>
                        <Callout title="You may find this information helpful" type={CalloutType.Information}>
                            Use an "Information" callout to let the user know of important or useful information they may need to proceed.
                            <ActionButton label={"Ternary button"} type={ActionButtonType.Ternary} />
                        </Callout>
                    </li>
                    <li>
                        <Callout title="You were successful" type={CalloutType.Success}>
                            This type of callout is rarely used because all SAVE actions already use a toast pop up, at the the bottom of the screen, to let the user know their action was successful. If you do need to use the "Success" callout it
                            should be for something positive. <InternalLink to={routeLinks.projects.root}>Internal link</InternalLink>
                        </Callout>
                    </li>
                    <li>
                        <Callout title="You are about to do something that needs attention and could have consequences" type={CalloutType.Warning}>
                            Use a "Warning" callout to alert the user that what they are about to do or have done, may need attention but won't stop them from proceeding.
                            <InternalLink to={routeLinks.projects.root}>Internal link</InternalLink>
                        </Callout>
                    </li>
                    <li>
                        <Callout title="You have done something that has some errors or serious consequences" type={CalloutType.Danger}>
                            Use this "Danger" callout to alert the user to an error or an action that has consequences. <InternalLink to={routeLinks.projects.root}>Internal link</InternalLink>
                        </Callout>
                    </li>
                </ul>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="OctoFeature Component">
                <div>
                    <FeatureToggle feature={Feature.MultiTenancy}>MultiTenancy is enabled</FeatureToggle>
                    <FeatureToggle feature={Feature.MultiTenancy} enabled={false}>
                        MultiTenancy is disabled
                    </FeatureToggle>
                </div>
                <div>
                    <FeatureToggle feature={Feature.CommunityActionTemplates}>CommunityActionTemplates is enabled</FeatureToggle>
                    <FeatureToggle feature={Feature.CommunityActionTemplates} enabled={false}>
                        CommunityActionTemplates is disabled
                    </FeatureToggle>
                </div>
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="RemoveItemsList Component"
                context={<span>Represents an in-memory list that items can be removed. New items can be added either inline or via a popup. A rule of thumb would be to use a popup when you have 4+ fields.</span>}
            >
                <p>The list below is not clickable</p>
                <ThingRemoveItemsList
                    listActions={[<ActionButton label="Add" />]}
                    data={this.state.removableItems}
                    clearButtonToolTip="Remove this thing"
                    onRemoveRow={thing => {
                        const newList = this.state.removableItems.slice();
                        const start = newList.findIndex(a => a.Name === thing.Name);
                        newList.splice(start, 1);
                        this.setState({ removableItems: newList });
                    }}
                    onRow={(thing, idx) => thing.Name}
                />
                <p>This list items are clickable</p>
                <ThingRemoveItemsList
                    listActions={[<ActionButton label="Add" />]}
                    data={this.state.removableItems}
                    clearButtonToolTip="Remove this thing"
                    onRemoveRow={thing => {
                        const newList = this.state.removableItems.slice();
                        const start = newList.findIndex(a => a.Name === thing.Name);
                        newList.splice(start, 1);
                        this.setState({ removableItems: newList });
                    }}
                    onRow={(thing, idx) => thing.Name}
                    onRowTouch={thing => console.log(`row with ${thing.Id} clicked`)}
                />
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Chips" context="Chips for projects, environment, tenant, tags, roles, channels, teams ...">
                <table>
                    <thead>
                        <tr>
                            <td>type</td>
                            <td>example</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Project:</td>
                            <td>
                                <ProjectChip
                                    project={{
                                        Id: "",
                                        Name: "Project A",
                                        VariableSetId: "",
                                        DeploymentProcessId: "",
                                        DiscreteChannelRelease: false,
                                        IncludedLibraryVariableSetIds: [],
                                        DefaultToSkipIfAlreadyInstalled: false,
                                        TenantedDeploymentMode: TenantedDeploymentMode.Untenanted,
                                        VersioningStrategy: null,
                                        ReleaseCreationStrategy: null,
                                        Templates: [],
                                        AutoDeployReleaseOverrides: [],
                                        Slug: "str",
                                        Description: "string",
                                        IsDisabled: false,
                                        ProjectGroupId: "string;",
                                        LifecycleId: "string",
                                        AutoCreateRelease: false,
                                        ProjectConnectivityPolicy: null,
                                        DefaultGuidedFailureMode: GuidedFailureMode.EnvironmentDefault,
                                        ClonedFromProjectId: null,
                                        Links: null,
                                        ExtensionSettings: null,
                                        ReleaseNotesTemplate: null,
                                        DeploymentChangesTemplate: null,
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Machine</td>
                            <td>
                                <MachineChip machineName="Machine A" />
                            </td>
                        </tr>
                        <tr>
                            <td>Environment</td>
                            <td>
                                <EnvironmentChip environmentName="Environment A" />
                            </td>
                        </tr>
                        <tr>
                            <td>Environment</td>
                            <td>
                                <EnvironmentChip environmentName="Excluded Environment" isExcluded={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>Worker Pool Static</td>
                            <td>
                                <WorkerPoolChip workerPoolType={WorkerPoolType.Static} workerPoolName={"Worker Pool Static"} />
                            </td>
                        </tr>
                        <tr>
                            <td>Worker Pool Dynamic</td>
                            <td>
                                <WorkerPoolChip workerPoolType={WorkerPoolType.Dynamic} workerPoolName={"Worker Pool Dynamic"} />
                            </td>
                        </tr>
                        <tr>
                            <td>Tenant</td>
                            <td>
                                <TenantChip tenantName={"Tenant A"} />
                            </td>
                        </tr>
                        <tr>
                            <td>Role</td>
                            <td>
                                <RoleChip role={"Role A"} />
                            </td>
                        </tr>
                        <tr>
                            <td>Channel</td>
                            <td>
                                <ChannelChip channelName="Channel A" />
                            </td>
                        </tr>
                        <tr>
                            <td>Team</td>
                            <td>
                                <TeamChip
                                    team={{
                                        Id: "teams-administrators",
                                        Name: "Team A",
                                        MemberUserIds: [],
                                        ExternalSecurityGroups: [],
                                        CanBeDeleted: false,
                                        CanBeRenamed: false,
                                        CanChangeRoles: false,
                                        CanChangeMembers: true,
                                        Links: {},
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Tag</td>
                            <td>
                                <Tag tagName="Hello World" description="Described" tagColor={success} />
                            </td>
                        </tr>
                        <tr>
                            <td>Clickable Role</td>
                            <td>
                                <RoleChip role={"Clickable Chip"} key={"role-clickableChip"} to={"#"} />
                            </td>
                        </tr>
                        <tr>
                            <td>Clickable Tag</td>
                            <td>
                                <Tag tagName="Clickable Tag" description="I'm linked" tagColor={success} to={"#"} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Tags List" context="Tenants Tags are a bit tricky to display so we have a dedicated component for that">
                {this.state.tagIndex && <TagsList tagIndex={this.state.tagIndex} canonicalNames={this.state.tagNames} />}
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Code Editor" context="Code Editor a a code editor support variety of programming languages.">
                <ScriptingLanguageSelector value={this.state.codeEditorLanguage} onChange={value => this.setState({ codeEditorLanguage: value })} />
                <Checkbox title="Full screen" label="Allow full screen" value={this.state.codeEditorAllowFullScreen} onChange={val => this.setState({ codeEditorAllowFullScreen: val })} />
                <div>
                    <CodeEditor language={this.state.codeEditorLanguage} value="" label="Script" allowFullScreen={this.state.codeEditorAllowFullScreen} onChange={x => console.log("change your model here")} />
                </div>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Markdown">
                <MarkdownEditor value={this.state.markdown} label="Markdown" onChange={value => this.setState({ markdown: value })} />
                <span>You just typed: </span>
                <Markdown markup={this.state.markdown} />
            </StyleGuideEntry>,

            <StyleGuideEntry
                heading="ScrollTable"
                context={"A table where the content is scrollable, but the headers are fixed. The body optionally uses virtual scrolling." + "The table columns are resizable - try dragging the handles in the table header!"}
            >
                <ScrollTable
                    rowCount={100}
                    overscanRowCount={5}
                    shouldVirtualize={true}
                    relativeColumnWidths={this.state.relativeColumnWidths}
                    onColumnWidthsChanged={relativeColumnWidths => this.setState({ relativeColumnWidths })}
                    minimumColumnWidthsInPx={[100, 100, 100]}
                    rowHeight={index => (index % 2 === 0 ? 50 : 80)}
                    headers={({ cellAligner, columnWidthsInPercent }) => [
                        cellAligner([<div>First Column</div>, <div>Second Column</div>, <div>Third Column</div>], { showResizeHandles: true }),
                        <div>This row does something a bit different, and therefore doesn't need to be column-aligned. You can do the same thing in the body as well</div>,
                        cellAligner([<div>And now we are aligned again... but things are a bit different because the first and second columns are merged</div>, <div>Third Column is still over here</div>], {
                            customColumnWidthsInPercent: [columnWidthsInPercent[0] + columnWidthsInPercent[1], columnWidthsInPercent[2]],
                        }),
                    ]}
                    rowRenderer={({ index, cellAligner }) => cellAligner([<div>Row: {index}</div>, <div>Notice how the columns are aligned</div>, <div>Even though this is just made up of plain old divs</div>])}
                />
            </StyleGuideEntry>,

            <StyleGuideEntry heading={"FocusActionsLayout"} context={"When you have some extra actions that you want to show up in a popup when you have focused on something (like an input)"}>
                <div style={{ width: "15rem", height: "3rem" }}>
                    <FocusActionsLayout
                        isFocused={this.state.focusActionsLayoutIsFocused}
                        actions={[<ActionButton label="Set Value to abc" onClick={() => this.setState({ focusActionsLayoutText: "abc" })} />, <ActionButton label="Set Value to xyz" onClick={() => this.setState({ focusActionsLayoutText: "xyz" })} />]}
                        onClickOutside={() => this.setState({ focusActionsLayoutIsFocused: false })}
                    >
                        <div onFocus={() => this.setState({ focusActionsLayoutIsFocused: true })}>
                            <Text value={this.state.focusActionsLayoutText} onChange={focusActionsLayoutText => this.setState({ focusActionsLayoutText })} />
                        </div>
                    </FocusActionsLayout>
                </div>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="ReadonlyText" context="A component that is styled the same as a text input, but is not a text input">
                <div>
                    ReadonlyText:
                    <div>
                        <ReadonlyText text={this.state.readonlyTextValue} />
                    </div>
                </div>
                <div>
                    ReadonlyText with some additional styling:
                    <div>
                        <ReadonlyText text={this.state.readonlyTextValue} className={styles.readonlyTextWithStrikethrough} />
                    </div>
                </div>
                <div>
                    Normal Text input (you can type in this one):
                    <div>
                        <Text value={this.state.readonlyTextValue} onChange={readonlyTextValue => this.setState({ readonlyTextValue })} underlineShow={false} />
                    </div>
                </div>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Task Details" context="Used to keep tasks looking consistent and familiar to existing users.">
                <TaskDetails task={this.state.sampleQueuedTask} />
                <TaskDetails task={this.state.sampleExecutingTask} />
                <TaskDetails task={this.state.sampleWaitingForManualInterventionTask} />
                <TaskDetails task={this.state.sampleSuccessfulTask} />
                <TaskDetails task={this.state.sampleSuccessfulTaskWithWarnings} />
                <TaskDetails task={this.state.sampleFailedTask} />
                <TaskDetails task={this.state.sampleTimedOutTask} />
                <TaskDetails task={this.state.sampleCancellingTask} />
                <TaskDetails task={this.state.sampleCanceledTask} />
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Sidebar">
                <SidebarLayout sideBar={<div>Sidebar on the right side (default)</div>}>Main body content</SidebarLayout>
                <SidebarLayout side={SidebarSide.Left} sideBar={<div>Sidebar on the left side</div>}>
                    Main body content
                </SidebarLayout>
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Checkboxes" context="All types of options">
                <Checkbox
                    label="With a title"
                    title="My title"
                    value={true}
                    onChange={() => {
                        /**/
                    }}
                    note={"With notes"}
                />
                <Checkbox
                    label="With a warning"
                    warning="My warning"
                    value={true}
                    onChange={() => {
                        /**/
                    }}
                    note={"With notes"}
                />
                <Checkbox
                    label="With a error"
                    error="My error"
                    value={true}
                    onChange={() => {
                        /**/
                    }}
                />
            </StyleGuideEntry>,

            <StyleGuideEntry heading="Wizard" context="Used to gather information from users in a sequential flow.">
                <OpenDialogButton label="Demo Wizard" type={ActionButtonType.Secondary}>
                    <DemoWizard title="Demo Wizard" />
                </OpenDialogButton>
            </StyleGuideEntry>,

            ...getTabEntries(),
            ...getDialogsEntries(),

            this.renderAdvancedFilterEntry(),

            this.renderNavigationSideBarEntry(),

            this.renderAutoCompleteEntry(),

            this.renderDisplayPropertiesEntry(),

            this.renderMultiSelectEntry(),

            renderQueryStringFiltersEntry({
                filter: this.state.queryStringFilter,
                onFilterChange: filter => this.setState({ queryStringFilter: filter }),
            }),
        ];
    }

    private renderMultiSelectEntry() {
        // tslint:disable-next-line:max-line-length
        const items: SelectItem[] = [
            { Id: "5a0d32aefcc4b90008b93548", Name: "Mexico" },
            { Id: "5a0d32ae5b962a5dd34f4eaf", Name: "Guinea-Bissau" },
            { Id: "5a0d32ae17919952c3f2f2bb", Name: "Albania" },
            { Id: "5a0d32ae68448c26e465f9ee", Name: "Anguilla" },
            { Id: "5a0d32ae3ad1586494f5a4f1", Name: "Oman" },
            { Id: "5a0d32ae70ba338c206b9f2e", Name: "Haiti" },
            { Id: "5a0d32ae4bdde0d1579a5922", Name: "Virgin Islands (US)" },
            { Id: "5a0d32aeffeebbcce3c8db58", Name: "United Kingdom" },
            { Id: "5a0d32aeedea7d42bf5f40f3", Name: "Sweden" },
            { Id: "5a0d32aea8c412b913363fa3", Name: "Dominica" },
            { Id: "5a0d32ae755ed443228dc67a", Name: "Kazakhstan" },
            { Id: "5a0d32aee9d5c090117df399", Name: "Congo" },
            { Id: "5a0d32aed312ec44d432ee8d", Name: "Latvia" },
            { Id: "5a0d32ae694ff0726e108818", Name: "Argentina" },
            { Id: "5a0d32ae9b250b69b0a295e7", Name: "France, Metropolitan" },
            { Id: "5a0d32ae008bec9bfbb9e0e5", Name: "Gibraltar" },
            { Id: "5a0d32ae51f59e4490dfe795", Name: "Yemen" },
            { Id: "5a0d32ae6ee1f4e516eb3a3c", Name: "Myanmar" },
            { Id: "5a0d32aeebb435b7984eabf3", Name: "San Marino" },
            { Id: "5a0d32ae7fd69cdf2ca0e828", Name: "Burundi" },
            { Id: "5a0d32ae77db49b092062c02", Name: "Honduras" },
            { Id: "5a0d32aeedee29b078a03ab4", Name: "Luxembourg" },
            { Id: "5a0d32ae53a9b38984a69e2c", Name: "Vanuatu" },
            { Id: "5a0d32ae0796645ebc6ece9a", Name: "Reunion" },
            { Id: "5a0d32aec4144f6bc98049f1", Name: "Liberia" },
            { Id: "5a0d32ae4d2db3fd195346be", Name: "Ghana" },
            { Id: "5a0d32aebc91c4f651d14046", Name: "Syria" },
            { Id: "5a0d32ae9ea43b1ac382fef4", Name: "Sao Tome and Principe" },
            { Id: "5a0d32ae1f2e66c9864b77b4", Name: "Colombia" },
            { Id: "5a0d32ae5512ab650d319801", Name: "Uruguay" },
            { Id: "5a0d32aeab166f8abc0eae58", Name: "Puerto Rico" },
            { Id: "5a0d32ae302f402d6110f902", Name: "Ethiopia" },
            { Id: "5a0d32ae006a7df443408fbb", Name: "New Caledonia" },
            { Id: "5a0d32ae9326b040d9588164", Name: "Nepal" },
            { Id: "5a0d32ae9b981846e2ba711a", Name: "Viet Nam" },
            { Id: "5a0d32ae5274d143100792b0", Name: "Fiji" },
            { Id: "5a0d32aeff0e78193a996db1", Name: "Cayman Islands" },
            { Id: "5a0d32ae1e57a56cfd95d1eb", Name: "S. Georgia and S. Sandwich Isls." },
            { Id: "5a0d32ae651b92583a1136cd", Name: "Martinique" },
            { Id: "5a0d32ae3eac88d6dc300d9c", Name: "Finland" },
            { Id: "5a0d32ae57e7b9f5c44eeb33", Name: "Mali" },
            { Id: "5a0d32aeb6fffd5f39d494b5", Name: "British Indian Ocean Territory" },
            { Id: "5a0d32ae8db29da2c1be9b09", Name: "Saint Kitts and Nevis" },
            { Id: "5a0d32aef8091f25d0ea048a", Name: "Wallis and Futuna Islands" },
            { Id: "5a0d32aea5978f22d4da2576", Name: "Maldives" },
            { Id: "5a0d32ae84c7d02f3b48d286", Name: "Angola" },
            { Id: "5a0d32ae7982e0d822ae88eb", Name: "Estonia" },
            { Id: "5a0d32aee2c2e8952424660b", Name: "Saint Vincent and The Grenadines" },
            { Id: "5a0d32ae5838f69e190b9bbd", Name: "Qatar" },
            { Id: "5a0d32aec9028f7db2cda214", Name: "Dominican Republic" },
        ];

        return (
            <StyleGuideEntry heading="Select (Multiselect)" context={<span>Used when the UI has a field that supports multiple items.</span>}>
                <div>
                    <h4>Starts as an empty field</h4>
                    <TestingMultiSelect
                        value={this.state.multiselect_empty_values}
                        items={items}
                        label="Which country do I go next"
                        renderChip={(i, d) => <Chip onRequestDelete={d}>{i.Name}</Chip>}
                        onChange={(values: string[]) => {
                            this.setState({ multiselect_empty_values: values });
                        }}
                    />
                </div>
                <div>
                    <h4>Field with existing values</h4>
                    <TestingMultiSelect
                        value={this.state.multiselect_existing_values}
                        items={items}
                        label="Which country do I go next"
                        renderChip={(i, d) => <Chip onRequestDelete={d}>{i.Name}</Chip>}
                        onChange={(values: string[]) => {
                            this.setState({ multiselect_existing_values: values });
                        }}
                    />
                </div>
                <div>
                    <h4>Field with an error</h4>
                    <TestingMultiSelect
                        value={this.state.multiselect_error_values}
                        items={items}
                        label="Which country do I go next"
                        error={this.state.multiselect_error_values.length === 0 ? "Can't be empty!" : null}
                        renderChip={(i, d) => <Chip onRequestDelete={d}>{i.Name}</Chip>}
                        onChange={(values: string[]) => {
                            this.setState({ multiselect_error_values: values });
                        }}
                    />
                    <Note>Making sure this text still shows</Note>
                </div>
                <div>
                    <h4>Add new items</h4>
                    <TestingMultiSelect
                        value={this.state.multiselect_add_values}
                        items={items}
                        label="Which country do I go next"
                        addNewTemplate={t => `Look mum I am adding '${t}' as a country!`}
                        renderChip={(i, d) => <Chip onRequestDelete={d}>{i.Name}</Chip>}
                        onNew={(t: string) => {
                            this.setState({ multiselect_add_values: this.state.multiselect_add_values.concat(t) });
                        }}
                        onChange={(values: string[]) => {
                            this.setState({ multiselect_add_values: values });
                        }}
                    />
                </div>
                <div>
                    <h4>Don't open of focus</h4>
                    <TestingMultiSelect
                        value={this.state.multiselect_focus_values}
                        items={items}
                        label="Which country do I go next"
                        renderChip={(i, d) => <Chip onRequestDelete={d}>{i.Name}</Chip>}
                        openOnFocus={false}
                        onChange={(values: string[]) => {
                            this.setState({ multiselect_focus_values: values });
                        }}
                    />
                </div>
                <div>
                    <h4>Label with tooltip description</h4>
                    <TestingMultiSelect
                        value={this.state.multiselect_description_values}
                        items={items}
                        description="**Hello there**"
                        label="Which country do I go next"
                        renderChip={(i, d) => <Chip onRequestDelete={d}>{i.Name}</Chip>}
                        onChange={(values: string[]) => {
                            this.setState({ multiselect_description_values: values });
                        }}
                    />
                </div>
                <div>
                    <h4>Custom item renderer</h4>
                    <TestingMultiSelect
                        value={this.state.multiselect_itemrender_values}
                        items={items}
                        label="Which country do I go next"
                        renderItem={i => ({ primaryText: i.Name, secondaryText: i.Id })}
                        renderChip={(i, d) => <Chip onRequestDelete={d}>{i.Name}</Chip>}
                        onChange={(values: string[]) => {
                            this.setState({ multiselect_itemrender_values: values });
                        }}
                    />
                </div>
            </StyleGuideEntry>
        );
    }

    private renderAdvancedFilterEntry() {
        const WeekendActivityMultiSelect = MultiSelect<SelectItem>();

        const chipRenderer = (r: SelectItem, onRequestDelete: (event: object) => void) => <FilterTextChip onRequestDelete={onRequestDelete} filterText={r.Id} />;

        interface Person {
            name: string;
            age: number;
            favoriteTvShow: string;
            favoriteWeekendActivities: WeekendActivity[];
        }
        const people: Person[] = [
            { name: "Bob", age: 50, favoriteTvShow: "Survivor", favoriteWeekendActivities: [WeekendActivity.Camping, WeekendActivity.Hiking, WeekendActivity.Dining] },
            { name: "Alice", age: 25, favoriteTvShow: "Game of Thrones", favoriteWeekendActivities: [WeekendActivity.Camping, WeekendActivity.Dining] },
            { name: "Chris", age: 20, favoriteTvShow: "Jerry Springer", favoriteWeekendActivities: [WeekendActivity.Hiking, WeekendActivity.Lounging] },
            { name: "Sally", age: 42, favoriteTvShow: "Today Tonight", favoriteWeekendActivities: [WeekendActivity.Lounging] },
            { name: "Christine", age: 31, favoriteTvShow: "Game of Thrones", favoriteWeekendActivities: [WeekendActivity.Hiking] },
            { name: "Wally", age: 79, favoriteTvShow: "Masterchef", favoriteWeekendActivities: [WeekendActivity.Dining, WeekendActivity.Camping] },
        ];
        const allWeekendActivities = Object.keys(WeekendActivity).reduce<WeekendActivity[]>((p, c: WeekendActivity) => [...p, WeekendActivity[c]], []);

        const filter = this.state.personFilter;
        // TODO: Chips should have an 'X' in the corner, which clears that individual filter
        const filterByChips: React.ReactNode[] = [
            ...(filter.name !== "" ? [<FilterTextChip key={0} filterText={filter.name} />] : []),
            ...(filter.age !== "" ? [<FilterTextChip key={1} filterText={filter.age} />] : []),
            ...(filter.likesOutdoors ? [<FilterTextChip key={2} filterText="Likes outdoors" />] : []),
            ...(filter.isRetired ? [<FilterTextChip key={3} filterText="Is retired" />] : []),
            ...(filter.favoriteTvShow !== "" ? [<FilterTextChip key={4} filterText={filter.favoriteTvShow} />] : []),
            ...filter.favoriteWeekendActivites.map((a, i) => <FilterTextChip key={5 + i} filterText={a} />), // Normally you would use a more specific chip type here
        ];

        return (
            <StyleGuideEntry
                heading="AdvancedFilter"
                context={
                    <div>
                        <p>Guidelines:</p>
                        <ul className={styles.bulletList}>
                            <li>Use when you have some complex data in the main pane that can be filtered in a bunch of different ways.</li>
                            <li>Any filters in the header should not appear in the filter panel.</li>
                            <li>Toggles (checkboxes) tend to appear first.</li>
                            <li>There are specific Advanced filter components for some controls (text input, checkbox). Other types, like MultiSelect, don't have an advanced filter specific component, so just use them as is.</li>
                            <li>When using your own custom controls, be careful to be consistent with the label to make it have the same form as other advanced filter controls.</li>
                        </ul>
                        <p>The reasons they don't have a specific component is because:</p>
                        <ul className={styles.bulletList}>
                            <li>There is not much need for one as we don't actually need to customise much to get them looking and working nicely in the filter panel</li>
                            <li>It would add some unnecessary complexity if we were to add them, for example around custom chip rendering/sharing code/generics in the case of MultiSelect</li>
                        </ul>
                    </div>
                }
            >
                <AdvancedPersonFilterLayout
                    additionalHeaderFilters={[<FilterSearchBox hintText={"Filter by name..."} value={filter.name} onChange={name => this.setState({ personFilter: { ...this.state.personFilter, name } })} />]}
                    defaultFilter={this.defaultPersonFilter()}
                    filter={filter}
                    onFilterReset={personFilter => this.setState({ personFilter })}
                    filterByChips={filterByChips.length && <div>{filterByChips}</div>}
                    filterSections={[
                        {
                            render: (
                                <div>
                                    <AdvancedFilterCheckbox
                                        label="Is retired"
                                        value={filter.isRetired}
                                        onChange={isRetired => this.setState({ personFilter: { ...this.state.personFilter, isRetired } })}
                                        icon={/*Icon is optional*/ <em className={"fa fa-warning"} style={{ color: alert }} />}
                                    />
                                    <AdvancedFilterCheckbox label="Likes outdoors" value={filter.likesOutdoors} onChange={likesOutdoors => this.setState({ personFilter: { ...this.state.personFilter, likesOutdoors } })} />
                                    <AdvancedFilterTextInput fieldName={"age"} value={filter.age ? filter.age.toString() : ""} onChange={age => this.setState({ personFilter: { ...this.state.personFilter, age } })} />
                                </div>
                            ),
                        },
                        {
                            sectionName: "Interests",
                            render: (
                                <div>
                                    <AdvancedFilterTextInput fieldName={"favorite TV show"} value={filter.favoriteTvShow} onChange={favoriteTvShow => this.setState({ personFilter: { ...this.state.personFilter, favoriteTvShow } })} />
                                    <WeekendActivityMultiSelect
                                        items={allWeekendActivities.map(i => ({ Id: i, Name: i }))}
                                        value={filter.favoriteWeekendActivites}
                                        fieldName="weekend activity"
                                        renderChip={chipRenderer}
                                        onChange={(favoriteWeekendActivites: WeekendActivity[]) => this.setState({ personFilter: { ...this.state.personFilter, favoriteWeekendActivites } })}
                                    />
                                </div>
                            ),
                        },
                    ]}
                    renderContent={() => {
                        return (
                            <ul>
                                {people.filter(matchesFilter).map((person, index) => {
                                    return (
                                        <li key={index}>
                                            {person.name} ({person.age}) loves {person.favoriteTvShow} and {person.favoriteWeekendActivities.join(" and ")}
                                        </li>
                                    );
                                })}
                            </ul>
                        );
                    }}
                />
            </StyleGuideEntry>
        );

        function matchesFilter(p: Person) {
            const matchesIsRetired = !filter.isRetired || p.age > 65;
            const matchesLikesOutdoors = !filter.likesOutdoors || p.favoriteWeekendActivities.includes(WeekendActivity.Camping) || p.favoriteWeekendActivities.includes(WeekendActivity.Hiking);
            const matchesFavoriteWeekendActivities = filter.favoriteWeekendActivites.every(a => p.favoriteWeekendActivities.includes(a));
            return (
                p.name.toLowerCase().includes(filter.name.toLowerCase()) &&
                p.favoriteTvShow.toLowerCase().includes(filter.favoriteTvShow.toLowerCase()) &&
                p.age.toString().includes(filter.age) &&
                matchesIsRetired &&
                matchesLikesOutdoors &&
                matchesFavoriteWeekendActivities
            );
        }
    }

    private renderNavigationSideBarEntry() {
        const navLinks = [
            Navigation.navItem("Some location", "/somelocation"),
            Navigation.navGroup("Some sub section", "/someSubsection", [
                Navigation.navItem("first part", "/someSubsection"),
                Navigation.navItem("second part", "/someSubsection"),
                Navigation.navItem("third part", "/someSubsection"),
                Navigation.navItem("Style Guide", "/style-guide"),
            ]),
        ];

        return (
            <StyleGuideEntry heading={"Navigation side bar"}>
                <NavigationSidebarLayout
                    name="Some resource"
                    description="Some description"
                    navLinks={navLinks}
                    content={
                        <div>
                            <p>Sidebar items can be nested, see the sub section in the shown example.</p>
                            <p>
                                When deciding between nesting a set of links as second-level links and creating a brand new navigation side bar layout, a good rule of thumb to consider is whether your sub section is a different resource, or acts like
                                a different resource.
                            </p>
                            <p>If yes, create a new side bar layout. The resource name can be used as the side bar title and give more context to the side bar</p>
                            <p>If no, it probably makes sense to use a second-level nested group</p>
                        </div>
                    }
                />
            </StyleGuideEntry>
        );
    }

    private renderAutoCompleteEntry() {
        const getOptions = async (searchText: string) => {
            await delay(1000); // simulate network request
            const filteredResults = ["apple", "banana", "strawberry", "orange", "peach", "grape", "pineapple", "cherry", "pear", "mango"].filter(f => f.includes(searchText.toLowerCase())).sort();
            const itemsToTake = 7;
            return {
                items: filteredResults.slice(0, itemsToTake).map(f => ({ Id: f, Name: f })),
                containsAllResults: filteredResults.length <= itemsToTake,
            };
        };
        return (
            <StyleGuideEntry
                heading={"AutoComplete"}
                context={"Similar to a Select component, but usually typing in a filter will execute a query against the API for more results" + " and also provides the option of entering a value that is not in the available options"}
            >
                <div className={styles.container}>
                    <div>
                        <h4>Auto complete value</h4>
                        <span>A query is executed when you change the text in the input. You are forced to enter something that appears in the available options. Filtering should be case insensitive.</span>
                        <AutoComplete label={"My favorite fruit"} hintText="Enter your favorite fruit" getOptions={getOptions} value={this.state.autoCompleteValue} onChange={autoCompleteValue => this.setState({ autoCompleteValue })} />
                        <div>
                            Current Value: <strong>{this.state.autoCompleteValue}</strong>
                        </div>
                    </div>
                    <div>
                        <h4>Free Form Auto Complete Value</h4>
                        <span>You can enter in any custom value into this autocomplete</span>
                        <AutoComplete
                            allowAnyTextValue={true}
                            label={"My favorite fruit"}
                            hintText="Enter your favorite fruit"
                            getOptions={getOptions}
                            value={this.state.freeformAutoCompleteValue}
                            onChange={freeformAutoCompleteValue => this.setState({ freeformAutoCompleteValue })}
                        />
                        <div>
                            Current Value: <strong>{this.state.freeformAutoCompleteValue}</strong>
                        </div>
                    </div>
                    <div>
                        <h4>Auto Complete with Errors</h4>
                        <span>This is what an autocomplete looks like with an error</span>
                        <AutoComplete
                            allowAnyTextValue={true}
                            label={"My favorite fruit"}
                            hintText="Enter your favorite fruit"
                            getOptions={getOptions}
                            value={this.state.autocompleteWithError}
                            onChange={autocompleteWithError => this.setState({ autocompleteWithError })}
                            error={"This is a sample error"}
                        />
                        <div>
                            Current Value: <strong>{this.state.autocompleteWithError}</strong>
                        </div>
                    </div>
                </div>
            </StyleGuideEntry>
        );
    }

    private renderDisplayPropertiesEntry() {
        return (
            <StyleGuideEntry heading="Display properties" context="When you just want to display some readonly key-value pairs">
                <DisplayProperties
                    properties={[
                        { key: "Queensland", value: "Brisbane" },
                        { key: "Victoria", value: "Melbourne" },
                        { key: "New South Wales", value: "Sydney" },
                        { key: "Western Australia", value: "Perth" },
                        { key: "South Australia", value: "Adelaide" },
                    ]}
                />
            </StyleGuideEntry>
        );
    }

    private defaultPersonFilter(): PersonFilter {
        return {
            name: "",
            favoriteTvShow: "",
            age: "",
            isRetired: false,
            likesOutdoors: false,
            favoriteWeekendActivites: [],
        };
    }

    private filterThings(filter: string, resource: Thing) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private navigateToEntry(e: any, heading: any) {
        e.preventDefault();
        //# did not work
        document.getElementById(heading).scrollIntoView();

        const scrolledY = window.scrollY;
        if (scrolledY) {
            window.scroll(0, scrolledY - 144);
        }
    }

    private startBusyIndicator = async (e: any) => {
        e.preventDefault();
        const busyIndicator = this.sleep1s();
        this.setState({ busyIndicator });
        await busyIndicator;
    };

    private sleep1s = () => {
        return new Promise(resolve => setTimeout(resolve, 1000));
    };

    private showAlert(message: string) {
        window.alert(message);
    }
}

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
