import * as React from "react";
import { Switch, RouteComponentProps, withRouter } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import RunbooksLayout from "./RunbooksLayout";
import OperationsOverviewLayout from "./OperationsOverviewLayout";
import RunbookOverviewLayout from "./RunbookOverviewLayout";
import RunbookRunsListLayout from "./RunbookRunsListLayout";
import RunbookProcessLayout from "./RunbookProcessLayout";
import RunbookSettingsLayout from "./RunbookSettingsLayout";
import RunbookRunNowLayout from "./RunbookRunNowLayout";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import RunbookContextLayout from "./RunbookContextLayout";
import { runbookLinks } from "../OperationsRoute";
import { RedirectAs404 } from "components/NotFound/NotFound";
import InternalRedirect from "components/Navigation/InternalRedirect";
import { generatePath } from "react-router";
import RunbookProcessRoute from "./RunbookProcessRoute";
import { ActionScope } from "components/Actions/pluginRegistry";
import { RunbookSnapshotsRoute } from "./RunbookSnapshots/RunbookSnapshotsRoute";

export const OperationsOverviewPage = withPage({ page: pageIds.project().operations })(OperationsOverviewLayout);
export const RunbooksPage = withPage({ page: pageIds.project().runbooks })(RunbooksLayout);
export const RunbookOverviewPage = withPage({ page: pageIds.project().runbook.root })(RunbookOverviewLayout);
export const RunbookRunsListPage = withPage({ page: pageIds.project().runbook.runsList })(RunbookRunsListLayout);
export const RunbookProcessPage = withPage({ page: pageIds.project().runbook.process })(RunbookProcessLayout);
export const RunbookRunNowPage = withPage({ page: pageIds.project().runbook.runNow })(RunbookRunNowLayout);
export const RunbookSettingsPage = withPage({ page: pageIds.project().runbook.settings })(RunbookSettingsLayout);

export type RunbookRouteProps = {
    path: string;
} & RouteComponentProps<any>;

class RunbookRoute extends React.Component<RunbookRouteProps> {
    render() {
        const links = runbookLinks(this.props.path);
        const match = this.props.match;
        return (
            <ReloadableRoute path={links.root}>
                <RunbookContextLayout>
                    {context => (
                        <ReloadableRoute path={links.root}>
                            <Switch>
                                <ReloadableRoute path={links.overview} component={RunbookOverviewPage} />
                                <ReloadableRoute path={links.runslist} component={RunbookRunsListPage} />
                                <ReloadableRoute path={links.runNow.root} exact={true} component={RunbookRunNowPage} />
                                <ReloadableRoute path={links.runNow.runbookSnapshot(":runbookSnapshotId")} exact={true} component={RunbookRunNowPage} />
                                <ReloadableRoute path={links.settings} component={RunbookSettingsPage} />
                                <RunbookProcessRoute processId={context.state.runbook && context.state.runbook.RunbookProcessId} scope={ActionScope.Runbooks} path={links.runbookProcess.runbookProcess(":runbookProcessId").root} />
                                <ReloadableRoute
                                    exact={true}
                                    path={links.root}
                                    render={(props: RouteComponentProps<any>) => {
                                        return <InternalRedirect to={`${generatePath(links.overview, props.match.params)}${props.location.search}`} />;
                                    }}
                                />
                                <ReloadableRoute path={links.runbookSnapshots} component={RunbookSnapshotsRoute} />
                                <RedirectAs404 />
                            </Switch>
                        </ReloadableRoute>
                    )}
                </RunbookContextLayout>
            </ReloadableRoute>
        );
    }
}

const EnhancedRunbookRoute = withRouter(RunbookRoute);
export default EnhancedRunbookRoute;
