import { NamedResource, TenantedDeploymentMode, ActionTemplateParameterResource } from "client/resources";
import { DeploymentActionPackageResource } from "./deploymentActionPackageResource";
import { MetadataTypeCollection } from "./dynamicFormResources";
import { ExtensionSettingsValues } from "./extensionSettingsValues";

export interface ProjectResource extends NamedResource {
    VariableSetId: string;
    DeploymentProcessId: string;
    DiscreteChannelRelease: boolean;
    IncludedLibraryVariableSetIds: string[];
    DefaultToSkipIfAlreadyInstalled: boolean;
    TenantedDeploymentMode: TenantedDeploymentMode;
    VersioningStrategy: VersioningStrategy;
    ReleaseCreationStrategy: ReleaseCreationStrategy;
    Templates: ActionTemplateParameterResource[];
    AutoDeployReleaseOverrides: any[];
    Slug: string;
    Description: string;
    IsDisabled: boolean;
    ProjectGroupId: string;
    LifecycleId: string;
    AutoCreateRelease: boolean;
    ProjectConnectivityPolicy: ProjectConnectivityPolicy;
    DefaultGuidedFailureMode: GuidedFailureMode;
    ClonedFromProjectId: string;
    SpaceId?: string;
    ExtensionSettings: ExtensionSettingsValues[];
    ReleaseNotesTemplate: string;
    DeploymentChangesTemplate: string;
}

export class ProjectSettingsMetadata {
    ExtensionId: string;
    Metadata: MetadataTypeCollection;
}

export interface VersioningStrategy {
    Template: string;
    DonorPackage?: DeploymentActionPackageResource;
}

export interface ReleaseCreationStrategy {
    ReleaseCreationPackage: DeploymentActionPackageResource;
    ChannelId?: string;
}

export interface ProjectConnectivityPolicy {
    SkipMachineBehavior: string;
    TargetRoles: string[];
    AllowDeploymentsToNoTargets: boolean;
    ExcludeUnhealthyTargets: boolean;
}

export enum GuidedFailureMode {
    EnvironmentDefault = "EnvironmentDefault",
    Off = "Off",
    On = "On",
}

export interface RecentlyViewedProjectIds {
    [key: string]: string[];
}

export default ProjectResource;
