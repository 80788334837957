import { NamedResource } from "./namedResource";

export class RunbookResource extends NamedResource {
    Description: string;
    RunbookProcessId: string;
    ProjectId: string;
    SpaceId: string;
}

export default RunbookResource;
