import * as React from "react";
import { ReleaseChanges } from "client/resources";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import Markdown from "components/Markdown";

interface ReleaseChangesDetailProps {
    changesMarkdown: string;
}

export default class ReleaseChangesDetail extends DataBaseComponent<ReleaseChangesDetailProps, DataBaseComponentState> {
    constructor(props: ReleaseChangesDetailProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <Markdown markup={this.props.changesMarkdown} />;
    }
}
